import { createTheme } from "@mui/material";

export const TableTheme = createTheme({
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '1rem'
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F3F6F9',
                    width: '100%',
                    paddingY: '14px'
                }
            }
        }
    }
});
