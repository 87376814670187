import { getData, patchData, postData } from '../Services';

export const postUser = (user) => {
  return postData('/user', user);
};

export const patchUser = (user) => {
  return patchData('/user/' + user.id, user);
};

export const getUserById = (id) => {
  return getData('/user/' + id);
};
