import { Empty, Observed, Pending, Validated } from "../styles/Styles";

export const CurriculumStates = {
  SIN_PLANES: {id: 0, name: 'SIN PLANES DE ESTUDIO'},
  PENDIENTE: { id: 1, name: 'PENDIENTE' },
  OBSERVADO: { id: 2, name: 'OBSERVADO' },
  APROBADO: { id: 3, name: 'APROBADO' },
  NUEVO: { id: 4, name: 'NUEVO' }
};

export const CurriculumStateJSX = {
    [CurriculumStates.PENDIENTE.id]: <Pending>{CurriculumStates.PENDIENTE.name}</Pending>,
    [CurriculumStates.OBSERVADO.id]: <Observed>{CurriculumStates.OBSERVADO.name}</Observed>,
    [CurriculumStates.APROBADO.id]: <Validated>{CurriculumStates.APROBADO.name}</Validated>,
    [CurriculumStates.NUEVO.id]: <>{CurriculumStates.NUEVO.name}</>,
    [CurriculumStates.SIN_PLANES.id]: <Empty>{CurriculumStates.SIN_PLANES.name}</Empty>
}
