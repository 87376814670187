import PropTypes from 'prop-types';
import { Alert, Box, Grid, Pagination, PaginationItem, Typography, Checkbox, ThemeProvider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { stylesColumns } from '../styles/Styles';
import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TableTheme } from '../styles/tableStyle';
import RegularTableRow from './RegularTableRow';
import CollapsibleTableRow from './CollapsibleTableRow';

const createColumn = (value) => {
  return createCell(value, stylesColumns);
};

const createCell = (value, styles) => {
  return (
    <TableCell key={value} sx={{ ...styles }}>
      {value}
    </TableCell>
  );
};

export const TableGenericComponent = ({
  bar = false,
  displayPagination = true,
  columns,
  rows,
  count,
  rowsPerPage,
  page,
  handleChange = function () {},
  hasActions,
  message = undefined,
  checkable = false,
  onCheck = function () {},
  allCheckTable,
  setAllCheckTable,
  allowCollapsibleRows = false
}) => {
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  useEffect(() => {
    if (rows) {
      setDataIsLoaded(true);
    }
  }, [rows]);

  const handleChangePage = (value) => {
    handleChange(rowsPerPage, value);
  };
  const last = () => (
    <>
      <ChevronLeftIcon sx={{ color: '#1976d2' }} />
      <Typography variant="body1" color="primary" sx={{ color: '#1976d2' }}>
        {' Anterior'}
      </Typography>
    </>
  );
  const next = () => (
    <>
      <Typography variant="body1" sx={{ color: '#1976d2' }}>
        {'Siguiente '}
      </Typography>
      <ChevronRightIcon sx={{ color: '#1976d2' }} />
    </>
  );

  const displayTable = rows.length || message ? 'block' : 'none';
  const drawableColumns = hasActions ? [...columns, { label: 'Acciones' }] : [...columns, { label: '' }];

  //#F3F6F9
  return (
    <ThemeProvider theme={TableTheme}>
      <Box sx={{ display: displayTable, '&:last-child td, &:last-child th': { border: 0 } }}>
        {bar && <hr style={{ border: '1px solid #000' }} />}
        <TableContainer aria-label="a dense table" sx={{ width: '100%' }}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                {checkable && (
                  <TableCell>
                    <Checkbox
                      onChange={() => {
                        setAllCheckTable(!allCheckTable);
                      }}
                      checked={allCheckTable}
                      size="small"
                    />
                  </TableCell>
                )}
                {drawableColumns.map((column) => createColumn(column.label))}
              </TableRow>
            </TableHead>
            {rows.length && !allowCollapsibleRows ? (
              <>
                <TableBody>
                  {rows.map((row) => (
                    <RegularTableRow key={row.id} columns={columns} row={row} checkable={checkable} onCheck={onCheck} />
                  ))}
                </TableBody>
              </>
            ) : null}
            {rows.length && allowCollapsibleRows ? (
              <>
                <TableBody sx={{ width: '100%' }}>
                  {rows.map((row) => {
                    return row.collapsible ? (
                      <CollapsibleTableRow
                        columns={columns}
                        rows={row.analytic}
                        checkable={checkable}
                        onCheck={onCheck}
                        headCellComponent={row.nameAndData}
                        actions={row.actions}
                      />
                    ) : (
                      <RegularTableRow key={row.id} columns={columns} row={row.analytic[0]} checkable={checkable} onCheck={onCheck} />
                    );
                  })}
                </TableBody>
              </>
            ) : null}
          </Table>

          {!rows.length && message && dataIsLoaded ? (
            <>
              <br></br>
              <Grid container justifyContent="center">
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{ mt: 4, display: 'center', width: '90%', fontSize: 16, borderLeft: '8px solid #03a9f4' }}>
                  {message}
                </Alert>
              </Grid>
              <br></br>
            </>
          ) : null}
        </TableContainer>
        <br />
        {displayPagination && (
          <Pagination
            count={count > rowsPerPage ? Math.ceil(count / rowsPerPage) : 1}
            page={page + 1}
            shape="rounded"
            onChange={(e, value) => handleChangePage(value - 1)}
            color="primary"
            renderItem={(item) => <PaginationItem components={{ previous: last, next: next }} {...item} sx={{ color: '#007BC7' }} />}
          />
        )}
        <br />
      </Box>
    </ThemeProvider>
  );
};

TableGenericComponent.propTypes = {
  bar: PropTypes.bool,
  displayPagination: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
  allowCollapsibleRows: PropTypes.bool
};
