import { Grid, Typography } from '@mui/material';
// import { CustomBreadcrumbs } from '../../components/CustomBreadcrumbs';

export const NotificationHead = () => {
  return (
    <>
      {/* <CustomBreadcrumbs now={'Notificaciones'} /> */}
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <Typography variant="h3" color="initial">
            Notificaciones
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
