import { useContext } from 'react';
import { AnalyticRouteConstants } from '../routes/RoutesConstants';
import { Actions, Roles } from '../constants/Actions';
import { AppContext } from '../context/global/AppContext';
import { useSnackbar } from 'notistack';
import { formatDecimal } from '../helpers/decimalHelper';
import { ADMINISTRADOR, AUTORIDAD_DE_LEGALIZACION, SUPER_ADMIN, VALIDADOR } from '../constants/Roles';

export const useAuthHelper = () => {
  const { user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const redirectAnalyticModule = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const isValidador = (userData && userData.roles && userData.roles.length > 0) ? (userData.roles[0] == VALIDADOR) : false;
    const numberEstablishment = JSON.parse(localStorage.getItem('user'))?.establishments?.length >= 2;

    if (user.actions && user.actions.includes(Actions.READ_ANALYTIC_GROUPED)) {
      if (isValidador) {
        if (numberEstablishment) {
          return AnalyticRouteConstants.BUSCAR_AGRUPADO;
        } else {
          return AnalyticRouteConstants.BUSCAR;
        }
      }
      return AnalyticRouteConstants.BUSCAR_AGRUPADO;
    } else {
      return AnalyticRouteConstants.BUSCAR;
    }
  };

  /**
   * Verifica que el user tenga los permisos necesarios para realizar una acción.
   * @param {*} requestedActions Siempre debe ser un array, incluso aunque se le esté pasando sólo una acción a verificar;
   * de lo contrario devolverá error (`requestedActions.some is not a function`).
   * @returns
   */
  const isAllowed = (requestedActions) => {
    return (
      user &&
      ((user.roles && user.roles.includes(Roles.SUPERADMIN)) || (user.actions && requestedActions.some((x) => user.actions.includes(x))))
    );
  };

  const addAndAuthTableAction = (element, permissions, actions) => {
    let actionObject = {
      html: element
    };
    if (isAllowed(permissions)) {
      actions.push(actionObject);
    }
  };

  const isSuperAdmin = () => {
    return user && user.roles && user.roles.includes(Roles.SUPERADMIN);
  };

  const authorizeElement = (element, permissions) => {
    return isAllowed(permissions) ? element : null;
  };

  const validateNote = (valueReplace, canLoadMaximumQualification) => {
    let floatValue = parseFloat(valueReplace);
    if (!canLoadMaximumQualification) {
      if (floatValue > 10 || floatValue < 0) {
        enqueueSnackbar('El valor debe ser una nota del 1 al 10', { variant: 'warning' });
        return '';
      } else {
        let formatValue = formatDecimal(floatValue);
        return formatValue;
      }
    } else if (canLoadMaximumQualification) {
      if (floatValue > 20 || floatValue < 0) {
        enqueueSnackbar('El valor debe ser una nota del 1 al 20 para este establecimiento', { variant: 'warning' });
        return '';
      } else {
        let formatValue = formatDecimal(floatValue);
        return formatValue;
      }
    } else {
      let formatValue = formatDecimal(floatValue);
      return formatValue;
    }
  };

  const isUserAdminOrLegalization = () => {
    return user.roles.includes(SUPER_ADMIN) || user.roles.includes(ADMINISTRADOR) || user.roles.includes(AUTORIDAD_DE_LEGALIZACION);
  };

  return {
    redirectAnalyticModule,
    isAllowed,
    addAndAuthTableAction,
    authorizeElement,
    isSuperAdmin,
    validateNote,
    isUserAdminOrLegalization
  };
};
