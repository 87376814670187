import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Actions } from '../../../../constants/Actions';
//TABLE
import { UsersState } from '../../../../context/admin/users/UsersState';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { UsersFilter } from './UsersFilter';
import { UsersTable } from './UsersTable';

export const SearchUsersPage = () => {
  useAuthHelper(Actions.READ_USER);

  return (
    <UsersState>
      <Container sx={{ mt: 4 }}>
        <Typography variant="h3" color="initial">
          Usuarios
        </Typography>
        <br />
        <UsersFilter />
        <UsersTable />
      </Container>
      <br />
    </UsersState>
  );
};
