import Visibility from '@mui/icons-material/Visibility';
import moment from 'moment';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableAction } from '../../../components/TableAction';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { Actions } from '../../../constants/Actions';
import { AnalyticalEstablishmentContext } from '../../../context/Analytical/AnalyticalContext';

import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { Box } from '@mui/material';
import { ANALYTICAL_STATUS } from '../../../constants/AnalyticalStates';
import { stateHtmlAnalytics } from '../../../helpers/StateHtmlColors';

const headers = [
  { label: 'CUE', field: 'cue' },
  { label: 'Anexo', field: 'annexed' },
  { label: 'Establecimiento', field: 'establishment_name' },
  { label: 'Nivel', field: 'level' },
  { label: 'Estado', field: 'statusHtml' }
];

export const AnalyticalEstablishmentTable = () => {
  const navigate = useNavigate();
  const { paginationProps, handlePageChange, establishments } = useContext(AnalyticalEstablishmentContext);
  const { addAndAuthTableAction } = useAuthHelper();

  const establishmentsHtml = establishments?.map((e) => {
    let updated = e.updated_at ? moment(e.updated_at).format('DD/MM/YYYY') : '';
    e.status = e.status || 0;
    e.statusHtml = <Box>{stateHtmlAnalytics(ANALYTICAL_STATUS[e.status])}</Box>;
    e.level = e.level || '-';
    e.actions = [];
    addAndAuthTableAction(
      <TableAction
        key={e.id + 'view'}
        tooltipTitle="Ver Detalle"
        onClick={() => handleViewDetailEstablishment(e)}
        iconElement={<Visibility color="primary" />}
      />,
      [Actions.READ_ANALYTIC],
      e.actions
    );

    e.updated = updated;
    return e;
  });

  const handleViewDetailEstablishment = (e) => {
    localStorage.setItem('analyticalEstablishmentId', e.id);
    localStorage.setItem('analyticalEstablishmentName',e.establishment_name);
    localStorage.setItem('analyticalEstablishmentIsTerciary',e.isEnableNewCareer);
    localStorage.setItem('analyticalEstablishmentIsPublic',e.isPublic);
    navigate(`/analiticos/buscar/${e.id}/${e.establishment_name}`);
  };

  return (
    <>
      <>
        {paginationProps && establishmentsHtml && (
          <TableGenericComponent
            displayPagination={true}
            bar={false}
            columns={headers}
            rows={establishmentsHtml}
            count={paginationProps.count}
            page={paginationProps.page}
            rowsPerPage={paginationProps.limit}
            handleChange={handlePageChange}
            hasActions={true}
            message="No hay establecimientos para mostrar."
          />
        )}

        <br />
      </>
    </>
  );
};
