import { Grid, Typography, Alert, AlertTitle } from '@mui/material';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { useParams } from 'react-router-dom';
import { HistoricalRouteConstants } from '../../../routes/RoutesConstants';

export const HistoricalSearchHeader = ({ showAlert, setShowAlert }) => {
  const params = useParams();
  /**
   * Esta variable es `true` cuando el user vuelve al listado después de haber dado de baja a un
   * estudiante desde la vista detalle de un analítico LEGALIZADO (es decir que accedió a esta
   * vista desde el módulo Histórico)
   */
  const remoteUnsubscribingAlert = JSON.parse(localStorage.getItem('remoteUnsubscribeAlert'));
  return (
    <>
      {params.id && <CustomBreadcrumbs base={'Historial'} now={params.name} baseLink={HistoricalRouteConstants.BUSCAR_ESTABLECIMIENTO} />}
      {(showAlert || remoteUnsubscribingAlert) && (
        <>
          <Alert sx={{ width: '75%' }} justifyContent="center" severity="success">
            <Grid container>
              <Grid container item xs={10}>
                <AlertTitle>
                  <strong>Se ha dado de baja el analítico correctamente</strong>
                </AlertTitle>
                El/la alumno/a se encuentra disponible en el módulo Analítico.
              </Grid>
              <Grid container item xs={2}>
                <CustomYellowButton style={{ marginTop: 0, float: 'right' }} action={() => setShowAlert(false)} title={'cerrar'} />
              </Grid>
            </Grid>
          </Alert>
          <br></br>
        </>
      )}
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h3" color="initial">
            Histórico
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
