import { Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CustomModal } from '../../../components/CustomModal';
import { GreyButton } from '../../../components/GreyButton';
import { CustomYellowButton } from '../../../components/CustomYellowButton';

export const AnalyticalModalTwoCopies = ({ openModal, handleCloseModal, copieReason, handleChangeReason, options }) => {
  return (
    <>
      {options.copiesReasons && (
        <CustomModal open={openModal} handleClose={handleCloseModal}>
          <Typography variant="body1">Motivo de copia</Typography>
          <Divider />
          <br />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={copieReason}
              label="Motivo"
              onChange={handleChangeReason}>
              {options?.copiesReasons.map((e) => (
                <MenuItem key={e.id} value={e.id_reason}>
                  {e.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />

          <Box sx={{ float: 'right', display: 'flex' }}>
            <GreyButton action={handleCloseModal} text={'Cerrar'} />
            <CustomYellowButton action={handleCloseModal} disabled={!copieReason} title={'Guardar'} />
          </Box>
        </CustomModal>
      )}
    </>
  );
};
