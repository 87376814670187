import { FormControl, MenuItem, Select, TableCell, InputLabel } from '@mui/material';
import { useState } from 'react';

const CLEAN_OPTIONS = -1;

export const MultipleSelectHeader = ({ options, updateSelect, field, label, idHeaderSelect, width, blockRow }) => {
  const [value] = useState('');
  const onSelect = (e) => {
    updateSelect(e.target.value === CLEAN_OPTIONS ? '' : e.target.value, field);
  };

  return (
    <TableCell width={width}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="demo-simple-select-label" shrink={false}>
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value ? value : ''}
          onChange={onSelect}
          disableUnderline
          disabled={blockRow}
          displayEmpty>
          <MenuItem key={'nullOption' + field} value={CLEAN_OPTIONS}>
            Limpiar Opciones
          </MenuItem>
          {options?.map((x) => {
            return (
              <MenuItem key={x.id + label} value={!idHeaderSelect ? x.id : x[idHeaderSelect]}>
                {x.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </TableCell>
  );
};
