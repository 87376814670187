import PropTypes from 'prop-types';
import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import RegularTableRow from './RegularTableRow';
import { stylesRows } from '../styles/Styles';

// Lo que llega a `rows` es `row.analyticals`.
/**
 * Tipo de row que es desplegable. Renderiza la cantidad de registros que se le pasa por `rows`.
 * @param {*} rows Array de rows por analítico a renderizar dentro de la row desplegable.
 * @param {*} headCellComponent Componente JSX que se renderizará como label de la row que se desplegará.
 * @param {*} actions Array de acciones cuyos íconos se renderizaran en la row desplegable.
 * @returns
 */
const CollapsibleTableRow = ({ columns, rows, checkable, onCheck, headCellComponent, actions }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className="collapsible-row">
        <TableCell colSpan={5} sx={{ ...stylesRows }}>
          <Box sx={{ display: 'flex' }}>
            <IconButton sx={{ mr: 2, width: '1.7em', height: '1.7em', my: 'auto', p: 0 }} onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>

            {headCellComponent}
          </Box>
        </TableCell>
        {actions && (
          <TableCell key={actions.id + ' Acciones'} sx={{ ...stylesRows }}>
            <Box sx={{ alignItems: 'center' }}>{actions ? actions.map((x) => x.html) : null}</Box>
          </TableCell>
        )}
      </TableRow>
      {open && rows
        ? rows.map((row, index) => (
            <RegularTableRow
              key={row.id}
              columns={columns}
              row={row}
              checkable={checkable}
              onCheck={onCheck}
              collapsibleChild
              firstChild={index === 0}
              lastChild={index === rows.length - 1}
            />
          ))
        : null}
    </>
  );
};

CollapsibleTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  checkable: PropTypes.bool,
  onCheck: PropTypes.func,
  headCellComponent: PropTypes.object.isRequired
};

export default CollapsibleTableRow;
