import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDecimal } from '../../../helpers/decimalHelper';
import {
  deleteAnalyticObserve,
  getStepsInfoAnalitic,
  getStepThreeCurriculumBox,
  getStepThreeCurriculumBoxAll,
  patchAnalyticObserve,
  patchSignAnalytic,
  patchValidateAnalytic,
  postAnalyticObserve
} from '../../../services/AnalyticalServices';
import { cancelAnalytic, patchUnsubscribeAnalytic } from '../../../services/HistoricalServices';
import { Complete } from '../../../styles/Styles';
import { month } from '../../../tools/months';
import { AnalyticalDetailContext } from '../AnalyticalContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { useContext } from 'react';
import { AppContext } from '../../global/AppContext';

export const AnalyticalDetailState = ({ children }) => {
  const [studentSelectedTable, setStudentSelectedTable] = useState({});
  const [infoAnaliticData, setInfoAnaliticData] = useState();
  const [levelsList, setLevelsList] = useState([]);
  const { isUserAdminOrLegalization } = useAuthHelper();
  const { user } = useContext(AppContext);

  const { id } = useParams();
  const ID = parseInt(id);

  // Detalles
  const [level, setLevel] = useState(1);
  const [curriculumBox, setCurriculumBox] = useState([]);
  const [signOk, setSignOk] = useState();

  useEffect(() => {
    if (ID) {
      getStepsInfoAnaliticData(ID);
      getAllLevels(ID);
      getStepThreeCurriculumBoxData(ID, level);
    }
  }, [signOk, id]);

  useEffect(() => {
    if (ID) getStepThreeCurriculumBoxData(ID, level);
  }, [level]);

  const getStepsInfoAnaliticData = async (id, previousState) => {
    // Se calcula el ID para los casos en que el user es EQUIPO DE CONDUCCIÓN o VALIDADOR:
    const calculatedEstablishmendId = !isUserAdminOrLegalization()
      ? user.establishments.filter((establishment) => establishment.id === user.establishmentId)[0].id
      : null;
    const res = await getStepsInfoAnalitic(id, previousState, calculatedEstablishmendId);
    setInfoAnaliticData(res);
    setStudentSelectedTable(res.step1);
  };

  const getStepThreeCurriculumBoxData = async (id, level) => {
    const res = await getStepThreeCurriculumBox(id, level);
    const months = month();
    setCurriculumBox(
      res.map((e) => {
        if (e.study_plan_curricular_box) {
          e.course = e.study_plan_curricular_box.course;
          e.qualification = e.qualification
            ? e.qualification?.qualification
            : e.numeric_qualification
            ? formatDecimal(parseFloat(e.numeric_qualification))
            : '';
          e.condition = <Complete>{e.conditions?.condition}</Complete>;
          e.month = e.month ? months[e.month - 1].name : '';
          e.establishment_name = e.is_own ? 'Este establecimiento' : e.other_establishment;
        }
        return e;
      })
    );
  };

  const postAnalyticNote = async (body) => {
    // eslint-disable-next-line no-unused-vars
    await postAnalyticObserve(body);
    //esto esta PESIMO pero la situacion es insolucionable, hay que refactorizar el backend
    getStepsInfoAnaliticData(ID, studentSelectedTable.analytic_status_id);
  };

  const patchAnalyticNote = async (body) => {
    await patchAnalyticObserve(body);
    //esto esta PESIMO pero la situacion es insolucionable, hay que refactorizar el backend
    getStepsInfoAnaliticData(ID, studentSelectedTable.analytic_status_id);
  };

  const deleteAnalyticNote = async (body) => {
    await deleteAnalyticObserve(body);
    //esto esta PESIMO pero la situacion es insolucionable, hay que refactorizar el backend
    getStepsInfoAnaliticData(ID, studentSelectedTable.analytic_status_id);
  };

  const patchSignAnalyticData = async () => {
    let res = await patchSignAnalytic(id);
    if (res.msg) {
      setSignOk(res.msg);
    }
  };

  const patchValidateAnalyticData = async () => {
    await patchValidateAnalytic(id);
    getStepsInfoAnaliticData(ID, studentSelectedTable.analytic_status_id);
  };

  // Trae una lista con los años finalizados
  const getAllLevels = async (id) => {
    let res = await getStepThreeCurriculumBoxAll(id);
    let levels = [...res.levels];
    setLevelsList(levels);
  };

  const unsubscribe = async (analytic) => {
    await patchUnsubscribeAnalytic(analytic);
    getStepsInfoAnaliticData(id, studentSelectedTable.analytic_status_id);
  };

  const handleCancelAnalytic = async (request) => {
    await cancelAnalytic(request);
    getStepsInfoAnaliticData(id, studentSelectedTable.analytic_status_id);
  };

  const getStudentStepOneData = () => studentSelectedTable.analytic_student;

  return (
    <>
      <AnalyticalDetailContext.Provider
        value={{
          studentSelectedTable,
          infoAnaliticData,
          setLevel,
          level,
          analyticsHtmlCurriculumBox: curriculumBox,
          levelsList,
          postAnalyticNote,
          patchAnalyticNote,
          deleteAnalyticNote,
          patchSignAnalyticData,
          patchValidateAnalyticData,
          getAllLevels,
          unsubscribe,
          handleCancelAnalytic,
          getStudentStepOneData
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </AnalyticalDetailContext.Provider>
    </>
  );
};
