import jwt from 'jwt-decode';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Actions, Roles } from '../../constants/Actions';
import { SUPER_ADMIN } from '../../constants/Roles';
import { getLogOutService, getUserService } from '../../services/LoginServices';
import { ExistsPendingNotifications } from '../../services/notificationService';
import { AppContext } from './AppContext';

const handleUnload = () => {
  if (localStorage.getItem('token')) {
    fetch(process.env.REACT_APP_URL_BACKEND + '/auth/potentialLogout', {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: 'application/json, text/plain, */*'
      }
    });
  }
};

export const AppState = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const location = useLocation();
  const [hasNotifications, setHasNotifications] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    handleUnload();
    if (!token) {
      getToken();
    }
  }, []);

  useEffect(() => {
    if (token) {
      checkExpired();
    }
    if (token && !user) {
      getUser();
    }
  }, [token]);

  const checkExpired = () => {
    if (jwt(token).exp < new Date() / 1000) {
      logout();
    }
  };

  const getUser = async () => {
    try {
      const res = await getUserService();
      const actions = concatActions(res.user_roles_user);
      const mappedUser = {
        first_name: res.first_name,
        last_name: res.last_name,
        cuit: res.cuit,
        id: res.id,
        google_picture: res.google_picture,
        establishments: res?.user_establishments.map((x) => {
          return {
            name: x.users_establishment.establishment_name,
            id: x.establishment_id,
            is_public: x.users_establishment.is_public,
            level: x?.users_establishment?.level?.charAt(0).toUpperCase() + x?.users_establishment?.level?.slice(1).toLowerCase(),
            management: x.users_establishment.is_public ? 'Estatal' : 'Privada',
            is_terciary: x.users_establishment.isEnableNewCareer || !(x.users_establishment.level === "SECUNDARIO")
          };
        }),
        roles: res?.user_roles_user.map((x) => x.role_id),
        hasGlobalRol: res?.user_roles_user.some((x) => x.user_roles_role.is_global || x.role_id === SUPER_ADMIN),
        actions: actions,
        establishmentId:
          res?.user_establishments && res.user_establishments.length > 0 && !actions.includes(Actions.SELECT_ESTABLISHMENT)
            ? res.user_establishments[0].establishment_id
            : null
      };
      setUser(mappedUser);
      localStorage.setItem('user', JSON.stringify(mappedUser));
    } catch {
      logout();
    }
  };

  const concatActions = (roles) => {
    let actions = [];
    if (roles) {
      roles.forEach((element) => {
        if (element.user_roles_role) {
          element.user_roles_role.role_roles_default_actions.forEach((x) => {
            actions.push(x.default_actions_id);
          });
        }
      });
    }
    return actions.filter(onlyUnique);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const getToken = () => {
    const { accessToken } = queryString.parse(location.search);
    if (accessToken) {
      localStorage.setItem('token', accessToken);
      setToken(accessToken);
      return true;
    }
    return false;
  };

  const selectEstablishment = (value, establishment) => {
    let mappedUser = { ...user, establishmentId: value };
    if (user.roles.includes(Roles.SUPERADMIN)) {
      mappedUser.establishments = [
        {
          id: establishment.id,
          name: establishment.name,
          is_public: establishment.is_public,
          is_terciary: establishment.is_terciary
        }
      ];
    }
    setUser(mappedUser);
    localStorage.setItem('user', JSON.stringify(mappedUser));
  };

  const logout = async () => {
    await getLogOutService();
    localStorage.clear();
    setToken(null);
    setUser(null);
  };

  const hasPendingNotifications = async () => {
    if (user && user.establishmentId) {
      const exists = await ExistsPendingNotifications(user.establishmentId);
      setHasNotifications(exists);
    }
  };

  return (
    <AppContext.Provider
      value={{
        token,
        hasNotifications,
        getUser,
        getToken,
        logout,
        selectEstablishment,
        hasPendingNotifications,
        user
      }}>
      {children}
    </AppContext.Provider>
  );
};
