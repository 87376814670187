import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableAction } from '../../../components/TableAction';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { Actions } from '../../../constants/Actions';
import { EstablishmentContext } from '../../../context/establishment/EstablishmentContext';
import { AppContext } from '../../../context/global/AppContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const EstablishmentTable = () => {
  const { selectEstablishment } = useContext(AppContext);
  const navigate = useNavigate();
  const { addAndAuthTableAction, isSuperAdmin } = useAuthHelper();
  const { enqueueSnackbar } = useSnackbar();
  const { establishments, paginationProps, updateInfoSuperAdmin, updateInfoEstablishment } = useContext(EstablishmentContext);

  const headers = [
    { label: 'Establecimiento', field: 'name' },
    { label: 'Nivel', field: 'level' },
    { label: 'Gestión', field: 'management', hidden: true }
  ];

  const handleDoneIcon = (e) => {
    if (e) {
      selectEstablishment(e.id, e);
      enqueueSnackbar('Ha seleccionado su establecimiento correctamente', { variant: 'success' });
      navigate('/');
    }
  };

  const data = useMemo(
    () =>
      establishments?.map((x) => {
        let e = { ...x };
        e.actions = [];

        addAndAuthTableAction(
          <div key={e.name}>
            <Box sx={{ float: 'center' }}>
              <TableAction tooltipTitle={'Seleccionar'} onClick={() => handleDoneIcon(e)} iconElement={<DoneIcon color="primary" />} />
            </Box>
          </div>,
          [Actions.SELECT_ESTABLISHMENT],
          e.actions
        );
        return e;
      }),
    [establishments]
  );

  return (
    <>
      <TableGenericComponent
        displayPagination={true}
        page={paginationProps.page || 0}
        rowsPerPage={paginationProps.limit || 0}
        count={paginationProps.count || 0}
        handleChange={(limit, page) => (isSuperAdmin() ? updateInfoSuperAdmin(limit, page) : updateInfoEstablishment(limit, page))}
        bar={false}
        columns={headers}
        rows={data}
        hasActions={true}
        message="No hay establecimientos para mostrar."
      />
    </>
  );
};
