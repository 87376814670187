import { NotificationState } from '../../context/notification/notificationState';
import { NotificationHead } from './NotificationHead';
import { NotificationTable } from './NotificationTable';
import { Grid } from '@mui/material';

export default function NotificationPage() {
  return (
    <>
      <NotificationState>
        <Grid sx={{ mt: 4, mb: 4 }}>
          <NotificationHead />
          <NotificationTable />
        </Grid>
      </NotificationState>
    </>
  );
}
