import { Box, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { Actions } from '../../../constants/Actions';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';

export const AnalyticalHead = () => {
  const { authorizeElement } = useAuthHelper();
  const navigate = useNavigate();
  const handleCreateAnalytical = () => {
    localStorage.removeItem('action');
    localStorage.setItem('action',"headerNewCareer");
    navigate(AnalyticRouteConstants.PASO1_NUEVO);
  };
  const params = useParams();

  return (
    <>
      {(params.id ? params.id : localStorage.getItem('analyticalEstablishmentId')) && <CustomBreadcrumbs base={'Analíticos'} now={(params.name ? params.name : localStorage.getItem('analyticalEstablishmentName'))} baseLink={AnalyticRouteConstants.BUSCAR_AGRUPADO} />}

      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {(params.id ? params.id : localStorage.getItem('analyticalEstablishmentId')) ? (
            <Typography variant="h4" color="initial">
              {(params.name ? params.name : localStorage.getItem('analyticalEstablishmentName'))}
            </Typography>
          ) : (
            <Typography variant="h3" color="initial">
              Analíticos
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ float: 'right', mt: 1 }}>
            {authorizeElement(<CustomYellowButton title={'+ Agregar alumno/a'} action={handleCreateAnalytical} />, [
              Actions.CREATE_ANALYTIC
            ])}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
