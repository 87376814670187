import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCurriculumById, getCurriculumsTitles, patchCurriculum, postCurriculum } from '../../services/CurriculumServices';
import { AppContext } from '../global/AppContext';
import { CurriculumStepOneContext } from './CurriculumContext';
import { useContext } from 'react';

export const CurriculumStepOneState = ({ children }) => {
  let { user } = useContext(AppContext);
  let params = useParams();
  const [isNewStudyPlan, setIsNewStudyPlan] = useState(false);

  useEffect(() => {
    getAllCurriculumsTitlesData();
    if (params.id) {
      getCurriculumByIdData(params.id);
    }
  }, []);

  const [options, setOptions] = useState();
  const [formData, setFormData] = useState();
  const [stepOneInfo] = useState();

  const getAllCurriculumsTitlesData = async () => {
    let res = await getCurriculumsTitles(user.establishmentId);
    try {
      setOptions({
        curriculums: [{ id: '', name: 'Seleccione esta opción si quiere ingresar un nuevo plan' }, ...res],
        modality: [
          { id: 1, name: 'Años' },
          { id: 2, name: 'Ciclos' }
        ],
        numberYears: [
          { id: 2, name: '2' },
          { id: 3, name: '3' },
          { id: 4, name: '4' },
          { id: 5, name: '5' },
          { id: 6, name: '6' },
          { id: 7, name: '7' }
        ]
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getCurriculumByIdData = async (id) => {
    let res = await getCurriculumById(id);
    setIsNewStudyPlan(res.is_preloaded === null ? null : !res.is_preloaded);
    try {
      setFormData(res);
    } catch (error) {
      console.error(error);
    }
  };

  const patchCurriculumModified = async (body, isNewOrObserved) => {
    let sendBody = { ...body };
    sendBody.study_plan_modality_id = parseInt(sendBody.study_plan_modality_id);
    sendBody.studyPlanId = isNewOrObserved ? formData.id : formData.id.id;
    sendBody.study_plan_status_id = formData.study_plan_status_id;
    sendBody.is_preloaded = !isNewStudyPlan;
    await patchCurriculum(sendBody);
  };

  const createStudyPlanByAmendingResolution = async (form, forceTrue) => {
    let x = { ...form };
    x.study_plan_modality_id = parseInt(form.study_plan_modality_id);
    if (form.id && form.amending_resolution) {
      let studyPlan = options.curriculums.filter((x) => x.id === form.id)[0];
      x.id = studyPlan.id;
      x.study_plan_name = studyPlan.name;
      x.title = studyPlan.title;
    } else {
      x.id = null;
    }
    x.is_preloaded = forceTrue || !isNewStudyPlan;
    let newId = await postCurriculum(x);
    return newId;
  };

  return (
    <>
      <CurriculumStepOneContext.Provider
        value={{
          options,
          stepOneInfo,
          formData,
          getCurriculumByIdData,
          patchCurriculumModified,
          createStudyPlanByAmendingResolution,
          setIsNewStudyPlan,
          isNewStudyPlan
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </CurriculumStepOneContext.Provider>
    </>
  );
};
