import { useEffect, useState } from 'react';
import { getAnalyticCopiesReasons, getAnalyticCopyOriginalReasons } from '../services/AnalyticalServices';

export const useReasonsInfo = (analyticId) => {
  const [reasons, setReasons] = useState(null);
  const [copyReasons, setCopyReasons] = useState(null);

  useEffect(() => {
    getReasons();
    onGetCopyReasons();
  }, []);

  const getReasons = async () => {
    const result = await getAnalyticCopyOriginalReasons(analyticId);
    setReasons(result);
  };

  const onGetCopyReasons = async () => {
    const result = await getAnalyticCopiesReasons();
    setCopyReasons(result);
  };

  return { reasons, copyReasons };
};
