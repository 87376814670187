import { Autocomplete, FormControl, TableCell, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const CalificationCell = ({ controlConfig, updateGrid, canLoadMaximumQualification }) => {
  const { initialValue, rowId, field, options, width, optionsText, disabled = false, max } = controlConfig;

  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const auth = useAuthHelper();

  const onAutocompleteNumericSelect = () => {
    if (inputValue) {
      let valueReplace = inputValue.replace(',', '.');
      if (isNaN(valueReplace) && !options.find((x) => x.name === valueReplace)) {
        valueReplace = '';
        setInputValue(valueReplace);
        enqueueSnackbar('El campo debe ser o bien un número, o una de las opciones listadas', { variant: 'warning' });
      } else if (!isNaN(valueReplace)) {
        valueReplace = auth.validateNote(valueReplace, canLoadMaximumQualification);
        setInputValue(valueReplace);
      }
      updateGrid(valueReplace, rowId, field);
    } else {
      updateGrid(inputValue, rowId, field);
    }
  };

  return (
    <TableCell width={width}>
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          autoSelect
          disabled={disabled}
          freeSolo
          id="combo-box-demo"
          inputValue={inputValue || ''}
          onInputChange={(event, value) => setInputValue(value)}
          onChange={(event, value) => setInputValue(value)}
          onBlur={onAutocompleteNumericSelect}
          value={inputValue ? inputValue : ''}
          options={options ? options.map((x) => x.name) : []}
          renderInput={(params) => (
            <TextField
              value={inputValue}
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
              inputProps={{
                ...params.inputProps,
                maxLength: max ? max : 100
              }}
            />
          )}
          noOptionsText={optionsText ? optionsText : 'No hay opciones que coincidan con la búsqueda'}
        />
      </FormControl>
    </TableCell>
  );
};
