import { Autocomplete, FormControl, TableCell, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export const EstablishmentCell = ({ controlConfig, updateGrid }) => {
  const { initialValue, rowId, field, options, width, optionsText, disabled = false } = controlConfig;

  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const onAutocompleteEstablishmentSelect = () => {
    updateGrid(inputValue, rowId, field);
  };

  return (
    <TableCell width={width}>
      <FormControl fullWidth>
        <Autocomplete
          fullWidth
          disabled={disabled}
          freeSolo
          id="combo-box-demo"
          onBlur={onAutocompleteEstablishmentSelect}
          value={inputValue || ''}
          inputValue={inputValue || ''}
          onInputChange={(e) => {
            setInputValue(e.target.value);
          }}
          onChange={(e, value) => {
            setInputValue(value);
          }}
          options={options || []}
          renderInput={(params) => (
            <TextField
              value={initialValue}
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
              inputProps={{
                ...params.inputProps,
                maxLength: 15
              }}
            />
          )}
          noOptionsText={optionsText ? optionsText : 'No hay opciones que coincidan con la búsqueda'}
        />
      </FormControl>
    </TableCell>
  );
};
