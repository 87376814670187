import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { AnalyticalEstablishmentContext } from '../../../context/Analytical/AnalyticalContext';

const INITIAL_PAGE = 0;
const LIMIT_PAGE = 10;

export const AnalyticalEstablishmentFilters = () => {
  const { getAnalyticalEstablishmentData, allAnalyticalStatus } = useContext(AnalyticalEstablishmentContext);

  const [analyticalState, setAnalyticalState] = useState('');
  const [establishmentOrDocument, setEstablishmentOrDocument] = useState('');

  const handleChangeSelect = (event) => {
    setAnalyticalState(event.target.value);
    getAnalyticalEstablishmentData(INITIAL_PAGE, LIMIT_PAGE, establishmentOrDocument, event.target.value || '');
  };

  const handleInputestablishmentOrDocument = (event) => setEstablishmentOrDocument(event.target.value);

  const handleSearchestablishmentOrDocument = (event) => {
    event.preventDefault();
    getAnalyticalEstablishmentData(INITIAL_PAGE, LIMIT_PAGE, establishmentOrDocument, analyticalState);
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          <FormControl sx={{ minWidth: '15em', backgroundColor: '#F3F6F9', border: 'none' }}>
            <InputLabel id="demo-simple-select-label">Todos los estados</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={analyticalState}
              label="Todos los estados"
              onChange={handleChangeSelect}>
              {allAnalyticalStatus.length > 1 &&
                allAnalyticalStatus.map((eachState) => {
                  return (
                    <MenuItem key={eachState?.id} value={eachState?.value}>
                      {eachState?.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={handleSearchestablishmentOrDocument}>
            <TextField
              autoComplete="off"
              id="outlined-required"
              label="Buscar CUE o establecimiento"
              fullWidth
              sx={{ backgroundColor: '#F3F6F9', border: 'none', marginRight: 2 }}
              value={establishmentOrDocument}
              onChange={handleInputestablishmentOrDocument}
            />
          </form>
        </Grid>
      </Grid>
      <br />
    </>
  );
};
