import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateOrEditResource } from '../../../hooks/useCreateOrEditResource';
import { getRoles } from '../../../services/admin/rolesService';
import { getUserById, patchUser, postUser } from '../../../services/admin/userService';
import { getEstablishmentsAutocomplete } from '../../../services/establishmentServices';
import { EditUserContext } from './EditUserContext';
import { getUserPositions } from '../../../services/ProfileServices';

const emptyOptions = { roles: [], establishments: [] };

export const EditUserState = ({ children }) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState(emptyOptions);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    let roles = await getRoles();
    let establishments = await getEstablishmentsAutocomplete();
    let positions = await getUserPositions();
    setOptions({
      roles: roles,
      establishments: establishments.map((x) => {
        let publicLabel = x.is_public ? 'ESTATAL' : 'PRIVADA';
        let level = x.level;
        x.name = `${x.cue} ${x.annexed} - ${x.establishment_name} (${publicLabel}) (${level})`;
        return x;
      }),
      positions: positions.map((position) => {
        position.name = position.description;
        return position;
      }),
    });
  };

  const getUser = async (id) => {
    let userData = await getUserById(id);

    setUserInfo(userData);
  };

  const createUser = async (params) => {
    let user = { ...params.form };
    await postUser(user);
    navigate(-1);
  };

  const editUser = async (params) => {
    let user = { ...params.form };
    await patchUser(user);
    navigate(-1);
  };

  const resourceHandler = useCreateOrEditResource(editUser, createUser, getUser);

  return (
    <EditUserContext.Provider
      value={{
        userInfo: userInfo,
        options: options,
        emptyOptions: emptyOptions,
        onSaveUser: resourceHandler.onSave
      }}>
      {children}
    </EditUserContext.Provider>
  );
};
