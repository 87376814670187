import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const Modal = (props) => {
  const { title, body, actions } = props;

  return (
    <Dialog open={true} sx={{ padding: '2em', '& .MuiPaper-root': { padding: 2, boxShadow: 'none' } }} maxWidth="xl">
      <DialogTitle sx={{ borderBottom: '1px solid #E6EBF0' }}>{title}</DialogTitle>
      <DialogContent sx={{ marginTop: '20px' }}>{body}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
