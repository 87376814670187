import { Container } from '@mui/material';
import { CurriculumEstablishmentContext } from './CurriculumContext';
import { useContext } from 'react';
import { AppContext } from '../global/AppContext';
import { useTable } from '../../hooks/useTable';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllEstablishments, getStatusCurriculum } from '../../services/CurriculumServices';

export const CurriculumEstablishmentState = ({ children }) => {
  const { user } = useContext(AppContext);
  const tableInfo = useTable(true);
  const [status, setStatus] = useState([]);
  const { paginationProps, data } = tableInfo;

  const [curriculumState, setCurriculumState] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getAllEstablishmentsData();
    getStatusData();
  }, []);

  const handleChangeSelect = async (event) => {
    event.preventDefault();
    await setCurriculumState(event.target.value);
    if (searchText != undefined) {
      getAllEstablishmentsData(0, 10, event.target.value, searchText);
    } else {
      getAllEstablishmentsData(0, 10, event.target.value);
    }
  };

  const handleSearch = (event) => {
    const trimmedSearchText = searchText.trim();
    event.preventDefault();
    if (trimmedSearchText && curriculumState) {
      getAllEstablishmentsData(0, 10, curriculumState, trimmedSearchText);
    } else if (trimmedSearchText) {
      getAllEstablishmentsData(0, 10, '', trimmedSearchText);
    } else if (curriculumState) {
      getAllEstablishmentsData(0, 10, curriculumState);
    } else if (!trimmedSearchText && !curriculumState) {
      getAllEstablishmentsData(0, 10);
    }
  };

  const getAllEstablishmentsData = async (page = 0, limit = paginationProps.default_limit, studyPlanStatusId = '', term = '') => {
    const response = await getAllEstablishments(page, limit, term, studyPlanStatusId);
    tableInfo.onChange(response.data, limit, page, response.count);
  };

  // ¿Éste habría que cambiarlo? ¿Sirve para la vista de Establecimientos?
  const getStatusData = async (studyPlanId) => {
    let data = await getStatusCurriculum(studyPlanId, user.establishmentId);
    data.unshift({ id: '', name: 'Todos los estados' });
    setStatus(data);
  };

  const handleEstablishmentPageChange = (limit, page) => {
    getAllEstablishmentsData(page, limit, curriculumState, searchText);
  };

  return (
    <>
      <CurriculumEstablishmentContext.Provider
        value={{
          page: paginationProps.page,
          limit: paginationProps.limit,
          establishments: data,
          status,
          count: paginationProps.count,
          getAllEstablishmentsData,
          handleChangeSelect,
          handleSearch,
          handleEstablishmentPageChange,
          setSearchText
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </CurriculumEstablishmentContext.Provider>
    </>
  );
};
