import { useState } from "react"
import { useTable } from "../../../hooks/useTable"
import { getAllUsersServices } from "../../../services/ProfileServices"
import { UsersContext } from "./UsersContext"

export const UsersState = ({ children }) => {
    const tableInfo = useTable(true);
    // const [modalState, setModalState] = useState(false)
    const [inputSearch, setInputSearch] = useState("")


    const search = async (stateModal, limit, page = 0) => {
        setInputSearch(stateModal)
        let pagination = {
            limit: limit ? limit : tableInfo.paginationProps.limit,
            page: page >= 0 ? page : tableInfo.paginationProps.page
        };
        let data = await getAllUsersServices(stateModal, pagination.limit, pagination.page + 1)

        let mapData = data.items.map(e => {
            e.rol = e.roles.join()
            e.establishment = e.establishments.join("; ")
            return e
        })
        tableInfo.onChange(mapData, pagination.limit, pagination.page, data.meta.totalItems)

    }

    const handlePageChange = (limit, page) => {
        search(inputSearch, limit, page)
    }


    return (
        <UsersContext.Provider value={{
            search,
            items: tableInfo.data,
            paginationProps: tableInfo.paginationProps,
            handlePageChange,
            inputSearch
        }}>
            {children}
        </UsersContext.Provider>
    )
}