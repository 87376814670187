import { Typography } from '@mui/material';
// import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
// import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';

export const AnalyticalEstablishmentHead = () => {
  // const { user } = useContext(AppContext);
  // const [establishment] = JSON.parse(localStorage.getItem('user')).establishments;

  //TODO REVISAR ESTO
  return (
    <>
      {/* <CustomBreadcrumbs base={'Establecimientos'} now={''} baseLink={AnalyticRouteConstants.BUSCAR_AGRUPADO} /> */}
      <Typography variant="h4" color="initial" mb={2} sx={{ fontSize: '37px', fontWeight: '700' }}>
        {'Analíticos'}
      </Typography>
    </>
  );
};
