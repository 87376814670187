import { useContext, useEffect, useState } from 'react';
import { useTable } from '../../../hooks/useTable';
import { AppContext } from '../../global/AppContext';
import { HistoricalEstablishmentContext } from './HistoricalEstablishmentContext';
import { getHistoricalStablishmentStudents } from '../../../services/HistoricalServices';

export const HistoricalEstablishmentState = ({ children }) => {
  const [termFilter, setTermFilter] = useState('');
  const [yearFilter, setYearFilter] = useState('');
  const tableInfo = useTable(true);
  const paginationProps = tableInfo.paginationProps;
  const { user } = useContext(AppContext);

  useEffect(() => {
    if (user) {
      initTable();
    }
  }, []);

  const initTable = () => {
    getEstablishment(10, 0, termFilter);
  };

  const getEstablishment = async (limit = paginationProps.limit, page = paginationProps.page, term = termFilter, year) => {
    const data = await getHistoricalStablishmentStudents(page, limit, term, year);
    tableInfo.onChange(data.data, limit, page, data.count)
  };

  const onChangePage = async (limit, page) => {
    getEstablishment(limit, page, termFilter, yearFilter);
  };
  const onChangeYear = async (year) => {
    await getEstablishment(paginationProps.limit, 0, termFilter, year);
    setYearFilter(year);
  };

  const onChangeTerm = async (term) => {
    await getEstablishment(paginationProps.limit, 0, term, yearFilter);
    setTermFilter(term);
  };

  return (
    <HistoricalEstablishmentContext.Provider
      value={{
        establishments: tableInfo.data || [],
        paginationProps: tableInfo.paginationProps,
        onChangePage,
        onChangeYear,
        onChangeTerm
      }}>
      {children}
    </HistoricalEstablishmentContext.Provider>
  );
};
