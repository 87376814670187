import { Alert, Typography } from '@mui/material';

export const ObservationDetailAlert = ({ observationDescription }) => {
  return (
    <Alert variant="outlined" severity="warning" sx={{ mt: 4, width: '100%', fontSize: 16, borderLeft: '8px solid #ff9800' }}>
      <b>Plan de estudios observado</b>
      <br />
      <Typography>{observationDescription}</Typography>
    </Alert>
  );
};
