import { Grid, TextField, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import { useContext } from 'react';
import { AnalyticalContext } from '../../../context/Analytical/AnalyticalContext';
import { useParams } from 'react-router-dom';

export const AnalyticalFilters = () => {
  const {
    allAnalyticalStatus,
    handleSearchAllParams,
    handleInputStudent,
    handleInputYear,
    handleInputYearSchool,
    handleChangeSelect,
    year,
    yearSchool,
    analyticalState,
    student,
  } = useContext(AnalyticalContext);

  const params = useParams();

  return (
    <>
      <br />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={3}>
          <FormControl sx={{ minWidth: '15em', backgroundColor: '#F3F6F9', border: 'none' }}>
            <InputLabel id="demo-simple-select-label">Todos los estados</InputLabel>
            {params.id ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={analyticalState}
                label="Todos los estados"
                onChange={handleChangeSelect}>
                {allAnalyticalStatus.length > 1 && allAnalyticalStatus.map((eachState) => {
                  return (
                    <MenuItem key={eachState?.id} value={eachState?.id}>
                      {eachState?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              allAnalyticalStatus.length != 0 && (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={analyticalState}
                  label="Todos los estados"
                  onChange={handleChangeSelect}>
                  {allAnalyticalStatus.map((eachState) => {
                    return (
                      <MenuItem key={eachState?.id} value={eachState?.id}>
                        {eachState?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )
            )}
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <form onSubmit={handleSearchAllParams}>
            <TextField
              label="Año/Ciclo"
              styles={{ fontSize: 12, backgroundColor: '#F3F6F9', border: 'none', marginRight: 1 }}
              value={year}
              onChange={handleInputYear}
              type="number"
              limit={1}
            />
          </form>
        </Grid>
        <Grid item xs={2}>
          <form onSubmit={handleSearchAllParams}>
            <TextField
              label="Ciclo Lectivo"
              styles={{ fontSize: 12, backgroundColor: '#F3F6F9', border: 'none', marginRight: 1 }}
              value={yearSchool}
              onChange={handleInputYearSchool}
              type="number"
              limit={4}
            />
          </form>
        </Grid>
        <Grid item xs={5}>
          <form onSubmit={handleSearchAllParams}>
            <TextField
              autoComplete="off"
              id="outlined-required"
              label="Buscar alumno/a o documento"
              fullWidth
              sx={{ backgroundColor: '#F3F6F9', border: 'none' }}
              value={student}
              onChange={handleInputStudent}
            />
          </form>
        </Grid>
      </Grid>
      <br />
    </>
  );
};
