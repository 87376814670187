import Container from '@mui/material/Container';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getCurricularBoxById, getCurriculumById, patchStepTwo, postAssociateCurriculum } from '../../services/CurriculumServices';
import { CurriculumStepTwoContext } from './CurriculumContext';
import { AppContext } from '../global/AppContext';
import { v4 as uuid } from 'uuid';

export const CurriculumStepTwoState = ({ children }) => {
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [curriculumBox, setCurriculumBox] = useState([]);
  const [curriculum, setCurriculum] = useState([]);

  useEffect(() => {
    loadInfo(id);
  }, [id]);

  const loadInfo = async (id) => {
    let newCurriculum = await getCurriculumByIdData(id);
    getCurriculumBoxData(id, newCurriculum);
  };

  const getCurriculumBoxData = async (id, newCurriculum) => {
    const res = await getCurricularBoxById(id);
    if (res.length) {
      setCurriculumBox(res.filter((x) => x.level <= newCurriculum.years));
    } else {
      let ob = [];
      for (let i = 0; i < 10; i++) {
        for (let j = 0; j < newCurriculum.years; j++) {
          ob.push({ level: j + 1, studyPlan_id: newCurriculum.id, id: uuid(), isNew: true, course: '', aproved: false });
        }
      }
      setCurriculumBox(ob);
    }
  };

  const getCurriculumByIdData = async (id) => {
    const res = await getCurriculumById(id);
    setCurriculum(res);
    return res;
  };

  const postAssociate = async (studyPlanId) => {
    await postAssociateCurriculum(studyPlanId, localStorage.getItem('establishmentId') || user.establishmentId);
  };

  const SendToPendingRequest = async (curricularBox) => {
    curricularBox.forEach((element) => {
      element.id = element.isNew ? null : parseInt(element.id);
      element.study_plan_id = parseInt(id);
      return element;
    });
    let res = await patchStepTwo({
      id: parseInt(id),
      curricular_box: curricularBox,
      establishment_id: parseInt(localStorage.getItem('establishmentId') || user.establishmentId)
    });
    return res;
  };

  return (
    <>
      <CurriculumStepTwoContext.Provider
        value={{
          curriculumBox,
          curriculum,
          postAssociate,
          SendToPendingRequest
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </CurriculumStepTwoContext.Provider>
    </>
  );
};
