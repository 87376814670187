import { createTheme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { amber, blue, green, grey, red } from '@mui/material/colors';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const themeNavbar = createTheme({
  palette: {
    primary: {
      main: '#fff',
      mainGradient: 'linear-gradient(0deg, rgba(253,187,45,1) 0%, rgba(255,255,255,1) 9%);'
    }
  }
});

export const theme = createTheme({
  typography: {
    fontFamily: 'Nunito'
  },
  palette: {
    text: {
      primary: '#38485C'
    }
  }
});

theme.typography.h3 = {
  fontWeight: '700',
  color: '#38485C',
  fontSize: '37px',
  lineHeight: '44px'
};
theme.typography.h5 = {
  fontWeight: '700',
  color: '#38485C',
  fontFamily: 'Nunito, Gill Sans Extrabold, Helvetica, sans-serif !important'
};
theme.typography.h4 = {
  fontWeight: '700',
  color: '#38485C',
  fontSize: '20px',
  fontFamily: 'Nunito, Gill Sans Extrabold, Helvetica, sans-serif !important'
};

export const MyButton = styled(Button)`
  background-color: #ffdb2e;
  font-weight: 600;
  font-size: 16px;
  color: #38485c;
  box-shadow: none;
  height: 49px;
  :hover {
    background-color: #fdd306;
  }
`;

export const InProcess = styled(Typography)`
  color: ${blue[500]};
  font-size: 13px;
  background-color: #e3f2fd;
  border-radius: 2px;
  max-width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: max-content;
`;
export const Complete = styled(Typography)`
  color: ${blue[500]};
  font-size: 13px;
  background-color: #e3f2fd;
  border-radius: 2px;
  max-width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: max-content;
`;
export const Pending = styled(Typography)`
  color: ${amber[500]};
  font-size: 13px;
  background-color: #fff8e1;
  border-radius: 2px;
  max-width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: max-content;
`;
export const Validated = styled(Typography)`
  color: ${green[500]};
  font-size: 13px;
  background-color: #e8f5e9;
  border-radius: 2px;
  max-width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: max-content;
`;
export const Canceled = styled(Typography)`
  color: ${grey[500]};
`;
export const Observed = styled(Typography)`
  color: ${red[500]};
  font-size: 13px;
  background-color: #ffebee;
  border-radius: 2px;
  max-width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: max-content;
`;

export const Empty = styled(Typography)`
  color: ${grey[500]};
  font-size: 13px;
  border-radius: 2px;
  max-width: fit-content;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: max-content;
`;

export const stylesColumns = {
  fontSize: '16px'
};

export const stylesRows = {
  color: '#575757',
  fontSize: '14px',
  fontStyle: 'normal',
  '&:first-of-type': { maxWidth: '280px' }
};

export const rowsShadows = {
  left: 'inset 80px -60px 15px -86px rgb(0 0 0 / 0.5)',
  right: 'inset -80px -60px 15px -86px rgb(0 0 0 / 0.5)',
  bottom: 'inset 0px -80px 15px -86px rgb(0 0 0 / 0.5)',
  top: 'inset 0px 80px 15px -86px rgb(0 0 0 / 0.5)'
};

export const themeSidebar = createTheme({
  palette: {
    neutral: {
      main: '#005387',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: 'Nunito',
    fontSize: 19,
    fontWeight: '600'
  },
  '& .MuiButtonBase-root': {
    fontWeight: '600'
  }
});

export const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#38485C',
    boxShadow: theme.shadows[1],
    fontSize: 16
  }
}));
