import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTable } from '../../../hooks/useTable';
import { cancelAnalytic, getHistoricalStudents, patchUnsubscribeAnalytic } from '../../../services/HistoricalServices';
import { AppContext } from '../../global/AppContext';
import { HistoricalSearchContext } from './HistoricalSearchContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const HistoricalSearchState = ({ children }) => {
  const [termFilter, setTermFilter] = useState('');
  const [yearFilter, setYearFilter] = useState('');
  const tableInfo = useTable(true);
  const paginationProps = tableInfo.paginationProps;
  const { user } = useContext(AppContext);
  const { isUserAdminOrLegalization } = useAuthHelper();

  useEffect(() => {
    if (user) {
      initTable();
    }
  }, []);

  const initTable = () => {
    getStudents(10, 0, termFilter);
  };

  const getStudents = async (limit = paginationProps.limit, page = paginationProps.page, term = termFilter, year) => {
    const numberEstablishment = JSON.parse(localStorage.getItem('user'))?.establishments?.length >= 2;
    if(!numberEstablishment){
      !isUserAdminOrLegalization() && localStorage.setItem('historicalEstablishmentId', user.establishmentId);
    }
    
    const data = await getHistoricalStudents(
      page,
      limit,
      localStorage.getItem('historicalEstablishmentId') ?? user.establishmentId,
      term,
      year
    );
    const students = data.data.map((x) => {
      return {
        ...x,
        completeName: `${x.last_name.toUpperCase()} ${x.name.toUpperCase()}`,
        modified: moment(x.updated_at).format('DD/MM/YYYY'),
        egress_date: moment(x.egress_date).format('DD/MM/YYYY')
      };
    });
    tableInfo.onChange(students, limit, page, data.count);
  };

  const onChangePage = async (limit, page) => {
    getStudents(limit, page, termFilter, yearFilter);
  };
  const onChangeYear = async (year) => {
    await getStudents(paginationProps.limit, 0, termFilter, year);
    setYearFilter(year);
  };

  const onChangeTerm = async (term) => {
    await getStudents(paginationProps.limit, 0, term, yearFilter);
    setTermFilter(term);
  };

  const unsubscribe = async (analytic) => {
    await patchUnsubscribeAnalytic(analytic);
  };

  const reloadStudents = async () => {
    getStudents(paginationProps.limit, paginationProps.page, termFilter, yearFilter);
  };

  const handleCancelAnalytic = async (request) => {
    await cancelAnalytic(request);
  };

  return (
    <HistoricalSearchContext.Provider
      value={{
        students: tableInfo.data || [],
        paginationProps: tableInfo.paginationProps,
        onChangePage,
        onChangeYear,
        onChangeTerm,
        unsubscribe,
        reloadStudents,
        handleCancelAnalytic
      }}>
      {children}
    </HistoricalSearchContext.Provider>
  );
};
