import { FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useContext, useState } from 'react';
import { HistoricalSearchContext } from '../../../context/historical/search/HistoricalSearchContext';
import { HistoricalEstablishmentContext } from '../../../context/historical/establishment/HistoricalEstablishmentContext';

const currentYear = new Date().getFullYear();

export const HistoricalSearchFilters = ({establishmentVersion}) => {
  const { onChangeYear, onChangeTerm } = useContext(establishmentVersion ? HistoricalEstablishmentContext : HistoricalSearchContext);
  const [yearFilter, setYearFilter] = useState('');
  const [termFilter, setTermFilter] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const onChangeYearFilter = (e) => {
    e.preventDefault();
    if (!yearValidationState.isValid) {
      enqueueSnackbar('Por favor ingrese un año válido', { variant: 'warning' });
    } else {
      onChangeYear(yearFilter);
    }
  };

  const onChangeTermFilter = (e) => {
    e.preventDefault();
    onChangeTerm(termFilter);
  };

  const yearValidationState = useMemo(() => {
    let result = { isValid: true, errorMessage: '' };
    if (yearFilter && parseInt(yearFilter) < 1900 || parseInt(yearFilter) > parseInt(currentYear)) {
      result = { isValid: false, errorMessage: `El año debe ser un valor entre 1900 y ${currentYear}` };
    }
    return result;
  }, [yearFilter]);

  return (
    <>
      <br />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={4}>
          <form onSubmit={onChangeYearFilter}>
            <FormControl sx={{ width: '70%' }}>
              <TextField
                type="number"
                label="Año"
                fullWidth
                sx={{ width: '70%', backgroundColor: '#F3F6F9', border: 'none' }}
                value={yearFilter}
                error={!yearValidationState.isValid}
                onChange={(e) => {
                  setYearFilter(e.target.value);
                }}
              />
              {!yearValidationState.isValid && (
                <FormHelperText sx={{ ml: 0, width: '100%' }} error>
                  {yearValidationState.errorMessage}
                </FormHelperText>
              )}
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={5}>
          <form onSubmit={onChangeTermFilter}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                autoComplete="off"
                id="outlined-required"
                label={establishmentVersion ? "Establecimiento":"Buscar alumno/a o documento"}
                fullWidth
                sx={{ backgroundColor: '#F3F6F9', border: 'none' }}
                value={termFilter}
                onChange={(e) => {
                  setTermFilter(e.target.value);
                }}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
      <br />
    </>
  );
};
