import { HistoricalSearchState } from '../../../context/historical/search/HistoricalSearchState';
import { HistoricalSearchFilters } from './HistoricalSearchFilters';
import { HistoricalSearchHeader } from './HistoricalSearchHeader';
import { HistoricalSearchTable } from './HistoricalSearchTable';
import { Grid } from '@mui/material';
import { useState } from 'react';

export const HistoricalSearchPage = () => {
  const [showAlert, setShowAlert] = useState(false);
  return (
    <HistoricalSearchState>
      <Grid sx={{ mt: 4, mb: 4 }}>
        <HistoricalSearchHeader showAlert={showAlert} setShowAlert={setShowAlert} />
        <HistoricalSearchFilters />
        <HistoricalSearchTable setShowAlert={setShowAlert} />
      </Grid>
    </HistoricalSearchState>
  );
};
