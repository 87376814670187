import { useParams } from 'react-router-dom';
import { FormPageHeader } from '../../../../components/FormPageHeader';
import { EditRolState } from '../../../../context/admin/editRol/EditRolState';
import { EditRolForm } from './EditRolForm';
import { AdminRouteConstants } from '../../../../routes/RoutesConstants';
import Container from '@mui/material/Container';

export const EditRolPage = () => {
  const { id } = useParams();

  return (
    <EditRolState>
      <Container sx={{ mt: 4 }}>
        <FormPageHeader
          titleEdition={'Editar Rol'}
          titleCreation={'Crear Rol'}
          isEdition={id}
          baseBreadCrumb="Roles"
          baseLink={AdminRouteConstants.ROL_BUSCAR}
          actualBreadCrumb={id !== undefined ? 'Editar Rol' : 'Crear Rol'}
        />
        <br></br>
        <EditRolForm />
      </Container>
    </EditRolState>
  );
};
