import { Grid } from '@mui/material';

import {HistoricalEstablishmentHead} from './HistoricalEstablishmentHead'
import { HistoricalEstablishmentState } from '../../../context/historical/establishment/HistoricalEstablishmentState';
import { HistoricalEstablishmentTable } from './HistoricalEstablishmentTable';
import { HistoricalSearchFilters } from '../search/HistoricalSearchFilters';

export const HistoricalEstablishmentPage = () => {
  return (
    <HistoricalEstablishmentState>
      <Grid sx={{ mt: 4, mb: 4 }}>
        <HistoricalEstablishmentHead />
        <HistoricalSearchFilters establishmentVersion />
        <HistoricalEstablishmentTable />        
      </Grid>
    </HistoricalEstablishmentState>
  );
};
