import PropTypes from 'prop-types';
import { MyButton } from '../styles/Styles';

export const GreyButton = ({ text, action, disabled, mr = 2, ml = 2, mt = 0 }) => {
  return (
    <MyButton
      variant="contained"
      onClick={action}
      // color="secondary"
      disabled={disabled}
      sx={{ marginRight: mr, marginLeft: ml, marginTop: mt }}>
      {text}
    </MyButton>
  );
};

GreyButton.prototype = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func,
  disabled: PropTypes.bool
};

GreyButton.defultProps = {
  disabled: false
};
