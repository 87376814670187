import { AnalyticalsState } from '../../../context/Analytical/Analyticals/AnalyticalState';
import { AnalyticalFilters } from './AnalyticalFilters';
import { AnalyticalHead } from './AnalyticalHead';
import { AnalyticalTable } from './AnalyticalTable';
import { Grid } from '@mui/material';

export default function AnalyticalPage() {
  return (
    <>
      <AnalyticalsState>
        <Grid sx={{ mt: 4, mb: 4 }}>
          <AnalyticalHead />
          <AnalyticalFilters />
          <AnalyticalTable />
        </Grid>
      </AnalyticalsState>
    </>
  );
}
