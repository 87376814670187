import { CustomModal } from '../../../components/CustomModal';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, Box, Typography, Divider } from '@mui/material';
import { useContext } from 'react';
import { AnalyticalStepTwoContext } from '../../../context/Analytical/AnalyticalContext';
import { useState } from 'react';
import { GreyButton } from '../../../components/GreyButton';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { useEffect } from 'react';

export const AnalyticalModalPartialCertificate = ({ openModal, handleCloseModal, handleCloseModalPartialExitWithouSave }) => {
  const { establishments, getEstablishmentNamesData, setEstablishmentAutocompleteSelected } = useContext(AnalyticalStepTwoContext);
  const [jurisdiction, setJurisdiction] = useState('');
  const [establishment, setEstablishment] = useState();

  const handleChange = (event) => {
    setJurisdiction(event.target.value);
  };
  const handleChangeAutocomplete = (event, value) => {
    setEstablishment(value);
  };
  const handleChangeSave = () => {
    setEstablishmentAutocompleteSelected(establishment);
    setJurisdiction('');
    setEstablishment('');
    handleCloseModal();
  };

  useEffect(() => {
    getEstablishmentNamesData();
  }, []);

  const handleDisabled = () => {
    if (jurisdiction === '') {
      return true;
    } else {
      if (jurisdiction) {
        if (establishment) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  const handleCloseModalPartialExitWithoutSave = () => {
    setJurisdiction('');
    setEstablishment('');
    handleCloseModalPartialExitWithouSave();
  };

  return (
    <>
      <CustomModal open={openModal}>
        <Typography variant="body1">Establecimiento destino</Typography>
          <Divider />
          <br />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="demo-simple-select-label">Jurisdicción</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={jurisdiction}
            label="Jurisdicción"
            onChange={handleChange}>
            <MenuItem value={true}>CABA</MenuItem>
            <MenuItem value={false}>Fuera de CABA</MenuItem>
          </Select>
        </FormControl>

        {establishments && jurisdiction && (
          <>
            <Autocomplete
              fullWidth
              // {...defaultProps}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.establishment_name}
                  </li>
                );
              }}
              options={establishments}
              id="combo-box"
              disablePortal
              loading={true}
              disabled={!jurisdiction}
              onChange={handleChangeAutocomplete}
              getOptionLabel={(option) => option.establishment_name || ''}
              renderInput={(params) => <TextField {...params} label="Establecimientos" />}
            />
          </>
        )}

        <br></br>
        <Box sx={{ float: 'right' }}>
          <GreyButton action={() => handleCloseModalPartialExitWithoutSave()} text={'Cerrar'} />
          <CustomYellowButton action={handleChangeSave} disabled={handleDisabled()} title={'Guardar'} />
        </Box>
      </CustomModal>
    </>
  );
};
