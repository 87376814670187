import Container from '@mui/material/Container';
import { useContext, useEffect, useState } from 'react';
import {
  deleteAnalytic,
  getAllAnalyticStatus,
  getAnalyticByFilter,
  getStatusHistory,
  getStudentData,
  patchSignAnalytic
} from '../../../services/AnalyticalServices';
import { AnalyticalContext } from '../AnalyticalContext';
import { AppContext } from '../../global/AppContext';
import { useParams } from 'react-router-dom';
import { useTable } from '../../../hooks/useTable';
import Toast from '../../../components/Toast';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { ADMINISTRADOR, AUTORIDAD_DE_LEGALIZACION, SUPER_ADMIN, VALIDADOR } from '../../../constants/Roles';

export const AnalyticalsState = ({ children }) => {
  const { user } = useContext(AppContext);
  const tableInfo = useTable(true);
  const [allAnalyticalStatus, setAllAnalyticalStatus] = useState([]);
  const { paginationProps } = tableInfo;
  const statusHistoryTableInfo = useTable(true);
  const { paginationProps: statusPagination } = statusHistoryTableInfo;
  const { isUserAdminOrLegalization: isAdminOrLegalization } = useAuthHelper();
  //
  const [analyticalState, setAnalyticalState] = useState('');
  const [student, setStudent] = useState('');
  const [year, setYear] = useState('');
  const [yearSchool, setYearSchool] = useState('');
  const [analyticId, setAnalyticId] = useState('');

  const [filter, setFilter] = useState('');

  useEffect(() => {
    getAllAnalyticalStatusData();
    getAnalyticByFilterData();
  }, []);

  const params = useParams();

  const getAnalyticByFilterData = async (
    page = paginationProps.page,
    limit = paginationProps.default_limit,
    filter,
    analyticalStatus,
    year,
    yearSchool
  ) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const isValidador = (userData && userData.roles && userData.roles.length > 0) ? (userData.roles[0] == VALIDADOR) : false;
    const isUserAdminOrLegalizacion = isAdminOrLegalization();
    const establishmentId = isUserAdminOrLegalizacion || isValidador
      ? localStorage.getItem('historicalEstablishmentId') || localStorage.getItem('analyticalEstablishmentId')
      : params.id || user.establishmentId;
    setAnalyticalState(analyticalStatus ?? '');
    setFilter(filter ?? '');
    setYear(year ?? '');
    setYearSchool(yearSchool ?? '');
    localStorage.setItem('searchStatusId', analyticalStatus ?? '');
    localStorage.setItem('searchTerm', filter ?? '');
    localStorage.setItem('searchYear', year ?? '');
    localStorage.setItem('searchYearSchool', yearSchool ?? '');

    let response = await getAnalyticByFilter(page, limit, filter, establishmentId, analyticalStatus, year, yearSchool);
    let mapData = response?.data.map((item) => {
      item.full_name = `${item.last_name.toUpperCase()} ${item.name.toUpperCase()}`;
      item.document = item.document_number;
      return item;
    });
    tableInfo.onChange(mapData, limit, page, response.count);
  };

  const getAllAnalyticalStatusData = async () => {
    const isUserLegalizationOrValidator = user.roles.includes(AUTORIDAD_DE_LEGALIZACION) || user.roles.includes(VALIDADOR);
    const isUserAdmin = user.roles.includes(ADMINISTRADOR) || user.roles.includes(SUPER_ADMIN);
    let data = await getAllAnalyticStatus(user);
    data.unshift({ id: 0, name: 'Todos los estados', value: '' });
    const addedValueProp = data.map((option) => ({ ...option, value: option.id }));
    const filteredAnalyticalStatus = addedValueProp.filter((status) => {
      const { id } = status;
      return isUserLegalizationOrValidator ? id == 0 || (id >= 4 && id <= 6) || id == 9 : id <= 7;
    });
    setAllAnalyticalStatus(isUserAdmin ? addedValueProp : filteredAnalyticalStatus);
  };

  const getStudentDataForStepOne = async (studentId) => {
    const studentData = await getStudentData(studentId);
    return studentData;
  };

  const handlePageChange = (limit, page) => {
    getAnalyticByFilterData(page, limit, filter, analyticalState, year, yearSchool);
  };

  const patchSignAnalyticData = async (id) => {
    if (id) {
      let res = await patchSignAnalytic(id);
      if (res) {
        getAnalyticByFilterData(paginationProps.page, paginationProps.limit, filter, analyticalState, year);
      }
    }
  };

  //
  const handleChangeSelect = (event) => {
    getAnalyticByFilterData(0, paginationProps.default_limit, student, event.target.value, year, yearSchool);
  };

  const handleInputStudent = (event) => setStudent(event.target.value);

  const handleSearchStudent = (event) => {
    event.preventDefault();
    getAnalyticByFilterData(0, paginationProps.default_limit, student, analyticalState, year, yearSchool);
  };

  const handleInputYear = (event) => setYear(event.target.value);

  const handleInputYearSchool = (event) => setYearSchool(event.target.value);

  const handleSearchAllParams = (event) => {
    event.preventDefault();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (yearSchool == '' || (yearSchool >= 1900 && yearSchool <= currentYear)) {
      getAnalyticByFilterData(0, paginationProps.default_limit, student, analyticalState, year, yearSchool);
    } else {
      Toast.error(`El Ciclo Lectivo deberia estar en el rango entre 1900 y ${currentYear}`);
    }
  };

  const deleteAnalyticData = async (id) => {
    let res = await deleteAnalytic(id);
    if (res) {
      getAnalyticByFilterData(paginationProps.page, paginationProps.limit, filter, analyticalState, year);
    }
  };

  const handleStatusHistoryPageChange = (limit, page) => {
    getStatusHistoryByAnalytic(analyticId, page, limit);
  };

  const getStatusHistoryByAnalytic = async (id, page = statusPagination.page, limit = statusPagination.default_limit) => {
    setAnalyticId(id);
    const res = await getStatusHistory(id, page, limit);
    statusHistoryTableInfo.onChange(res.data, limit, page, res.count);
  };

  return (
    <>
      <AnalyticalContext.Provider
        value={{
          allAnalyticalStatus,
          count: paginationProps.count,
          page: paginationProps.page,
          limit: paginationProps.limit,
          analytics: tableInfo.data,
          statusCount: statusPagination.count,
          statusPage: statusPagination.page,
          statusLimit: statusPagination.limit,
          statusHistory: statusHistoryTableInfo.data,
          handleStatusHistoryPageChange,
          getAnalyticByFilterData,
          handlePageChange,
          patchSignAnalyticData,
          handleChangeSelect,
          handleInputStudent,
          handleInputYear,
          handleInputYearSchool,
          handleSearchAllParams,
          handleSearchStudent,
          analyticalState,
          student,
          year,
          yearSchool,
          deleteAnalyticData,
          getStudentData: getStudentDataForStepOne,
          getStatusHistoryByAnalytic
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </AnalyticalContext.Provider>
    </>
  );
};
