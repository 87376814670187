import { Alert, Box, Grid, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomStepper } from '../../../components/CustomStepper';
import { CustomSteps } from '../../../components/CustomSteps';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GenericForm } from '../../../components/GenericForm';
import { GreyButton } from '../../../components/GreyButton';
import { Modal } from '../../../components/Modal';
import analyticalStepOneConfig from '../../../configs/analyticalStepOneConfig.json';
import { stepsArray } from '../../../constants/StepsArray';
import { AnalyticalStepOneContext } from '../../../context/Analytical/AnalyticalContext';
import { AppContext } from '../../../context/global/AppContext';
import { useGenericForm } from '../../../hooks/useGenericForm';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { COMPLETO, EN_PROCESO, PENDIENTE_DE_FIRMA } from '../../../constants/AnalyticalStates';

const { formControls } = analyticalStepOneConfig;

export const createAlert = (condition, message, key) => {
  return (
    condition && (
      <Grid item key={key}>
        <Alert severity="warning">{message}</Alert>
      </Grid>
    )
  );
};

export const AnalyticalStepOneForm = () => {
  const {
    options,
    stepOneInfo,
    patchUpdateAnalyticalData,
    postNewAnalyticalData,
    postAdditionalAnalyticalData,
    analyticId,
    studentStatus,
    setStudentStatus,
    getStatus,
    studentIsInOtherEstablishment,
    existsInHistoryModule,
    isDataFromRenaper
  } = useContext(AnalyticalStepOneContext);

  const {
    form,
    initForm,
    handleValue,
    validateFormAndExecutePostAction,
    setConfig,
    config,
    hasModifications,
    emptyRequiredFields,
    hasEmptyRequiredFields
  } = useGenericForm(formControls);
  const params = useParams();
  const { user } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const { isUserAdminOrLegalization } = useAuthHelper();
  const establishmentId = localStorage.getItem('analyticalEstablishmentId');
  const establishmentName = localStorage.getItem('analyticalEstablishmentName');
  const analiticalEstablishmentIsTerciary = JSON.parse(localStorage.getItem('analyticalEstablishmentIsTerciary'));
  const analyticalEstablishmentIsPublic = localStorage.getItem('analyticalEstablishmentIsPublic');

  const baseLink = isUserAdminOrLegalization()
    ? AnalyticRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
    : AnalyticRouteConstants.BUSCAR;
  const [isFormDisabledAndFilled, setIsFormDisabledAndFilled] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    localStorage.setItem('limitYear', currentYear);
  }, []);

  useEffect(() => {
    // En este efecto es donde carga el formulario si se ha hecho fetch de data para rellenar
    if (stepOneInfo) {
      const noEditStep1 = stepOneInfo.noEditStep1; //noEditStep1= true significa que no se puede editar el paso 1 porque existe al menos un analitico en estado(4, 6, 9, 12)
      const editStep1Override4Or5 = stepOneInfo?.override?.editStep1; //editStep1Override4Or5= false significa que no se puede editar porque anteriormente este analitico fue anulado por "extravio/hurto" o "Ilegible/Destrucción".
      params.blockExistsInAnalyticModule = stepOneInfo?.blockExistsInAnalyticModule;
      params.statusCarrera = stepOneInfo?.statusCarrera;
      initForm(stepOneInfo);
      const editableStates = [EN_PROCESO, COMPLETO, PENDIENTE_DE_FIRMA];
      const sessionRequiredFields = localStorage.getItem('requiredFields') ? localStorage.getItem('requiredFields').split(',') : [];
      const editStep1 =
        stepOneInfo?.override?.editStep1 && (stepOneInfo?.analytic_status_id === 7 || stepOneInfo?.analytic_status_id === 5);
      const schoolYearAux = stepOneInfo.schoolYear ? stepOneInfo?.schoolYear : stepOneInfo?.schoolYearAux;
      const requiredInvalidFields = emptyRequiredFields(stepOneInfo);
      const isCancelledAndNotEditable = !stepOneInfo.override?.editStep1 && stepOneInfo.analytic_status_id === 7;
      const isCancelledAndEditable = stepOneInfo.override?.editStep1 && stepOneInfo.analytic_status_id === 7;
      const isForAdditionalCareer = stepOneInfo.new_carreer_editable;
      const is_from_api = stepOneInfo.is_from_api;
      const isPreviousSchoolYear = schoolYearAux < stepOneInfo.schoolYearSystem && stepOneInfo.is_from_api == true;
      const isAllAnaliticsStatusLesSix = !stepOneInfo?.analytics_less_state_six;
      const isEditableState = editableStates.includes(stepOneInfo.analytic_status_id);
      if (
        (((!is_from_api && isAllAnaliticsStatusLesSix) ||
          (!is_from_api && (isCancelledAndEditable || isPreviousSchoolYear) && isAllAnaliticsStatusLesSix) ||
          (!is_from_api && editStep1 && !existsInHistoryModule) ||
          (is_from_api && isPreviousSchoolYear)) &&
          editStep1Override4Or5 &&
          !noEditStep1) ||
        isDataFromRenaper
      ) {
        setConfig((prevConfig) => prevConfig.map((x) => ({ ...x, disabled: false })));
      } else if (
        (!is_from_api && (isCancelledAndNotEditable || !isAllAnaliticsStatusLesSix)) ||
        (is_from_api && !isEditableState && isCancelledAndNotEditable) ||
        !editStep1Override4Or5 ||
        noEditStep1
      ) {
        setConfig((prevConfig) => prevConfig.map((x) => ({ ...x, disabled: true })));
      } else if ((is_from_api && editStep1) || (is_from_api && isEditableState && !noEditStep1 && editStep1Override4Or5)) {
        const states = ['birthplace', 'gender_id', 'birthcountry', 'birthprovince'];
        setConfig((prevConfig) => prevConfig.map((x) => ({ ...x, disabled: !states.includes(x.field) })));
      } else if (!isForAdditionalCareer && !is_from_api) {
        setConfig((prevConfig) =>
          prevConfig.map((x) => ({ ...x, disabled: x.field === 'schoolYear' || x.field === 'years' ? false : true }))
        );
      }

      if (requiredInvalidFields.length > 0 || (is_from_api && sessionRequiredFields.length > 0)) {
        setConfig((prevConfig) =>
          prevConfig.map((x) => {
            return {
              ...x,
              disabled: requiredInvalidFields.includes(x.field) ? false : x.disabled
            };
          })
        );
      }
    }
    if (studentStatus) {
      // Para los casos en que se quiere crear un analítico en un establecimiento SECUNDARIO y la API
      // devuelve que existe en Histórico o Analítico, siempre debe deshabilitarse
      setConfig((prevConfig) =>
        prevConfig.map((x) => {
          return {
            ...x,
            required: x.required,
            disabled: true
          };
        })
      );
      // Se agrega esta lógica en un IF separado porque el IF principal toma en cuenta muchas variables
      // que para este caso que se describe no son necesarias y generan conflictos innecesarios.
    }
  }, [stepOneInfo, existsInHistoryModule]);

  useEffect(() => {
    isDisabledAndFilled();
  }, [config, form, stepOneInfo]);

  useEffect(() => {
    if (!!form.document_type_id && !!form.document_number) {
      setStudentStatus(null);
      getStatus(form.document_type_id, form.document_number, form.gender_id, params.id);
    }
  }, [form.document_type_id, form.document_number, form.gender_id]);

  const navigate = useNavigate();
  const isStablishmentTerciary = JSON.parse(
    analiticalEstablishmentIsTerciary ?? user.establishments.filter((x) => x.id === user.establishmentId)[0]?.is_terciary
  );
  const isStablishmentIsPublic = JSON.parse(
    analyticalEstablishmentIsPublic ?? user.establishments.filter((x) => x.id === user.establishmentId)[0].is_public
  );

  const saveHandleClick = (form) => {
    if (!isStablishmentTerciary && isStablishmentIsPublic && !params.id) {
      setOpen(true);
    } else {
      if (analyticId) {
        patchUpdateAnalyticalData(form);
      } else if (
        (studentIsInOtherEstablishment && isStablishmentTerciary) ||
        (stepOneInfo && stepOneInfo.is_additional_analytic) ||
        params.statusCarrera
      ) {
        postAdditionalAnalyticalData(form, hasModifications);
      } else {
        postNewAnalyticalData(form);
      }
    }
  };

  const nextHandleClick = async (form) => {
    if (!isStablishmentTerciary && isStablishmentIsPublic && !params.id) {
      setOpen(true);
    } else {
      await saveFormAndRedirect(form);
    }
  };

  const saveFormAndRedirect = async (form) => {
    if (analyticId) {
      await patchUpdateAnalyticalData(form);
      redirectNext(analyticId);
    } else if (studentIsInOtherEstablishment || (stepOneInfo && stepOneInfo.is_additional_analytic) || params.statusCarrera) {
      postAdditionalAnalyticalData(form, hasModifications).then((id) => {
        redirectNext(id);
      });
    } else {
      postNewAnalyticalData(form).then((id) => {
        redirectNext(id);
      });
    }
  };

  const redirectNext = (id) => {
    navigate(AnalyticRouteConstants.PASO2(id));
  };

  const confirmModalBody = (
    <>
      <br />
      <Typography>
        Recuerde que sólo corresponde agregar alumnos/as que hayan concluido la cursada en ciclos lectivos anteriores al 2022.
      </Typography>
      <br />
      <Typography>En caso contrario debe dar de alta el/la alumno/a por la aplicación mi escuela.</Typography>
      <br />
      <Typography>¿Está seguro que quiere continuar?</Typography>
    </>
  );
  const confirmModalActions = (
    <>
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <GreyButton text="Cancelar" action={() => setOpen(false)} />
        <CustomYellowButton
          title="Confirmar"
          action={() => {
            validateFormAndExecutePostAction(saveFormAndRedirect);
          }}
        />
      </Grid>
    </>
  );

  const isDisabledAndFilled = () => {
    const hasRequiredFieldsEmpty = hasEmptyRequiredFields > 0;
    setIsFormDisabledAndFilled(hasRequiredFieldsEmpty);
  };

  return (
    <>
      <CustomSteps activeStep={1} stepsArray={stepsArray} />
      <CustomStepper steps={3} activeStep={1} />
      <br />
      <Paper elevation={3}>
        <Box sx={{ p: 3, pb: 4 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" color="initial">
                Información de el/la alumno/a
              </Typography>
            </Grid>
            {studentStatus && createAlert(studentStatus, studentStatus)}
          </Grid>
          <br />
          <GenericForm options={options} form={form} formControls={config} handleValue={handleValue}></GenericForm>
        </Box>
      </Paper>
      <br />
      <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
        <Box>
          <GreyButton text="Cancelar" action={() => navigate(baseLink)} />
        </Box>
        <Box sx={{ mr: 1 }}>
          <GreyButton
            text="Guardar"
            disabled={
              studentStatus ||
              !hasModifications ||
              (studentStatus && studentStatus.includes('Nueva Carrera')) ||
              (studentStatus && (analiticalEstablishmentIsTerciary == null ? false : !analiticalEstablishmentIsTerciary))
            }
            action={() => validateFormAndExecutePostAction(saveHandleClick)}
          />
        </Box>
        <CustomYellowButton
          disabled={
            isFormDisabledAndFilled ||
            (params.blockExistsInAnalyticModule && !params.statusCarrera) ||
            (studentStatus && localStorage.getItem('action') == 'edit') ||
            (studentStatus && studentStatus.includes('Nueva Carrera')) ||
            (studentStatus && (analiticalEstablishmentIsTerciary == null ? false : !analiticalEstablishmentIsTerciary))
          }
          title="Continuar"
          //action={() => validateFormAndExecutePostAction(nextHandleClick, true)}
          action={() =>
            validateFormAndExecutePostAction(!hasModifications && params?.id ? () => redirectNext(params.id) : nextHandleClick, true)
          }
        />
      </Box>
      {open && <Modal title="¡Atención!" body={confirmModalBody} actions={confirmModalActions} />}
    </>
  );
};
