export const EN_PROCESO = 1;
export const COMPLETO = 2;
export const PENDIENTE_DE_FIRMA = 3;
export const PENDIENTE_DE_VALIDACION = 4;
export const OBSERVADO = 5;
export const VALIDADO = 6;
export const ANULADO = 7;
export const PENDIENTE_DE_ENVIO = 8;
export const PENDIENTE_DE_FIRMA_DIGITAL = 9;
export const PENDIENTE_DE_IMPRESION = 10;
export const IMPRESO = 11;
export const LEGALIZADO = 12;
export const VALIDAR_IMPRESION = 13;
export const NOTIFICADO = 14;
export const ENVIADO = 15;
export const DADO_DE_BAJA = 16;

export const ANALYTICAL_STATUS = {
  0: {stateNumber: 0, state: 'Sin analíticos'},
  1: { stateNumber: 1, state: 'En proceso' },
  2: { stateNumber: 2, state: 'Completo' },
  3: { stateNumber: 3, state: 'Pendiente de firma' },
  4: { stateNumber: 4, state: 'Pendiente de validación' },
  5: { stateNumber: 5, state: 'Observado' },
  6: { stateNumber: 6, state: 'Validado' },
  7: { stateNumber: 7, state: 'Anulado' },
  8: { stateNumber: 8, state: 'Pendiente de envío' },
  9: { stateNumber: 9, state: 'Pendiente de firma digital' },
  // 10: { stateNumber: 10, state: 'Pendiente de impresión' },
  // 11: { stateNumber: 11, state: 'Impreso' },
  12: { stateNumber: 12, state: 'Legalizado' },
  // 13: { stateNumber: 13, state: 'Validar impresión' },
  14: { stateNumber: 14, state: 'Notificado' },
  15: { stateNumber: 15, state: 'Enviado' },
  16: { stateNumber: 16, state: 'Dado de baja' }
};

export const NACION_ANALYTICAL_STATUS = {
  PENDIENTE_VALIDACION: ANALYTICAL_STATUS[4].state,
  PENDIENTE_FIRMA: ANALYTICAL_STATUS[3].state,
  VALIDADO: ANALYTICAL_STATUS[6].state,
  FALLA_RENAFEJU: 'Falla RENAFEJU',
  FALLA_FIRMA_INCONSISTENTE: 'Firma inconsistence',
  FALLA_CERTIFICADO: 'Falla certificado',
  FALLA_TITULOS_DUPLICADOS: 'Títulos duplicados',
  FALLA_PROVINCIA: 'Falla provincia',
  ERROR: 'Error',
  ANULADO: ANALYTICAL_STATUS[7].state,
  RECHAZADO: 'Rechazado',
  VALIDADO_BLOCKCHAIN: 'Validado blockchain',
  ESTABLECIMIENTO_INVALIDO: 'Establecimiento inválido',
}