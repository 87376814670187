import {
  ANULADO,
  COMPLETO,
  EN_PROCESO,
  OBSERVADO,
  PENDIENTE_DE_FIRMA,
  PENDIENTE_DE_VALIDACION,
  VALIDADO
} from '../constants/AnalyticalStates';

export const steps = [
  {
    label: 'En proceso',
    condition: (state) => {
      return state === EN_PROCESO || state === ANULADO;
    },
    description: `Posee datos personales y datos parciales de las calificaciones.`
  },
  {
    label: 'Completo',
    condition: (state) => {
      return state === COMPLETO;
    },
    description: 'Ya se encuentra disponible para firma.'
  },
  {
    label: 'Pendiente de firma',
    condition: (state) => {
      return state === PENDIENTE_DE_FIRMA;
    },
    description: `Ya se encuentra disponible para firma.`
  },
  {
    condition: (state) => {
      return state === PENDIENTE_DE_VALIDACION || state === OBSERVADO;
    },
    label: 'Pendiente de validación'
  },
  {
    condition: (state) => {
      return state >= VALIDADO && state !== ANULADO;
    },
    label: 'Validado'
  }
];
