import { Paper, Typography, Box } from '@mui/material';
import { Canceled } from '../styles/Styles';

export const PreviousDataComponent = ({ name, docType, document, studyPlan, amendingResolution }) => {
  return (
    <Paper elevation={3} sx={{ boxShadow: 'none', borderRadius: '8px' }}>
      <Box sx={{ padding: '20px 35px' }}>
        <Typography variant="h5" color="initial" sx={{ fontWeight: 'bold' }}>
          {name}
        </Typography>
        <br />
        <Box sx={{ display: 'flex' }}>
          {document && (
            <Box sx={{ mr: 4 }}>
              <Canceled variant="body1">Documento</Canceled>
              <Typography variant="h6">
                {docType} {document}
              </Typography>
            </Box>
          )}

          {studyPlan && (
            <Box>
              <Canceled variant="body1">Plan de Estudio</Canceled>
              <Typography variant="h6">{studyPlan}{amendingResolution ? ` (${amendingResolution})` : amendingResolution}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
