import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import { useContext, useMemo } from 'react';
import { TableAction } from '../../components/TableAction';
import { TableGenericComponent } from '../../components/TableGenericComponents';
import { AppContext } from '../../context/global/AppContext';
import { NotificationContext } from '../../context/notification/notificationContext';

const headers = [{ label: 'Notificación', field: 'description' }];

export const NotificationTable = () => {
  const { notifications, setViewed } = useContext(NotificationContext);
  const { hasPendingNotifications } = useContext(AppContext);

  const handleDoneIcon = async (e) => {
    await setViewed(e.id);
    hasPendingNotifications();
  };

  const data = useMemo(() => {
    return notifications.map((x) => {
      x.actions = [];
      if (!x.viewed) {
        const html = {
          html: (
            <Box sx={{ float: 'center' }}>
              <TableAction
                key={'seleccionar' + x.id}
                tooltipTitle={'Notificar lectura'}
                onClick={() => handleDoneIcon(x)}
                iconElement={<DoneIcon color="primary" />}
              />
            </Box>
          )
        };
        x.actions.push(html);
      }
      return x;
    });
  }, [notifications]);

  return (
    <TableGenericComponent
      displayPagination={false}
      bar={false}
      columns={headers}
      rows={data}
      hasActions={true}
      message={'No posee notificaciones pendientes para este establecimiento'}
    />
  );
};
