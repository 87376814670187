import { Avatar, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import GoogleIcon from '../assets/images/Google.svg';
import '../styles/styles.css';

const LButton = ({ action }) => {
  return (
    <Stack direction="row" alignItems="center" mt={2} spacing={2}>
      <Button
        variant="contained"
        onClick={action}
        sx={{
          backgroundColor: '#FFF',
          color: '#505E70',
          textTransform: 'none',
          fontSize: '16px',
          alignItems: 'center',
          p: '15px',
          '&:hover': { backgroundColor: '#FFF' }
        }}>
        <Avatar alt={'Logo de google'} src={GoogleIcon} sx={{ width: '28px', height: '28px', mr: '10px' }} />
        Ingresá con tu cuenta <b> @bue.edu.ar</b>
      </Button>
    </Stack>
  );
};

export default LButton;
