import { Grid, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { EstablishmentContext } from '../../../context/establishment/EstablishmentContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const EstablishmentHead = () => {
  const [filter, setFilter] = useState('');
  const { isSuperAdmin } = useAuthHelper();
  const { updateInfoSuperAdmin, updateInfoEstablishment } = useContext(EstablishmentContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    isSuperAdmin() ? updateInfoSuperAdmin(null, 0, filter) : updateInfoEstablishment(null, 0, filter);
  };

  return (
    <>
      <CustomBreadcrumbs base={'Inicio'} now={'Seleccionar establecimiento'} />
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h3" color="initial">
            Seleccionar Establecimientos
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item xs={6}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField
              autoComplete="off"
              id="outlined-required"
              label="Buscar establecimiento"
              fullWidth
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </form>
        </Grid>
      </Grid>
    </>
  );
};
