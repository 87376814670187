import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Actions, Roles } from '../constants/Actions';
import { AppContext } from '../context/global/AppContext';
import { EstablishmentRouteConstants } from './RoutesConstants';

export const ProtectedRoute = ({ page, requestedPermissions }) => {
  const { user } = useContext(AppContext);

  const verifyAllowed = () => {
    if (!user) return false;
    if (user.roles && user.roles.includes(Roles.SUPERADMIN)) return true;
    if (!user.actions || (requestedPermissions && !requestedPermissions.some((x) => user.actions.includes(x)))) {
      return false;
    }
    return true;
  };

  return verifyAllowed() === true ? (
    (user.actions.includes(Actions.SELECT_ESTABLISHMENT) || user.roles.includes(Roles.SUPERADMIN)) && !user.establishmentId ? (
      <Navigate to={EstablishmentRouteConstants.BUSCAR} />
    ) : (
      page
    )
  ) : (
    <Navigate to="/" />
  );
};
