import { Typography } from '@mui/material';

export const HistoricalEstablishmentHead = () => {
  
  return (
    <>
      <Typography variant="h4" color="initial" mb={2} sx={{ fontSize: '37px', fontWeight: '700' }}>
        {'Histórico'}
      </Typography>
    </>
  );
};
