import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import './style.css';

export const NavbarUser = ({ selectedUser }) => {
  return (
    <>
      <Box sx={{ flexGrow: 0, marginRight: '5px' }}>
        <IconButton sx={{ p: 0 }}>
          {localStorage.getItem('token') && (
            <Avatar
              sx={{ width: 26, height: 26, bgcolor: '#FFF', color: '#38485C' }}
              alt={selectedUser?.first_name}
              src={selectedUser?.google_picture}>
              <AccountCircleOutlinedIcon />
            </Avatar>
          )}
        </IconButton>
      </Box>
    </>
  );
};
