import { getData, postData } from './Services';

export const getUserService = async () => {
  const res = await getData('/user/me');
  return res;
};

export const getLogOutService = async () => {
  const res = await getData('/auth/logout');
  return res;
};

export const pushPotentialLogoutToken = async () => {
  const res = await postData('/auth/potentialLogout', true);
  return res;
};
