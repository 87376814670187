export const formatDecimal = (floatValue) => (floatValue % 1 != 0 ? floatValue.toFixed(2) : floatValue.toFixed(2).replace(/\.?0+$/, ''));

export const convertNumberQualification = (numeric_qualification, isLiceo = false) => {
  const arrayNumberTexts = [
    'Cero',
    'Uno',
    'Dos',
    'Tres',
    'Cuatro',
    'Cinco',
    'Seis',
    'Siete',
    'Ocho',
    'Nueve',
    'Diez',
    'Once',
    'Doce',
    'Trece',
    'Catorce',
    'Quince',
    'Dieciséis',
    'Diecisiete',
    'Dieciocho',
    'Diecinueve',
    'Veinte'
  ];
  const formatedNumber = formatDecimal(numeric_qualification);
  const numbers = formatedNumber.toString().split('.');
  let numberSTR = formatedNumber.toString().replace('.', ',');
  if (isLiceo) {
    numberSTR += ` (${numbers[0]}`;
    numberSTR += numeric_qualification % 1 != 0 ? ` con ${numbers[1]}/00)` : ')';
  } else if (!isLiceo) {
    numberSTR += ` (${arrayNumberTexts[parseInt(numbers[0])]}`;
    numberSTR += numeric_qualification % 1 != 0 ? ` con ${numbers[1]})` : ')';
  }
  return numberSTR;
};

export const addZeros = (numberString, indexToAdd, limit) => {
  //calculo cantidad de ceros que tengo que agregar a la izsquierda
  const newValue = parseInt(numberString) + indexToAdd;
  const zerosToAdd = limit - newValue.toString().length;
  return '0'.repeat(zerosToAdd) + newValue.toString();
};
