import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
  TextareaAutosize
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useRef, useState } from 'react';

export const GenericFormInput = ({
  label,
  type = 'text',
  valueData = '',
  handle = {},
  list = [],
  required,
  readOnly,
  disabled,
  multiple,
  validationFunction,
  optionsText,
  defaultValue = '',
  max
}) => {
  const [value, setValue] = useState(valueData);
  const touched = useRef(false);
  const [validation, setValidation] = useState({
    errorMessage: '',
    isValid: true
  });
  useEffect(() => {
    setValue(valueData);
  }, [valueData]);

  const onChangeValue = (newValue) => {
    let result;
    if (validationFunction) {
      result = validationFunction(newValue);
      setValidation(result);
    }
    if (touched.current === false) {
      touched.current = true;
    }
    //optimizacion para actualizar solo cuando el valor cambia
    if (valueData !== newValue) {
      setValue(newValue);
      handle(newValue);
    }
  };

  let emptyObject = multiple ? [] : null;

  return (
    <Box sx={{ mr: 2, mb: '30px' }}>
      <Typography variant="h6">{label}</Typography>
      {(type == 'text' || type == 'int') && (
        <FormControl fullWidth>
          <TextField
            sx={{
              backgroundColor: '#F5F9FC',
              '& .Mui-disabled': {
                backgroundColor: '#DCDCDC'
              }
            }}
            autoComplete="off"
            size="small"
            id="outlined-required"
            value={value}
            placeholder={defaultValue}
            type={type === 'int' ? 'number' : ' '}
            disabled={disabled}
            required={required}
            error={touched.current && (!validation.isValid || (required && !value))}
            onBlur={(event) => onChangeValue(event.target.value)}
            onChange={(event) => setValue(event.target.value)}
            inputProps={{
              maxLength: max ? max : 100
            }}
            fullWidth
          />
          {!validation.isValid && (
            <FormHelperText error id="accountId-error">
              {validation.errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {type == 'long_text' && (
        <FormControl fullWidth>
          <TextareaAutosize
            sx={{
              backgroundColor: '#F5F9FC',
              '& .Mui-disabled': {
                backgroundColor: '#DCDCDC'
              }
            }}
            autoComplete="off"
            size="small"
            id="outlined-required"
            value={value}
            placeholder={defaultValue}
            disabled={disabled}
            required={required}
            error={touched.current ? !validation.isValid || (required && !value) : false}
            onBlur={(event) => onChangeValue(event.target.value)}
            onChange={(event) => setValue(event.target.value)}
            minRows={4}
          />
          {!validation.isValid && (
            <FormHelperText error id="accountId-error">
              {validation.errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {type == 'date' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <>
            <DesktopDatePicker
              fullWidth
              sx={{
                backgroundColor: '#F5F9FC',
                '& .Mui-disabled': {
                  backgroundColor: '#DCDCDC'
                }
              }}
              type="datetime-local"
              value={value ? value : null}
              inputFormat="dd/MM/yyyy"
              required={required}
              disabled={disabled}
              // onChange={newValue =>DataRes(newValue)}
              onChange={(newValue) => (newValue > new Date('01/01/1900') ? onChangeValue(newValue) : null)}
              renderInput={(params) => <TextField size="small" {...params} sx={{ width: '100%', backgroundColor: '#F5F9FC' }} />}
              error={validation && !validation.isValid}
            />
            {validation && !validation.isValid && <FormHelperText error>{validation.errorMessage}</FormHelperText>}
          </>
        </LocalizationProvider>
      )}
      {type == 'select' && (
        <FormControl fullWidth sx={{ backgroundColor: '#F5F9FC' }} size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              backgroundColor: '#F5F9FC',
              '& .Mui-disabled': {
                backgroundColor: '#DCDCDC'
              }
            }}
            value={valueData || defaultValue || ''}
            readOnly={readOnly}
            required={required}
            disabled={disabled}
            error={touched.current && required && !value}
            onChange={(event) => onChangeValue(event.target.value)}>
            {list?.map((e) => (
              <MenuItem key={e.id} value={e.id} disabled={e.disabled}>
                {e.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {type == 'switch' && (
        <FormControlLabel
          style={{ margin: 0 }}
          disabled={disabled}
          control={<Switch color="primary" />}
          checked={value ? true : false}
          onChange={() => {
            onChangeValue(!value);
          }}
          labelPlacement="top"
        />
      )}
      {type == 'radio' && (
        <>
          <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" value={value} disabled={disabled}>
            {list?.map((e) => (
              <FormControlLabel
                disabled={disabled}
                key={e.id}
                value={e.id}
                control={
                  <Radio
                    onChange={(e) => {
                      onChangeValue(e.target.value);
                    }}
                  />
                }
                label={e.name}
              />
            ))}
          </RadioGroup>
        </>
      )}
      {type == 'autocomplete' && (
        <FormControl fullWidth>
          <Autocomplete
            fullWidth
            disablePortal
            autoHighlight
            id="combo-box-demo"
            multiple={multiple}
            sx={{
              backgroundColor: '#F5F9FC',
              '& .Mui-disabled': {
                backgroundColor: '#DCDCDC'
              }
            }}
            required={required}
            onChange={(event, value) => onChangeValue(value)}
            value={valueData ? valueData : emptyObject}
            options={list ? list : []}
            getOptionDisabled={(option) => option.disabled}
            renderInput={(params) => <TextField {...params} />}
            getOptionLabel={(x) => (x ? x.name : '')}
            noOptionsText={optionsText ? optionsText : 'No hay opciones que coincidan con la búsqueda'}
            renderOption={(props, x) => {
              return (
                <li {...props} key={x.id ? x.id : 0}>
                  {x.name}
                </li>
              );
            }}
          />
          {!validation.isValid && <FormHelperText error>{validation.errorMessage}</FormHelperText>}
        </FormControl>
      )}
    </Box>
  );
};
