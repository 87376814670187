import { Grid } from '@mui/material';
import { useContext } from 'react';
import { AnalyticalDetailContext } from '../../../context/Analytical/AnalyticalContext';
import { VerticalStepper } from '../../../components/VerticalStepper';

export const StepperDetailAnalytic = () => {
  const { studentSelectedTable } = useContext(AnalyticalDetailContext);
  return (
    <>
      {studentSelectedTable?.step && (
        <Grid item xs={3}>
          <VerticalStepper state={studentSelectedTable?.analytic_status_id} />
        </Grid>
      )}
    </>
  );
};
