// import escuela from './assets/images/escuela.jpg'
import { ThemeProvider, Zoom } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Spinner } from './components/Spinner';
import { SnackbarUtilsConfigurator } from './components/Toast';
import { AppRouter } from './routes/AppRouter';
import { theme } from './styles/Styles';
import './styles/styles.css';

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarProvider TransitionComponent={Zoom} autoHideDuration={3000} maxSnack={2}>
          <SnackbarUtilsConfigurator />
          <Spinner />
          <AppRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
