import { CurriculumState } from '../../../context/Curriculum/CurriculumState';
import { Grid } from '@mui/material';
import { CurriculumHead } from './CurriculumHead';
import { CurriculumFilters } from './CurriculumFilters';
import { CurriculumTable } from './CurriculumTable';

export const CurriculumPage = () => {
  return (
    <CurriculumState>
      <Grid sx={{ mt: 4, mb: 4 }}>
        <CurriculumHead />
        <CurriculumFilters />
        <CurriculumTable />
      </Grid>
    </CurriculumState>
  );
};
