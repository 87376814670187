import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { AnalyticalStepOneState } from '../../../context/Analytical/StepOne/AnalyticalStepOneState';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';

import { AnalyticalStepOneForm } from './AnalyticalStepOneForm';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const AnalyticalStepOnePage = () => {
  let params = useParams();
  const { isUserAdminOrLegalization } = useAuthHelper();
  const establishmentId = localStorage.getItem('analyticalEstablishmentId');
  const establishmentName = localStorage.getItem('analyticalEstablishmentName');
  const baseLink = isUserAdminOrLegalization()
    ? AnalyticRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
    : AnalyticRouteConstants.BUSCAR;

  return (
    <AnalyticalStepOneState>
      <br />
      <Grid sx={{ background: 'linear-gradient(180deg, rgba(230,235,240,1) 30%, rgba(243,246,249,1) 30%)', p: '30px' }}>
        <CustomBreadcrumbs base={'Analítico'} now={'Editar analítico'} baseLink={baseLink} />
        {params?.idstudent ? (
          <Typography variant="h3" color="initial">
            Editar analítico
          </Typography>
        ) : (
          <Typography variant="h3" color="initial">
            Agregar alumno/a
          </Typography>
        )}
        <AnalyticalStepOneForm />
      </Grid>
    </AnalyticalStepOneState>
  );
};
