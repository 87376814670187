import { Grid } from '@mui/material';
import { useContext } from 'react';
import { AnalyticalDetailContext } from '../../../context/Analytical/AnalyticalContext';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { AnalyticalData } from './AnalyticalData';
import { StudentCard } from './StudentCard';
import { AnalyticalOptions } from './AnalyticalOptions';
import { AnalyticalDetailHeader } from './AnalyticalDetailHeader';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { AnalyticRouteConstants, HistoricalRouteConstants } from '../../../routes/RoutesConstants';

export const BodyDetailAnalytic = () => {
  const { studentSelectedTable, infoAnaliticData } = useContext(AnalyticalDetailContext);
  const { isUserAdminOrLegalization } = useAuthHelper();
  // Se determinan el ID y el NAME en función de que se haya navegado desde ANALÍTICOS o desde HISTÓRICOS, respectivamente:
  const establishmentId = studentSelectedTable?.establishment?.id || localStorage.getItem('historicalEstablishmentId');
  const establishmentName = studentSelectedTable?.establishment?.establishment_name || localStorage.getItem('historicalEstablishmentName');
  let baseLink;
  if(localStorage?.getItem('historicalEstablishmentId')){
    baseLink = isUserAdminOrLegalization()
    ? HistoricalRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
    : HistoricalRouteConstants.BUSCAR;
  }else{
    baseLink = isUserAdminOrLegalization()
    ? AnalyticRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
    : AnalyticRouteConstants.BUSCAR;
  }

  return (
    <Grid item xs={9}>
      <CustomBreadcrumbs
        base={studentSelectedTable?.establishment?.establishment_name}
        now="Detalle del analítico"
        baseLink={baseLink}
      />
      <AnalyticalDetailHeader student={studentSelectedTable} />
      <StudentCard student={studentSelectedTable} />
      <AnalyticalOptions />
      {infoAnaliticData && <AnalyticalData student={infoAnaliticData} />}
    </Grid>
  );
};
