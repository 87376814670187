import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
  // TextField
} from '@mui/material';
import { Box } from '@mui/system';
// import { useContext } from 'react';
import { CustomModal } from '../../../components/CustomModal';
// import { NumericField } from '../../../components/NumericField';
// import { GreyButton } from '../../../components/GreyButton';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
// import { AnalyticalStepTwoContext } from '../../../context/Analytical/AnalyticalContext';

// const yesOrNot = [
//   { id: 0, value: false, text: 'No' },
//   { id: 1, value: true, text: 'Si' }
// ];

export const AnalyticalModalNewOriginal = ({ openModal, handleCloseModal, copieReason, handleChangeReason, options }) => {
  // const { serialN, setSerialN, setJustifyText, yesOrNotDelivered, denunciationN, setDenunciationN, handleOriginalDelivered } =
  //   useContext(AnalyticalStepTwoContext);
  return (
    <>
      {options.copyOriginalReasons && (
        <CustomModal open={openModal} handleClose={handleCloseModal}>
          {/* <Typography variant="body1">Anular analítico de {studentName}</Typography> */}
          <Typography variant="body1">Motivo</Typography>
          <Divider />
          <br />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={copieReason}
              label="Motivo"
              onChange={handleChangeReason}>
              {options?.copyOriginalReasons.map((e) => (
                <MenuItem key={e.id} value={e.id_reason}>
                  {e.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <br />
          <TextField type="text" label="Justificación" onChange={(e) => setJustifyText(e.target.value)} fullWidth></TextField>
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">¿Entrego analítico?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={yesOrNotDelivered}
              label="Entregado"
              onChange={handleOriginalDelivered}>
              {yesOrNot.map((e) => (
                <MenuItem key={e.id} value={e.value}>
                  {e.text}
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ mt: 2 }} autoComplete="off">
              <FormControl fullWidth>
                {yesOrNotDelivered === true && (
                  <NumericField
                    label="N° serie"
                    variant="outlined"
                    numberValue={serialN}
                    setNumberValue={setSerialN}
                    limit={15}
                    styles={{ mb: 2 }}
                  />
                )}
                {yesOrNotDelivered === false && (
                  <NumericField
                    label="N° de denuncia"
                    variant="outlined"
                    numberValue={denunciationN}
                    setNumberValue={setDenunciationN}
                    limit={15}
                    styles={{ mb: 2 }}
                  />
                )}
              </FormControl>
            </Box>
          </FormControl>
          <br /> */}
          <br />
          <Box sx={{ float: 'right', display: 'flex' }}>
            {/* <GreyButton action={handleCloseModal} text={'Cerrar'} /> */}
            <CustomYellowButton action={handleCloseModal} disabled={copieReason == undefined} title={'Guardar'} />
          </Box>
        </CustomModal>
      )}
    </>
  );
};
