import { Alert, Container, Divider, Grid, IconButton, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';

// import YouTube from 'react-youtube';

//Icons
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import StudyPlanPreApproved from '../assets/documents/studyPlan/StudyPlanPreApproved.pdf'
import ExtendCurriculumBoxStudyPlan from '../assets/documents/studyPlan/ExtendCurriculumBoxStudyPlan.pdf'
import UploadStudyPlan from '../assets/documents/studyPlan/UploadStudyPlan.pdf'

import firmPartialCertificate from '../assets/documents/analytical/firmPartialCertificate.pdf'
import newOriginalAnalytical from '../assets/documents/analytical/newOriginalAnalytical.pdf'
import newStudent from '../assets/documents/analytical/newStudent.pdf'

import unsubscribeAnalytic from '../assets/documents/historical/unsubscribeAnalytic.pdf'
import cancelAnalytic from '../assets/documents/historical/cancelAnalytic.pdf'
import { useState } from 'react';
import styled from '@emotion/styled';
import { AccordionButton } from '../components/Accordion';

export default function MainPage() {
  // const onPlayerReady = (event) => {
  //   event.target.pauseVideo();
  // }

  // const opts = {
  //   height: '390',
  //   width: '640',
  //   playerVars: {
  //     showinfo: 0,
  //     showsearch: 0,
  //     modestbranding: 1
  //   },
  // };

  const [expanded, setExpanded] = useState(false);
  // const [expandedVid, setExpandedVid] = useState(false);

  const ExpandMore = styled((props) => {
    const { ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <Container sx={{ background: 'white', my: 4, ml: 0 }} maxWidth='md'>
      <Typography variant="h3" sx={{ fontSize: '36px !important' }}>Instructivos y tutoriales</Typography>
      <Typography sx={{ fontSize: '29px !important' }}>Sistema de Títulos Educativos Digitales</Typography>
      <Typography sx={{ fontSize: '16px' }} component="div" >
        En este espacio vas a encontrar instructivos y tutoriales que te servirán de ayuda para utilizar los distintos módulos de la plataforma.
      </Typography>
      <Alert
        variant="outlined"
        severity="info"
        sx={{ mt: 2, display: 'center', width: '96%', fontSize: 16, borderLeft: '8px solid #03a9f4', borderRadius: '8px', backgroundColor: '#F5F9FC' }}>
        Si necesitás realizar alguna consulta podés escribirnos a <a href={'mailto:soporte.sited@bue.edu.ar'}>soporte.sited@bue.edu.ar</a>
      </Alert>
      <br />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: '#F3F6F9' }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '21px', fontWeight: '600' }} component="div">
                Instructivos
              </Typography>
              <ExpandMore
                onClick={e => e && setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                {expanded ? <RemoveIcon /> : <AddIcon />}
              </ExpandMore>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <AccordionButton
                  title={<Typography variant="h4">Módulo Plan de estudios</Typography>}
                  body={<><Link
                    underline='hover'
                    onClick={() => { window.open(StudyPlanPreApproved, '_blank') }}>
                    Asociar plan de estudios pre-aprobado <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                  </Link>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Link
                      underline='hover'
                      onClick={() => { window.open(ExtendCurriculumBoxStudyPlan, '_blank') }}>
                      Ampliar la caja curricular de un plan de estudios pre-aprobado <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Link
                      underline='hover'
                      onClick={() => { window.open(UploadStudyPlan, '_blank') }}>
                      Cargar un plan de estudios nuevo <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link></>}
                >
                </AccordionButton>
                <AccordionButton
                  title={<Typography variant="h4">Módulo Analíticos</Typography>}
                  body={<>
                    <Link
                      underline='hover'
                      onClick={() => { window.open(newStudent, '_blank') }}>
                      ¿Cómo agregar un alumno/a? <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Link
                      underline='hover'
                      onClick={() => { window.open(newOriginalAnalytical, '_blank') }}>
                      ¿Cómo generar un analítico? <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Link
                      underline='hover'
                      onClick={() => { window.open(firmPartialCertificate, '_blank') }}>
                      ¿Cómo firmar un analítico? <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link>
                  </>}
                >
                </AccordionButton>
                <AccordionButton
                  title={<Typography variant="h4">Módulo Histórico</Typography>}
                  body={<>
                    <Link
                      underline='hover'
                      onClick={() => { window.open(cancelAnalytic, '_blank') }}>
                      Anular un analítico <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Link
                      underline='hover'
                      onClick={() => { window.open(unsubscribeAnalytic, '_blank') }}>
                      Dar de baja un analítico <OpenInNewIcon fontSize='small' sx={{ mb: '-5px' }} />
                    </Link>
                  </>}
                >
                </AccordionButton>
                <br />
              </CardContent>
            </Collapse>
          </Card>

        </Grid>
        {/* <Grid item xs={12}>

          <Card sx={{ backgroundColor: '#F3F6F9' }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '21px', fontWeight: '600' }} component="div">
                Videos
              </Typography>
              <ExpandMore
                onClick={e => e && setExpandedVid(!expandedVid)}
                aria-expanded={expandedVid}
                aria-label="show more"
              >
                {expandedVid ? <RemoveIcon /> : <AddIcon />}
              </ExpandMore>
            </CardContent>
            <Collapse in={expandedVid} timeout="auto" unmountOnExit>
              <CardContent>
                <AccordionButton
                  title={<Typography variant="h4">Módulo Plan de estudios</Typography>}
                  body={<>
                    <AccordionButton
                      title={<Link underline='none'>Asociar plan de estudios pre-aprobado</Link>}
                      body={<YouTube videoId="Wqpi26p9OVo" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                    <AccordionButton
                      title={<Link underline='none'>Ampliar la caja curricular de un plan de estudios pre-aprobado</Link>}
                      body={<YouTube videoId="181XNwfXdkk" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                    <AccordionButton
                      title={<Link underline='none'>Cargar un plan de estudios nuevo</Link>}
                      body={<YouTube videoId="zcZ_pTcMcTw" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                  </>}
                />
                <AccordionButton
                  title={<Typography variant="h4">Módulo Analíticos</Typography>}
                  body={<>
                    <AccordionButton
                      title={<Link underline='none'>¿Cómo agregar un alumno/a?</Link>}
                      body={<YouTube videoId="7qEA-IQITXs" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                    <AccordionButton
                      title={<Link underline='none'>¿Cómo generar un analítico original?</Link>}
                      body={<YouTube videoId="o2rlEfe7z3c" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                    <AccordionButton
                      title={<Link underline='none'>¿Cómo generar un certificado de estudios parciales?</Link>}
                      body={<YouTube videoId="PqwtbyHZGdk" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                    <AccordionButton
                      title={<Link underline='none'>¿Cómo firmar un analítico/certificado de estudios parciales?</Link>}
                      body={<YouTube videoId="X5KbfB_zobk" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                  </>}
                />
                <AccordionButton
                  title={<Typography variant="h4">Módulo Histórico</Typography>}
                  body={<>
                    <AccordionButton
                      title={<Link underline='none'>Anular un analítico</Link>}
                      body={<YouTube videoId="-bvPqIFPx88" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                    <AccordionButton
                      title={<Link underline='none'>Dar de baja un analítico</Link>}
                      body={<YouTube videoId="QZHANJKGG-k" opts={opts} onReady={e => onPlayerReady(e)} />}
                    />
                  </>}
                />
              </CardContent>
            </Collapse>
          </Card>

        </Grid> */}
      </Grid>
    </Container>
  );
}
