import { Box, Grid, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { GenericForm } from '../../../../components/GenericForm';
import { GreyButton } from '../../../../components/GreyButton';
import RolesConfig from '../../../../configs/Admin/rolesConfig.json';
import { EditRolContext } from '../../../../context/admin/editRol/EditRolContext';
import { useGenericForm } from '../../../../hooks/useGenericForm';
import { EditRolPermissions } from './EditRolPermissions';

export const EditRolForm = () => {
  const { form, handleValue, validateFormAndExecutePostAction, initForm, config, setConfig } = useGenericForm(RolesConfig.formControls);
  const { permissions, onSaveRol, rolPermissionsIds, rolInfo } = useContext(EditRolContext);
  const [permissionsControls, setPermissionsControls] = useState([]);

  useEffect(() => {
    if (permissions.length > 0) {
      let newPermissionsControls = permissions.map((x) => {
        x.checked = false;
        return x;
      });
      if (rolPermissionsIds.length > 0) {
        rolPermissionsIds.forEach((id) => {
          newPermissionsControls.filter((x) => x.id === id)[0].checked = true;
        });
      }
      setPermissionsControls(newPermissionsControls);
    }
  }, [permissions, rolPermissionsIds]);

  useEffect(() => {
    if (rolInfo) {
      initForm(rolInfo);
      if (!rolInfo.is_editable) {
        setConfig((prevConfig) =>
          prevConfig.map((x) => {
            return { ...x, disabled: true };
          })
        );
      }
    }
  }, [rolInfo]);

  const onChangeChecked = (id) => {
    let newPermissionsControls = [...permissionsControls];
    let permission = newPermissionsControls.filter((x) => x.id === id)[0];
    permission.checked = !permission.checked;
    setPermissionsControls(newPermissionsControls);
  };

  const saveRol = (form) => {
    onSaveRol({ form: form, permissionsControls: permissionsControls });
  };

  return (
    <>
      <Paper elevation={3}>
        <Box sx={{ p: 3, pb: 4 }}>
          <br />
          <Grid container>
            <Typography>Información Rol</Typography>
          </Grid>
          <GenericForm formControls={config} form={form} handleValue={handleValue} />
          <br></br>
          <Grid container>
            <Typography>Información Permisos</Typography>
          </Grid>
          <br></br>
          <Grid container>
            <EditRolPermissions permissionsControls={permissionsControls} onChangeChecked={onChangeChecked} />
          </Grid>
        </Box>
      </Paper>
      <br />
      <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
        <GreyButton
          text="Guardar"
          action={() => {
            validateFormAndExecutePostAction(saveRol);
          }}
        />
      </Box>
    </>
  );
};
