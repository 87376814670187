import { getData } from './Services';

export const getEstablishments = () => {
  let data = getData('/establishment');
  return data;
};

export const getEstablishmentsAutocomplete = () => {
  let data = getData('/establishment/names');
  return data;
};

export const getEstablishmentsByFilter = (filter, limit, page) => {
  let qs = `?page=${page}&limit=${limit}`;
  qs += filter ? '&name=' + filter : '';
  let data = getData('/establishment/filter' + qs);
  return data;
};
