import { MyButton } from '../styles/Styles';
import '../styles/styles.css';

export const CustomYellowButton = ({ action, title, disabled = false, style }) => {
  return (
    <>
      <MyButton variant="contained" style={style} disabled={disabled} onClick={action}>
        {title}
      </MyButton>
    </>
  );
};
