import {getData,putData} from './Services'

export const getAllUsersServices = async(state,limit=10,page=1) =>{
    const res = await getData( `/user?page=${page}&limit=${limit}&term=${state}`)
    return res
} 

export const getAllRolesServices = async() =>{
    const res = await getData( "/authorization/roles/all")
    return res
}

export const getUserPositions = async() => {
    const res = await getData("/position");
    return res
}

export const putRolesServices = async(body) =>{
    const res = await putData( "/authorization/",body)
    return res
}