import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { EditableCurricularBoxRow } from './EditableCurricularBoxRow';
export const EditableCurricularBoxTable = ({ data, onUpdateRow, onDeleteRow }) => {
  return (
    <Box>
      <Box>
        <TableContainer size="small" aria-label="a dense table" sx={{ width: '100%' }}>
          {data && data.length > 0 ? (
            <Table aria-label="dense table" size="small">
              <TableHead sx={{ backgroundColor: '#F3F6F9' }}>
                <TableRow>
                  <TableCell>{<Typography>Espacio curricular</Typography>}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: '100%' }}>
                {data.map((row) => (
                  <EditableCurricularBoxRow key={row.id} row={row} onUpdateRow={onUpdateRow} onDeleteRow={onDeleteRow} />
                ))}
              </TableBody>
            </Table>
          ) : null}
        </TableContainer>
      </Box>
      <br></br>
    </Box>
  );
};
