import { getData, patchData } from './Services';

export const getHistoricalStudents = async (page, limit, establishmentId, termFilter, yearFilter) => {
  const res = await getData(
    `/historical?page=${page}&limit=${limit}${establishmentId ? `&establishment_id=${establishmentId}` : ''}${
      yearFilter ? `&date=${yearFilter}` : ''
    }${termFilter ? `&term=${termFilter}` : ''}`
  );
  return res;
};
export const getHistoricalStablishmentStudents = async (page, limit, termFilter, yearFilter) => {
  const res = await getData(
    `/establishment/GroupByEstablishmentForHistorical?page=${page}&limit=${limit}${
      yearFilter ? `&date=${yearFilter}` : ''
    }${termFilter ? `&term=${termFilter}` : ''}`
  );
  return res;
};

export const patchUnsubscribeAnalytic = async (analytic) => {
  return await patchData('/historical/unsubscribe', analytic);
};

export const getCopyTypes = async (id) => {
  return await getData('/historical/copyTypes/' + id);
};

export const cancelAnalytic = async (analytic) => {
  return await patchData('/historical/override', analytic);
};
