import moment from 'moment';

const DNI_TYPE = 2;

export const getValidateFunction = (validationKey) => {
  switch (validationKey) {
    case 'dni':
      return dniValidation;
    case 'number':
      return numberValidation;
    case 'mail':
      return emailValidation;
    case 'cuit':
      return isValidCUITCUIL;
    case 'multipleSelect':
      return multipleSelectValidation;
    case 'minLength':
      return minLengthValidation;
    case 'maxLength':
      return maxLengthValidation;
    case 'numberAndmaxLength':
      return numberAndmaxLengthValidation;
    case 'yearLimit':
      return yearLimitValidation;
    case 'date':
      return isDate;
    case 'minDate':
      return minDateValidation;
    default:
      return null;
  }
};

//esto no quedo muy generico la verdad, pero es la solucion mas facil
const dniValidation = (value, config, form) => {
  if (form.document_type_id !== DNI_TYPE) {
    return maxLengthValidation(value, config);
  }
  return value.length >= 7 && value.length <= 9 && value.match('^[0-9]*$')
    ? { isValid: true, errorMessage: '' }
    : { isValid: false, errorMessage: `El formato del dni es incorrecto. Recuerde solo ingresar números` };
};

const numberValidation = (value) => {
  if (value && !value.match('^[0-9]*$')) return { isValid: false, errorMessage: `Formato incorrecto, solo debe contener números.` };
  return { isValid: true, errorMessage: '' };
};

const emailValidation = (value) => {
  if (value && !value.match('[aA-zZ0-9._%+-]+@[aA-zZ0-9.-]+[aA-zZ]{2,4}$'))
    return { isValid: false, errorMessage: 'Formato de email incorrecto.' };
  if (value && !value.includes('@bue.edu.ar')) return { isValid: false, errorMessage: 'El email no coincide con el dominio @bue.edu.ar' };
  return { isValid: true, errorMessage: '' };
};

const multipleSelectValidation = (value) => {
  return value && value.length > 0
    ? { isValid: true, errorMessage: '' }
    : { isValid: false, errorMessage: 'debe seleccionar al menos un registro' };
};

const minLengthValidation = (value, config) => {
  return value.length >= config.min && (config.required ? alphaNumValidation(value).isValid : true)
    ? { isValid: true, errorMessage: '' }
    : {
        isValid: false,
        errorMessage: `El texto debe tener más de ${config.min} caracteres${alphaNumValidation(value).errorMessage}`
      };
};

const maxLengthValidation = (value, config) => {
  if (alphaNumValidation(value).isValid) {
    return value.length <= config.max
      ? { isValid: true, errorMessage: '' }
      : {
          isValid: false,
          errorMessage: `El texto debe tener menos de ${config.max} caracteres.`
        };
  } else {
    return {
      isValid: false,
      errorMessage: alphaNumValidation(value).errorMessage
    };
  }
};

const numberAndmaxLengthValidation = (value, config) => {
  if (value && !value.toString().match('^[0-9]*$')) {
    return { isValid: false, errorMessage: `Formato incorrecto, solo debe contener números.` };
  }
  if (value.toString().length <= config.max) {
    return { isValid: true, errorMessage: '' };
  }
  return { isValid: false, errorMessage: `El texto debe tener menos de ${config.max} caracteres.` };
};

const isValidCUITCUIL = (cuit) => {
  let rv = false;
  let resultado = 0;
  let cuit_nro = cuit.replace('-', '');
  if (cuit_nro.length != 11) return { isValid: false, errorMessage: 'El tamaño del cuil no es correcto, deben ser 11 digitos' };
  const codes = '6789456789';
  let verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
  let x = 0;

  while (x < 10) {
    let digitoValidador = parseInt(codes.substring(x, x + 1));
    if (isNaN(digitoValidador)) digitoValidador = 0;
    let digito = parseInt(cuit_nro.substring(x, x + 1));
    if (isNaN(digito)) digito = 0;
    let digitoValidacion = digitoValidador * digito;
    resultado += digitoValidacion;
    x++;
  }
  resultado = resultado % 11;
  rv = resultado == verificador;
  return rv ? { isValid: true, errorMessage: '' } : { isValid: false, errorMessage: 'El formato del cuil es incorrecto' };
};

export const alphaNumValidation = (text) => {
  const alphaNumRegEx = /^[\s]/;
  if (text.match(alphaNumRegEx)) {
    return { isValid: false, errorMessage: 'El texto no debe empezar ni contener solo espacios.' };
  } else if (!text.match(alphaNumRegEx)) {
    return { isValid: true, errorMessage: '.' };
  }
};

export const yearLimitValidation = (value) => {
  const currentYear = localStorage.getItem('limitYear');
  if (value <= currentYear && value >= 1900) {
    return { isValid: true, errorMessage: '' };
  }
  return { isValid: false, errorMessage: `El ciclo lectivo deberia estar en el rango entre 1900 y ${currentYear}` };
};

export const isDate = (value) => {
  return moment(value).isValid()
    ? {
        isValid: true,
        errorMessage: ''
      }
    : {
        isValid: false,
        errorMessage: 'Ingrese un formato de fecha válido.'
      };
};

export const minDateValidation = (date, minDate) => {
  if (!isDate(date).isValid) {
    return isDate(date);
  } else if (moment(date).isBefore(minDate)) {
    return {
      isValid: false,
      errorMessage: 'La fecha de emisión no puede ser anterior a la fecha de egreso.'
    };
  }
  return { isValid: true, errorMessage: '' };
};
