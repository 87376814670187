import PropTypes from 'prop-types';
import { Box, Checkbox, TableCell, TableRow } from '@mui/material';
import { rowsShadows, stylesRows } from '../styles/Styles';
import React from 'react';

const RegularTableRow = ({ columns, row, checkable, onCheck, collapsibleChild, firstChild, lastChild }) => {
  const middleChildShadows = `${rowsShadows.left}, ${rowsShadows.right}`;
  const firstChildShadows = `${middleChildShadows}, ${rowsShadows.top}`;
  const lastChildShadows = `${middleChildShadows}, ${rowsShadows.bottom}`;
  const collapsibleChildShadow =
    collapsibleChild &&
    (!firstChild && !lastChild ? middleChildShadows : firstChild ? firstChildShadows : lastChild ? lastChildShadows : '');

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: !collapsibleChild ? '#EDEBEC' : '#9e949a'
          },
          backgroundColor: collapsibleChild && '#EDEBEC',
          boxShadow: collapsibleChildShadow
        }}>
        {checkable && (
          <TableCell key={row.id} sx={{ ...stylesRows }}>
            <Checkbox
              onChange={() => {
                onCheck(row.id);
              }}
              checked={row.checked}
              size="small"
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell key={row.id + ' ' + column.label + ' ' + row[column.field]} sx={{ ...stylesRows }}>
            {row[column.field]}
          </TableCell>
        ))}
        <TableCell key={row.id + ' Acciones'} sx={{ ...stylesRows, width: "15%" }}>
          <Box sx={{ alignItems: 'center' }}>{row.actions ? row.actions.map((x) => x.html) : null}</Box>
        </TableCell>
      </TableRow>
    </>
  );
};

RegularTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  checkable: PropTypes.bool,
  onCheck: PropTypes.func,
  collapsibleChild: PropTypes.bool
};

export default RegularTableRow;
