import Typography from '@mui/material/Typography'
export default function NotFoundPage() {
    return (
        <div>
            <Typography variant="h1" color="white" align="center" >
                404
            </Typography>
        </div>
    )
}
