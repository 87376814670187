import { usePagination } from "./usePagination"
import { useState } from "react";

export const useTable = (withPagination) => {
    const [data, setData] = useState([]);
    //usar este campo solo si es editable para mostrar los selects 
    //si esto escala mucho capaz deberiamos separar el usetable fijo de la tabla editable
    const [options, setOptions] = useState([]);
    const pagination = usePagination()

    const updateOptions = (newOptions) => {
        setOptions(newOptions);
    }

    const onChange = (newData, limit = null, page = null, count = null) => {
        setData(newData)
        if (withPagination) {
            pagination.updatePagination(limit, page);
            pagination.updateCount(count);
        }
    }

    /*se expone el siguiente objeto
    data : registros a renderizar
    options: listas maestras que deben ser renderizadas en los selects
    pagination : información del hook use pagination (ver documentación del use pagination)
    OnChange : metodo que actualiza el paginado y la data en una actualizacion  */

    return { data, options, paginationProps: withPagination ? pagination.paginationProps : null, updateOptions, onChange }

}