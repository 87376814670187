import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteCurriculumObserve,
  getCurricularBoxById,
  getCurriculumById,
  patchCurriculumObserve,
  postCurriculumObserve
} from '../../services/CurriculumServices';
import { CurriculumContext } from './CurriculumContext';

export const CurriculumDetailState = ({ children }) => {
  const [curriculumBox, setCurriculumBox] = useState([]);
  const [curriculum, setCurriculum] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getCurriculumBoxData(id);
      getCurriculumByIdData(id);
    }
  }, [id]);

  const getCurriculumBoxData = async (id) => {
    const res = await getCurricularBoxById(id);
    setCurriculumBox(res);
  };

  const getCurriculumByIdData = async (id) => {
    const res = await getCurriculumById(id);
    setCurriculum(res);
  };

  const patchCurriculumNote = async (body) => {
    await patchCurriculumObserve(body);
    getCurriculumByIdData(id);
  };

  const postCurriculumNote = async (body) => {
    await postCurriculumObserve(body);
    getCurriculumByIdData(id);
  };

  const deleteCurriculumNote = async (observationId) => {
    await deleteCurriculumObserve(observationId);
    getCurriculumByIdData(id);
  };

  return (
    <>
      <CurriculumContext.Provider
        value={{
          curriculumBox,
          curriculum,
          patchCurriculumNote,
          postCurriculumNote,
          deleteCurriculumNote
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </CurriculumContext.Provider>
    </>
  );
};
