import { Box, Grid, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useContext } from 'react';
import { GenericForm } from '../../../../components/GenericForm';
import { GreyButton } from '../../../../components/GreyButton';
import UsersConfig from '../../../../configs/Admin/usersConfig.json';
import { EditUserContext } from '../../../../context/admin/editUser/EditUserContext';
import { useGenericForm } from '../../../../hooks/useGenericForm';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { Actions } from '../../../../constants/Actions';

export const EditUserForm = () => {
  const mapFormControls = () =>
    UsersConfig.formControls.map((e) => {
      if (e.field === 'roles') {
        e.action = handleChangeRoles;
      }
      return { ...e };
    });
  const {
    form,
    config,
    validateFormAndExecutePostAction,
    initForm,
    setConfig,
    handleValue,
    setForm,
    hasModifications,
    setHasModifications
  } = useGenericForm(UsersConfig.formControls, mapFormControls);
  const { onSaveUser, options, userInfo, emptyOptions } = useContext(EditUserContext);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuthHelper();

  useEffect(() => {
    //esto es para chequear que las opciones  ya se hayan cargado
    //no es mi idea mas brillante la verdad
    if (userInfo && options !== emptyOptions) {
      initForm(userInfo, options);
      setFieldVisibility(
        'establishment',
        userInfo.roles.some((x) => !x.is_global)
      );
    }
  }, [userInfo, options]);

  useEffect(() => {
    if (auth.isSuperAdmin()) {
      setFieldVisibility('positions', true);
    }
  }, []);

  useEffect(() => {
    // al cargar las opciones de establecimientos va a validar los permisos del user
    // en función de éso, habilitará o deshabilitará las opciones de establecimientos
    // estatales
    const userHasPermission = auth.isAllowed([Actions.ASSOCIATE_STATEESTABLISHMENT]);
    if (!userHasPermission) {
      options.establishments.map((establishment) => {
        establishment.disabled = establishment.is_public;
      })
    }
  }, [options])

  const postActionSave = (form) => {
    onSaveUser({ form: form });
  };

  const handleChangeRoles = (value) => {
    setHasModifications(true);
    if (!value.length) {
      setForm((prevForm) => {
        return { ...prevForm, roles: [] };
      });
      setFieldVisibility('establishment', true);
    }
    let globalProfiles = value.every((x) => x.is_global);
    let establishmentProfiles = value.every((x) => !x.is_global);
    let isRolWithRequiredEstablishment = value.some((x) => x.id === 1 || x.id === 2 || x.id === 3 || x.id === 7);
    if (isRolWithRequiredEstablishment) {
      setConfig((prevForm) =>
        prevForm.map((x) => {
          let e = { ...x };
          if (e.field === 'establishments') {
            e.required = true;
          }
          return e;
        })
      );
    } else {
      setConfig((prevForm) =>
        prevForm.map((x) => {
          let e = { ...x };
          if (e.field === 'establishments') {
            e.required = false;
          }
          return e;
        })
      );
    }
    if (!globalProfiles && !establishmentProfiles) {
      enqueueSnackbar('No puede elegir roles globales y roles para establecimientos únicos', { variant: 'warning' });
    } else {
      if (globalProfiles) {
        handleGlobalProfileForm(value);
      } else {
        handleEstablishmentForm(value);
      }
    }
  };

  const handleGlobalProfileForm = (value) => {
    setForm((prevForm) => {
      return { ...prevForm, roles: value, establishments: [] };
    });
    //esto solo hace falta hacerlo una vez, y cuando se borra
    if (value.length === 1) setFieldVisibility('establishment', false);
  };

  const handleEstablishmentForm = (value) => {
    setForm((prevForm) => {
      return { ...prevForm, roles: value };
    });
    if (value.length === 1) setFieldVisibility('establishment', true);
  };

  const setFieldVisibility = (field, show) => {
    setConfig((prevConfig) =>
      prevConfig.map((x) => {
        let e = { ...x };
        if (e.field === field) {
          e.hidden = !show;
        }
        return e;
      })
    );
  };

  return (
    <>
      <Paper elevation={3}>
        <Box sx={{ p: 3, pb: 4 }}>
          <br />
          <Grid container>
            <Typography>Información Usuario</Typography>
          </Grid>
          <GenericForm options={options} formControls={config} form={form} handleValue={handleValue} />
          <br></br>
        </Box>
      </Paper>
      <br />
      <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
        <GreyButton
          text="Guardar"
          disabled={!hasModifications}
          action={() => {
            validateFormAndExecutePostAction(postActionSave);
          }}
        />
      </Box>
    </>
  );
};
