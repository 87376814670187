import { useParams } from 'react-router-dom';
import { FormPageHeader } from '../../../../components/FormPageHeader';
import { EditUserState } from '../../../../context/admin/editUser/EditUserState';
import { EditUserForm } from './EditUserForm';
import { AdminRouteConstants } from '../../../../routes/RoutesConstants';
import Container from '@mui/material/Container';

export const EditUserPage = () => {
  const { id } = useParams();

  return (
    <EditUserState>
      <Container sx={{ mt: 4 }}>
        <FormPageHeader
          titleEdition={'Editar Usuario'}
          titleCreation={'Crear Usuario'}
          isEdition={id !== undefined}
          baseBreadCrumb="Usuarios"
          baseLink={AdminRouteConstants.USUARIO_BUSCAR}
          actualBreadCrumb={id !== undefined ? 'Editar usuario' : 'Crear usuario'}
        />
        <br></br>
        <EditUserForm />
      </Container>
    </EditUserState>
  );
};
