import { useEffect, useState } from 'react';
import { useTable } from '../../../hooks/useTable';
import { getAllAnalyticStatus, getAnalyticalEstablishment } from '../../../services/AnalyticalServices';
import { AnalyticalEstablishmentContext } from '../AnalyticalContext';
import { useContext } from 'react';
import { AppContext } from '../../global/AppContext';
import { ADMINISTRADOR, AUTORIDAD_DE_LEGALIZACION, SUPER_ADMIN } from '../../../constants/Roles';

export const AnalyticalEstablishmentState = ({ children }) => {
  const [getAnalyticalParameters, setGetAnalyticalParameters] = useState({ establishment: '', status: '' });
  const tableInfo = useTable(true);
  const { paginationProps, data } = tableInfo;
  const { user } = useContext(AppContext);
  const [allAnalyticalStatus, setAllAnalyticalStatus] = useState([]);

  useEffect(() => {
    getAnalyticalEstablishmentData();
    getAllAnalyticalStatusData();
  }, []);

  const getAnalyticalEstablishmentData = async (page = 0, limit = paginationProps.default_limit, establishment = '', status = '') => {
    setGetAnalyticalParameters({ establishment, status });
    const response = await getAnalyticalEstablishment(page, limit, establishment, status);
    tableInfo.onChange(response.data, limit, page, response.count);
  };

  const getAllAnalyticalStatusData = async () => {
    const isUserLegalization = user.roles.includes(AUTORIDAD_DE_LEGALIZACION);
    const isUserAdmin = user.roles.includes(ADMINISTRADOR) || user.roles.includes(SUPER_ADMIN);
    let data = await getAllAnalyticStatus(user);

    data.unshift({ id: 0, name: 'Todos los estados', value: '' });
    const addedValueProp = data.map((option) => ({ ...option, value: option.id }));
    const filteredAnalyticalStatus = addedValueProp.filter((status) => {
      const { id } = status;
      return isUserLegalization ? id == 0 || (id >= 4 && id <= 6) || id == 9 : id <= 7;
    });

    setAllAnalyticalStatus(isUserAdmin ? addedValueProp : filteredAnalyticalStatus);
  };

  const handlePageChange = (limit = 10, page) => {
    const { establishment, status } = getAnalyticalParameters;
    getAnalyticalEstablishmentData(page, limit, establishment, status);
  };

  return (
    <AnalyticalEstablishmentContext.Provider
      value={{
        establishments: data,
        paginationProps: tableInfo.paginationProps,
        getAnalyticalEstablishmentData,
        handlePageChange,
        allAnalyticalStatus
      }}>
      {children}
    </AnalyticalEstablishmentContext.Provider>
  );
};
