import { Divider, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { useReasonsInfo } from '../../../hooks/useReasonsInfo';

export const CancelLegalizedAnalyticModal = ({ handleClose, handleCancel, id }) => {
  const { reasons } = useReasonsInfo(id);
  const [reasonSelected, setReasonSelected] = useState('');

  const onUnsubscribed = () => {
    const request = { analytic_id: parseInt(id), copy_type: 1, copy_reason: reasonSelected };
    handleCancel(request);
  };

  return (
    <>
      <Typography variant="h6">Anular analítico</Typography>
      <br></br>
      <Divider />
      <br></br>

      <Grid container>
        <Typography>Motivo</Typography>
      </Grid>
      <br></br>
      <Grid container>
        <FormControl fullWidth sx={{ backgroundColor: '#F5F9FC' }} size="small">
          <Select
            sx={{
              backgroundColor: '#F5F9FC',
              '& .Mui-disabled': {
                backgroundColor: '#DCDCDC'
              }
            }}
            value={reasonSelected}
            onChange={(event) => setReasonSelected(event.target.value)}>
            {reasons &&
              reasons.map((x) => {
                return (
                  <MenuItem key={x.id} value={x.id}>
                    {x.reason}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      <br></br>

      <Grid container item justifyContent={'flex-end'}>
        <CustomYellowButton action={handleClose} title={'Cancelar'} style={{ marginRight: 10 }} />
        <CustomYellowButton disabled={!reasonSelected} action={onUnsubscribed} title={'Anular'} />
      </Grid>
    </>
  );
};
