import { Box, Typography, Grid, Link } from '@mui/material';

export const ShowDataDetail = ({ title, info, inline = false , isLink = false}) => {
  return inline ? (
    <>
      <Grid container spacing={2} sx={{ mt: 0, pb: 2, borderBottom: 'solid 1px #CCC', alignItems: 'flex-start', maxWidth: '800px', wordWrap: 'break-word' }}>
        <Grid item xs={5} sx={{ fontWeight: 'light' }}>
          {title}
        </Grid>
        {info ? (
          <Grid item xs={7} sx={{ fontWeight: 'bold' }}>
            {!isLink ? info : (
            <Link href={info} target="_blank" rel="noopener noreferrer">
              Ver
            </Link>
            )}
          </Grid>
        ) : (
          <Grid item xs={7} sx={{ fontWeight: 'bold', color: '#9EAAB8' }}>
            No aplica
          </Grid>
        )}
      </Grid>
    </>
  ) : (
    <>
      <Box sx={{ mr: 2 }}>
        <Typography component={'div'}>
          <Box sx={{ fontWeight: 'light', m: 1, mr: 7 }}>{title}</Box>
          <Box sx={{ fontWeight: 'bold', m: 1 }}>{info}</Box>
        </Typography>
      </Box>
    </>
  );
};
