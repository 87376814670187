import { Grid } from '@mui/material';
import { CurriculumEstablishmentState } from '../../../../context/Curriculum/CurriculumEstablishmentState';
import { CurriculumHead } from '../../curriculumsTable/CurriculumHead';
import { CurriculumFilters } from '../../curriculumsTable/CurriculumFilters';
import { CurriculumEstablishmentsTable } from './CurriculumEstablishmentsTable';

export const CurriculumEstablishmentPage = () => {
  return (
    <CurriculumEstablishmentState>
      <Grid sx={{ mt: 4, mb: 4 }}>
        <CurriculumHead establishmentVersion />
        <CurriculumFilters establishmentVersion />
        <CurriculumEstablishmentsTable />
      </Grid>
    </CurriculumEstablishmentState>
  );
};
