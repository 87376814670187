import {
  ANULADO,
  COMPLETO,
  EN_PROCESO,
  OBSERVADO,
  PENDIENTE_DE_ENVIO,
  PENDIENTE_DE_FIRMA,
  PENDIENTE_DE_VALIDACION,
  PENDIENTE_DE_FIRMA_DIGITAL,
  VALIDADO,
  PENDIENTE_DE_IMPRESION,
  IMPRESO,
  VALIDAR_IMPRESION,
  LEGALIZADO,
  ENVIADO
} from '../constants/AnalyticalStates';
import { CurriculumStateJSX } from '../constants/CurriculumStates';
import { Canceled, InProcess, Observed, Pending, Validated, Empty } from '../styles/Styles';

// Estados firma
import { Avatar } from '@mui/material';
import in_process from '../assets/images/signature/f0.png';
import pending from '../assets/images/signature/f_pending.png';
import validated from '../assets/images/signature/f2.png';

export const stateHtmlAnalytics = (e) => {
  switch (e.stateNumber) {
    case EN_PROCESO:
    case COMPLETO:
      return <InProcess>{e.state}</InProcess>;
    case PENDIENTE_DE_FIRMA:
    case PENDIENTE_DE_FIRMA_DIGITAL:
    case PENDIENTE_DE_VALIDACION:
    case PENDIENTE_DE_ENVIO:
    case PENDIENTE_DE_IMPRESION:
    case VALIDAR_IMPRESION:
      return <Pending>{e.state}</Pending>;
    case OBSERVADO:
      return <Observed>{e.state}</Observed>;
    case VALIDADO:
    case IMPRESO:
    case LEGALIZADO:
    case ENVIADO:
      return <Validated>{e.state}</Validated>;
    case ANULADO:
      return <Canceled>{e.state}</Canceled>;
    default:
      return <Empty>{e.state}</Empty>;
  }
};
const AvatarSignature = ({ f }) => <Avatar alt="estado" src={f} sx={{ mr: 2, width: '2em', height: '2em', my: 'auto' }} />;

export const stateHtmlAnalyticsAvatar = (e) => {
  switch (e.stateNumber) {
    case EN_PROCESO:
    case COMPLETO:
    case OBSERVADO:
    case ANULADO:
      return <AvatarSignature f={in_process} />;
    case PENDIENTE_DE_FIRMA:
    case PENDIENTE_DE_FIRMA_DIGITAL:
      return <AvatarSignature f={pending} />;
    case PENDIENTE_DE_VALIDACION:
    case PENDIENTE_DE_ENVIO:
    case VALIDADO:
    case LEGALIZADO:
      return <AvatarSignature f={validated} />;
    default:
      return e.state;
  }
};

export const stateHtmlCurriculums = (status) => {
  return CurriculumStateJSX[status] || '';
};
