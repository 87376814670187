import { TableCell, TableRow, TextField, Grid, Tooltip, IconButton } from '@mui/material';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

export const EditableCurricularBoxRow = ({ row, onUpdateRow, onDeleteRow }) => {
  const { id, course, aproved } = row;
  const [value, setValue] = useState(course);
  const [touched, setTouched] = useState(false);

  const onBlur = (e) => {
    onUpdateRow(id, e.target.value);
  };

  const deleteRow = () => {
    onDeleteRow(id);
  };

  return (
    <TableRow
      key={row.id}
      sx={{
        cursor: 'pointer'
      }}>
      <TableCell>
        <Grid container>
          <Grid item xs>
            <TextField
              variant="standard"
              fullWidth
              disabled={aproved}
              placeholder={'Ingrese el nombre del espacio curricular'}
              value={value}
              // onBlur={onBlur}
              onKeyUp={onBlur}
              error={touched && !value && value === ' '}
              // error={touched && !value}
              required={true}
              onChange={(e) => {
                if (e.target.value.length <= 75) {
                  setValue(e.target.value);
                  setTouched(true);
                } else {
                  setValue(e.target.value.slice(0, 75)); // Recorta el valor al límite máximo
                  setTouched(true);
                }
              }}
              InputProps={{
                disableUnderline: true
              }}></TextField>
          </Grid>
          {!aproved ? (
            <Grid container item xs justifyContent={'flex-end'}>
              <Tooltip title="Borrar espacio curricular">
                <IconButton onClick={deleteRow}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      </TableCell>
    </TableRow>
  );
};
