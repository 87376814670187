import { Box, Grid, Paper, Typography } from '@mui/material';
import { ShowDataDetail } from '../../../components/ShowDataDetail';
import {
  ANULADO,
  COMPLETO,
  EN_PROCESO,
  OBSERVADO,
  PENDIENTE_DE_ENVIO,
  PENDIENTE_DE_FIRMA_DIGITAL,
  PENDIENTE_DE_FIRMA,
  PENDIENTE_DE_VALIDACION,
  VALIDADO,
  PENDIENTE_DE_IMPRESION,
  IMPRESO,
  VALIDAR_IMPRESION,
  LEGALIZADO,
  ENVIADO
} from '../../../constants/AnalyticalStates';
import { Canceled, InProcess, Observed, Pending, Validated } from '../../../styles/Styles';

export const StudentCard = ({ student }) => {
  const stateHtmlAnalytics = (state) => {
    switch (state) {
      case EN_PROCESO:
        return <InProcess>EN PROCESO</InProcess>;
      case COMPLETO:
        return <InProcess>COMPLETO</InProcess>;
      case PENDIENTE_DE_FIRMA:
        return <Pending>PENDIENTE DE FIRMA</Pending>;
      case PENDIENTE_DE_VALIDACION:
        return <Pending>PENDIENTE DE VALIDACION</Pending>;
      case PENDIENTE_DE_ENVIO:
        return <Pending>PENDIENTE DE ENVIO</Pending>;
      case PENDIENTE_DE_FIRMA_DIGITAL:
        return <Pending>PENDIENTE DE FIRMA DIGITAL</Pending>;
      case PENDIENTE_DE_IMPRESION:
        return <Pending>PENDIENTE DE IMPRESION</Pending>;
      case VALIDAR_IMPRESION:
        return <Pending>VALIDAR IMPRESION</Pending>;
      case OBSERVADO:
        return <Observed>OBSERVADO</Observed>;
      case VALIDADO:
        return <Validated>VALIDADO</Validated>;
      case IMPRESO:
        return <Validated>IMPRESO</Validated>;
      case LEGALIZADO:
        return <Validated>LEGALIZADO</Validated>;
      case ENVIADO:
        return <Validated>ENVIADO</Validated>;
      case ANULADO:
        return <Canceled>ANULADO</Canceled>;
      default:
        return '';
    }
  };

  return (
    <>
      {student.analytic_student && (
        <Paper elevation={0} sx={{ backgroundColor: '#F3F6F9', padding: 4 }}>
          <Grid sx={{ display: 'flex', mb: 2, alignItems: 'flex-end' }}>
            <Typography variant="h3">
              {student?.analytic_student.name} {student?.analytic_student.last_name}
            </Typography>
            <Grid sx={{ ml: 2, mb: 1 }}>{student?.analytic_status_id && stateHtmlAnalytics(student?.analytic_status_id)}</Grid>
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <ShowDataDetail title={'Tipo de Documento'} info={student?.analytic_student.document_type?.name} />
            <ShowDataDetail title={'Documento'} info={student?.analytic_student.document_number} />
            <ShowDataDetail title={'Nacimiento'} info={student?.analytic_student.birth_date} />
            <ShowDataDetail title={'Lugar'} info={student?.analytic_student.birthplace} />
          </Box>
        </Paper>
      )}
      <br />
    </>
  );
};
