import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { MultipleSelectHeader } from '../../../components/EditableTable/MultipleSelectHeader';

export const CurricularBoxHeaderTable = ({ tableConfig, updateMultipleColumn, blockRow, selectOptions }) => {
  const { subjectConfig, calificationConfig, conditionConfig, monthConfig, yearConfig, establishmentConfig } = tableConfig;

  return (
    <TableRow>
      <TableCell width={subjectConfig.width} key={subjectConfig.label}>
        <Typography style={{ marginTop: 15 }}> {subjectConfig.label}</Typography>
      </TableCell>
      <MultipleSelectHeader
        blockRow={blockRow}
        width={calificationConfig.width}
        idHeaderSelect={calificationConfig.idHeaderSelect}
        label={calificationConfig.label}
        key={calificationConfig.label}
        updateSelect={updateMultipleColumn}
        notObjectValue={calificationConfig.notObjectValue}
        options={selectOptions[calificationConfig.optionsKey]}
        field={calificationConfig.field}
      />
      <MultipleSelectHeader
        blockRow={blockRow}
        width={conditionConfig.width}
        idHeaderSelect={conditionConfig.idHeaderSelect}
        label={conditionConfig.label}
        key={conditionConfig.label}
        updateSelect={updateMultipleColumn}
        notObjectValue={conditionConfig.notObjectValue}
        options={selectOptions[conditionConfig.optionsKey]}
        field={conditionConfig.field}
      />
      <MultipleSelectHeader
        blockRow={blockRow}
        width={monthConfig.width}
        idHeaderSelect={monthConfig.idHeaderSelect}
        label={monthConfig.label}
        key={monthConfig.label}
        updateSelect={updateMultipleColumn}
        notObjectValue={monthConfig.notObjectValue}
        options={selectOptions[monthConfig.optionsKey]}
        field={monthConfig.field}
      />
      <MultipleSelectHeader
        blockRow={blockRow}
        width={yearConfig.width}
        idHeaderSelect={yearConfig.idHeaderSelect}
        label={yearConfig.label}
        key={yearConfig.label}
        updateSelect={updateMultipleColumn}
        notObjectValue={yearConfig.notObjectValue}
        options={selectOptions[yearConfig.optionsKey]}
        field={yearConfig.field}
      />
      <MultipleSelectHeader
        blockRow={blockRow}
        width={establishmentConfig.width}
        idHeaderSelect={establishmentConfig.idHeaderSelect}
        label={establishmentConfig.label}
        key={establishmentConfig.label}
        updateSelect={updateMultipleColumn}
        notObjectValue={establishmentConfig.notObjectValue}
        options={selectOptions[establishmentConfig.optionsKeyHeader]}
        field={establishmentConfig.field}
      />
    </TableRow>
  );
};
