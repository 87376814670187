import { Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const GradeYearButton = ({ year, pageYearNow, pendingState = true, handle, label = 'Ciclo' }) => {
  return (
    <>
      {pageYearNow != year ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: '12px 20px',
            alignItems: 'center',
            mr: '2px',
            '&:hover': {
              backgroundColor: 'white',
              border: 'solid 2px #005387',
              borderRadius: '4px',
              cursor: 'pointer'
            }
          }}
          onClick={() => handle(year)}>
          {pendingState === null ? '' : !pendingState && <TaskAltIcon sx={{ color: '#26874A', mr: 1 }}></TaskAltIcon>}
          <Typography variant="body1" align="center">
            {year}° {label}
          </Typography>
          {pendingState === null ? '' : pendingState && (
            <Typography variant="body1" align="center" sx={{ color: '#9EAAB8', ml: 1 }}>
              (pendiente)
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 'max-content',
            backgroundColor: '#005387',
            color: '#fff',
            p: '12px 20px',
            border: 'solid 2px #005387',
            borderRadius: '4px',
            alignItems: 'center',
            height: '28px',
            mr: '2px',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => handle(year)}>
          {pendingState === null ? '' : !pendingState && <TaskAltIcon sx={{ color: '#FFF', mr: 1 }}></TaskAltIcon>}
          <Typography variant="body1" align="center">
            {year}° {label}
          </Typography>
          {pendingState === null ? '' : pendingState && (
            <Typography variant="body1" align="center" sx={{ ml: 1 }}>
              (pendiente)
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
