import { Box, IconButton } from '@mui/material';
import { LightTooltip } from '../styles/Styles';

export const TableAction = ({ tooltipTitle, onClick, iconElement, disabled, float = 'left' }) => {
  return (
    <LightTooltip title={tooltipTitle}>
      <Box sx={{ display: 'inline-block', float: float }}>
        <IconButton disabled={disabled} onClick={onClick}>
          {iconElement}
        </IconButton>
      </Box>
    </LightTooltip>
  );
};
