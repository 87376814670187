import { useEffect, useState } from 'react';
import { useCreateOrEditResource } from '../../../hooks/useCreateOrEditResource';
import { getPermissions, getRolById, patchRol, postRol } from '../../../services/admin/rolesService';
import { EditRolContext } from './EditRolContext';
import { useNavigate } from 'react-router-dom';

export const EditRolState = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [rolPermissionsIds, setRolPermissionsIds] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [rolInfo, setRolInfo] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    initPermissions();
  }, []);

  const initPermissions = async () => {
    let data = await getPermissions();
    setPermissions(data);
  };

  const getRol = async (id) => {
    const rolData = await getRolById(id);
    setRolPermissionsIds(rolData.role_roles_default_actions.map((x) => x.default_actions_id));
    setRolInfo({ ...rolData, id: parseInt(rolData.id) });
  };

  const createRol = async (params) => {
    let { form, permissionsControls } = params;
    form.actions = permissionsControls.filter((x) => x.checked)?.map((x) => x.id);
    await postRol(form);
    navigate(-1);
  };

  const editRol = async (params) => {
    let { form, permissionsControls } = params;
    form.actions = permissionsControls.filter((x) => x.checked)?.map((x) => x.id);
    await patchRol(form);
    navigate(-1);
  };

  const resourceHandler = useCreateOrEditResource(editRol, createRol, getRol);

  return (
    <EditRolContext.Provider
      value={{
        onSaveRol: resourceHandler.onSave,
        permissions: permissions,
        rolPermissionsIds: rolPermissionsIds,
        rolInfo: rolInfo
      }}>
      {children}
    </EditRolContext.Provider>
  );
};
