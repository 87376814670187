import { Grid } from '@mui/material';
import { AnalyticalDetailState } from '../../../context/Analytical/AnalyticalDetail/AnalyticalDetailState';
import { BodyDetailAnalytic } from './BodyDetailAnalytic';
import { CurriculumBox } from './CurriculumBox';
import { StepperDetailAnalytic } from './StepperDetailAnalytic';

export const AnalyticDetailPage = () => {
  return (
    <AnalyticalDetailState>
      <Grid container>
        <BodyDetailAnalytic />
        <StepperDetailAnalytic />
      </Grid>
      <CurriculumBox />
    </AnalyticalDetailState>
  );
};
