export const AnalyticRouteConstants = {
  BUSCAR: '/analiticos/buscar/',
  BUSCAR_PARAMS: (id, name) => `/analiticos/buscar/${id}/${name}`,
  BUSCAR_AGRUPADO: '/analiticos/buscarAgrupado',
  DETALLE: (id) => '/analiticos/detalle/' + id,
  PASO2: (id) => '/analiticos/editar/paso2/' + id,
  PASO1: (id) => '/analiticos/editar/paso1/' + id,
  PASO3: (id) => '/analiticos/editar/paso3/' + id,
  PASO1_NUEVO: '/analiticos/editar/paso1/nuevo'
};

export const AdminRouteConstants = {
  ROL_BUSCAR: '/admin/roles/buscar',
  ROL_CREAR: '/admin/roles/editar',
  ROL_EDITAR: (id) => '/admin/roles/editar/' + id,
  USUARIO_BUSCAR: '/admin/usuarios/buscar',
  USUARIO_CREAR: '/admin/usuarios/editar',
  USUARIO_EDITAR: (id) => '/admin/usuarios/editar/' + id
};

export const StudyPlanRouteConstants = {
  BUSCAR: '/planEstudio/buscar',
  DETALLE: (id) => '/planEstudio/detalle/' + id,
  PASO1: (id) => '/planEstudio/editar/paso1/' + id,
  PASO1_NUEVO: '/planEstudio/editar/paso1/nuevo',
  PASO2: (id) => '/planEstudio/editar/paso2/' + id,
  ESTABLECIMIENTOS: '/planEstudio/establecimientos',
  BUSCAR_POR_ESTABLECIMIENTO: (id,name) => `/planEstudio/buscar/${id}/${name}`,
};

export const EstablishmentRouteConstants = {
  BUSCAR: '/seleccionarEstablecimiento'
};

export const HistoricalRouteConstants = {
  BUSCAR_ESTABLECIMIENTO: '/historico/establecimientos',
  BUSCAR: '/historico/buscar',
  BUSCAR_PARAMS: (id, name) => `/historico/buscar/${id}/${name}`,
  DETALLE: (id) => '/historico/detalle/' + id,
};
