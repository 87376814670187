import { Box, Grid, Container } from '@mui/material';
import '../styles/style.css';
import facebookIcon from '../assets/img/footer/redes/facebook-icon.svg';
import twitterIcon from '../assets/img/footer/redes/twitter-icon.svg';
import instagramIcon from '../assets/img/footer/redes/instagram-icon.svg';
import youtubeIcon from '../assets/img/footer/redes/youtube-icon.svg';
import whatsappIcon from '../assets/img/footer/redes/whatsapp-icon.svg';
import logoFooterPrinc from '../assets/img/footer/logo-footer-princ.svg';
import logoFooterSec from '../assets/img/footer/logo-footer-sec.svg';
import logoFooterMobile from '../assets/img/footer/logo-footer-mobile.svg';

/*
import Avatar from '@mui/material/Avatar';
import BAB from '../../assets/images/foot-ba.svg';
import BAC from '../../assets/images/Vector.svg';
// icons
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
*/
/**
 * Crea un link con el cargo y el nombre y apellido de la autoridad en cuestión.
 * @param {*} title El cargo que ostenta la autoridad.
 * @param {*} name  Nombre y apellido de la autoridad.
 * @returns
 */
/*
let createAuthorityLink = (title, name) => {
  return (
    <Grid item xs={2}>
      <Box sx={{ textAlign: 'right', fontSize: '13px' }}>
        <Link href="#" underline="none" color="inherit">
          <Box>
            <div style={{ fontWeight: 'bold', fontSize: '14px', letterSpacing: 0.5 }}>{title}</div>
            <div>{name}</div>
          </Box>
        </Link>
      </Box>
    </Grid>
  );
};
*/

export default function CustomFooter() {
  return (
    <footer className="bg-footer">
      <Box py={{ xs: 1, sm: 1 }}>
        <Grid sx={{ mt: 2, mb: 1 }}>
          <Container maxWidth="xl">
          <div className="footer-caba container-caba">
              <div className="container-footer">
                <div>
                  <img src={logoFooterPrinc} alt="" className="logo-princ-footer" />
                  <h6 className="text-social-media">Nuestras redes</h6>
                </div>
                <div>
                  <ul className="list-social-media">
                    <li className="list-item-social-media">
                      <a className="list-link-social-media" href="https://www.facebook.com/EducacionBA" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="Facebook" className="list-img-social-media" />
                        <span className="item-name-social-media">Facebook</span>
                      </a>
                    </li>
                    <li className="list-item-social-media">
                      <a className="list-link-social-media" href="https://twitter.com/EducacionBA" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} alt="Twitter" className="list-img-social-media" />
                        <span className="item-name-social-media">Twitter</span>
                      </a>
                    </li>
                    <li className="list-item-social-media">
                      <a className="list-link-social-media" href="https://www.instagram.com/educacionbA/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Instagram" className="list-img-social-media" />
                        <span className="item-name-social-media">Instagram</span>
                      </a>
                    </li>
                    <li className="list-item-social-media">
                      <a className="list-link-social-media" href="https://www.youtube.com/@educacionBA/" target="_blank" rel="noopener noreferrer">
                        <img src={youtubeIcon} alt="Youtube" className="list-img-social-media" />
                        <span className="item-name-social-media">Youtube</span>
                      </a>
                    </li>
                    <li className="list-item-social-media">
                      <a className="list-link-social-media" href="https://api.whatsapp.com/send/?phone=5491150500147&amp;text=Hola+%EF%BF%BD&amp;app_absent=0" target="_blank" rel="noopener noreferrer">
                        <img src={whatsappIcon} alt="WhatsApp" className="list-img-social-media" />
                        <span className="item-name-social-media">WhatsApp</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="hr-style" />
              <div className="container-sec-footer">
                <div className="container-logo-sec">
                  <img src={logoFooterSec} alt="Logo de la Ciudad Autónoma de Buenos Aires" className="logo-footer-sec" />
                </div>
                <div className="list-persons divisor">
                  <div className="list-person-pr">
                    <p className="list-person-job">Ministerio de Educación</p>
                    <p className="list-person-name">Mercedes Miguel</p>
                  </div>
                  <div className="list-person-px list-person-divisor">
                    <p className="list-person-job">
                      Subsecretaría de <br />
                      Tecnología Educativa
                    </p>
                    <p className="list-person-name">Ignacio Sanguinetti</p>
                  </div>
                  <div className="list-person-pl list-person-divisor">
                    <p className="list-person-job">
                      Dirección General de Proyectos <br />
                      y Tecnología Educativa
                    </p>
                    <p className="list-person-name">Mariano Pérez Alfaro</p>
                  </div>
                </div>
              </div>
              <hr className="hr-style" />
              <div className="container-copyright">
                <div className="container-img-copyright">
                  <img src={logoFooterMobile} alt="Logo Footer Mobile" />
                  <img src={logoFooterPrinc} alt="Logo Footer Principal" />
                </div>
                <p className="text-copyright">
                  Todos los derechos reservados © 2024
                  <span id="anioCopyright"></span> Dirección General de Proyectos y
                  Tecnología Educativa
                </p>
              </div>
            </div>
          </Container >
        </Grid>
      </Box>
    </footer >
  );
}
