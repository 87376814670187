import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { getValidateFunction } from '../helpers/functionValidatorHelper';
import { useMemo } from 'react';

/**
 * Hook para la creación de formularios.
 * @param formControls Configuración de la estructura del formulario en JSON.
 * @param configurateFunction Función que se le pasa para configurar las acciones eseciales que puede tener un campo del formulario (si es que el handle change no es el generico).
 */
export const useGenericForm = (formControls, configurateFunction) => {
  // Form el formulario
  const [form, setForm] = useState({});
  const [config, setConfig] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [hasModifications, setHasModifications] = useState(false);

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    let newConfig = [...formControls];
    newConfig.forEach((x) => {
      x.validation = getValidateFunction(x.validationKey);
      if (x.additionalValidationKeys) {
        x.additionalValidations = x.additionalValidationKeys.map((key) => getValidateFunction(key));
      }
      return x;
    });
    setConfig(configurateFunction ? configurateFunction(newConfig) : newConfig);
  }, []);

  //

  const resetForm = () => {
    let emptyForm = getEmptyForm();
    setForm(emptyForm);
  };

  const getEmptyForm = () => {
    let emptyForm = {};
    for (var j = 0; j < formControls.length; j++) {
      if (formControls[j].type === 'switch') {
        emptyForm[formControls[j].field] = !formControls[j].defaultValue ? false : true;
      } else if (formControls[j].type === 'autocomplete') {
        emptyForm[formControls[j].field] = formControls[j].multiple ? [] : null;
      } else {
        emptyForm[formControls[j].field] = '';
      }
    }
    return emptyForm;
  };

  const initForm = (formValues, options) => {
    if (options && formValues) {
      let autoCompleteTypes = config.filter((x) => x.type === 'autocomplete');
      autoCompleteTypes?.forEach((element) => {
        if (!element.hidden) {
          if (element.multiple) {
            formValues[element.field] = options[element.optionsKey].filter((x) => formValues[element.field].includes(x.id));
          } else {
            formValues[element.field] = options[element.optionsKey].filter((x) => x.id === formValues[element.field])[0];
          }
        }
      });
    }
    setForm(formValues);
  };

  // Actualiza los valores del form
  const handleValue = (value, field) => {
    setForm((prevForm) => {
      let newForm = { ...prevForm };
      newForm[field] = value;
      return newForm;
    });
    setHasModifications(true);
  };

  //este metodo tiene que crecer
  const formatForm = () => {
    let formatedForm = { ...form };
    for (var j = 0; j < config.length; j++) {
      if (!config.hidden) {
        if (config[j].type === 'int' && form[config[j].field]) {
          formatedForm[config[j].field] = parseInt(form[config[j].field]);
        }
        if (config[j].type === 'date' && form[config[j].field]) {
          formatedForm[config[j].field] = moment(form[config[j].field]).format('YYYY-MM-DD');
        }
        if (config[j].type === 'autocomplete') {
          formatedForm[config[j].field] = config[j].multiple ? form[config[j].field]?.map((x) => x.id) : form[config[j].field].id;
        }
        if (config[j].type === 'radio') {
          formatedForm[config[j].field] = form[config[j].field];
        }
      }
    }
    return formatedForm;
  };

  const isEmpty = (config, val) => {
    let value = val;
    if (typeof value === 'string') {
      value = val.trim();
    }
    switch (config.type) {
      case 'text':
        return !value;
      case 'switch':
        return '' || null || undefined;
      case 'autocomplete':
        if (config.multiple) {
          return value === undefined || value === null || (value && value.length === 0);
        } else {
          return !value;
        }
      default:
        return !value;
    }
  };

  const validateFormAndExecutePostAction = (action, forceValidation = false) => {
    if ((!hasModifications && !forceValidation) || validateForm()) {
      action(formatForm());
    }
  };

  const validateForm = () => {
    const pushToValid = (validator, column) => {
      if (column.field === 'broadcast_date') {
        const egressDate = form['egress_date'];
        valid.push({
          ...validator(form[column.field], egressDate),
          label: column.label.replace('*', '')
        });
      } else {
        valid.push({
          ...validator(form[column.field], column, form),
          label: column.label.replace('*', '')
        });
      }
    };

    let valid = [],
      isValid = true;
    let invalidFields = config.filter((x) => x.required && !x.hidden && isEmpty(x, form[x.field]));
    // eslint-disable-next-line no-console
    let isRequired = invalidFields.length === 0;
    config.forEach((x) => {
      if (form[x.field]) {
        if (x.validationKey) pushToValid(x.validation, x);
        if (x.additionalValidationKeys && x.additionalValidations)
          x.additionalValidations.forEach((validation) => pushToValid(validation, x));
      }
    });
    valid.forEach((x) => {
      if (!x.isValid) {
        isValid = false;
        enqueueSnackbar(`${x.label} : ${x.errorMessage}`, { variant: 'warning' });
      }
    });
    !isRequired ? enqueueSnackbar('Debe completar los datos obligatorios.', { variant: 'warning' }) : '';
    return isValid && isRequired;
  };

  const formIsEmpty = useMemo(() => {
    return config && form && config.every((x) => isEmpty(x, form[x.field]));
  }, [config, form]);

  const hasEmptyRequiredFields = useMemo(() => {
    if (config && form) {
      const emptyRequiredFields = config.filter((x) => x.required && !x.hidden && isEmpty(x, form[x.field]));
      return emptyRequiredFields.length;
    }
  }, [config, form]);

  const emptyRequiredFields = (fetchedFormData) => {
    if (fetchedFormData) {
      const emptyFields = [];
      const requiredFields = config.filter((x) => x.required && !x.hidden).map((x) => x.field);
      requiredFields.forEach((field) => !fetchedFormData[field] && emptyFields.push(field));
      return emptyFields;
    }
  };

  const emptyNumFields = () => {
    if (form && config) {
      const emptyFields = [];
      const requiredFields = config.filter((x) => x.required && !x.disabled).map((x) => x.field);

      requiredFields.forEach((field) => {
        if (!form[field] || form[field] === undefined) {
          emptyFields.push(field);
        }
      });
      return emptyFields;
    }

    return [];
  };

  return {
    form,
    config,
    initForm,
    handleValue,
    validateFormAndExecutePostAction,
    setConfig,
    getEmptyForm,
    setForm,
    hasModifications,
    formIsEmpty,
    setHasModifications,
    hasEmptyRequiredFields,
    emptyRequiredFields,
    emptyNumFields
  };
};
