import { Grid, Box, Typography } from '@mui/material';
import { CustomYellowButton } from '../../../../components/CustomYellowButton';
// import { CustomBreadcrumbs } from '../../../../components/CustomBreadcrumbs';
import { useNavigate } from 'react-router-dom';
import { RolesTable } from './RolesTable';
import { RolesState } from '../../../../context/admin/roles/RolesState';
import Container from '@mui/material/Container';
import { AdminRouteConstants } from '../../../../routes/RoutesConstants';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { Actions } from '../../../../constants/Actions';

export const SearchRolesPage = () => {
  const { authorizeElement } = useAuthHelper();
  const navigate = useNavigate();

  const CreateRol = () => {
    navigate(AdminRouteConstants.ROL_CREAR);
  };

  return (
    <RolesState>
      <Container sx={{ mt: 4 }}>
        {/* <CustomBreadcrumbs base={'Admin'} now={'Roles'} /> */}
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="h3" color="initial">
              Roles
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {
              <Box sx={{ float: 'right', mt: 1, mb: 1 }}>
                {authorizeElement(<CustomYellowButton title={'+ Agregar Rol'} action={CreateRol} />, [Actions.CREATE_ROL])}
              </Box>
            }
          </Grid>
        </Grid>
        <RolesTable />
      </Container>
    </RolesState>
  );
};
