import { useEffect } from "react";
import { useParams } from "react-router-dom"

//le paso las acciones de get de la información, edit y create para saber que acciones usar
export const useCreateOrEditResource = (editAction, createAction, getForm) => {
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getForm(id);
        }
    }, [])

    const onSave = (params) => {
        if (id) {
            editAction(params)
        } else {
            createAction(params)
        }
    }

    return ({ onSave });
}