import { Edit } from '@mui/icons-material';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Send from '@mui/icons-material/Send';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GreyButton } from '../../../components/GreyButton';
import { Modal } from '../../../components/Modal';
import { TableAction } from '../../../components/TableAction';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { Actions } from '../../../constants/Actions';
import { CurriculumContext } from '../../../context/Curriculum/CurriculumContext';
import { curriculumStateValidator } from '../../../helpers/curriculumStateValidator';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { StudyPlanRouteConstants } from '../../../routes/RoutesConstants';
import { stateHtmlCurriculums } from '../../../helpers/StateHtmlColors';
import { useMemo } from 'react';

export const CurriculumTable = () => {
  const { entities, page, limit, handlePageChange, deleteCurriculumData, disassociateCurriculumData, count } =
    useContext(CurriculumContext);
  const navigate = useNavigate();
  const { addAndAuthTableAction } = useAuthHelper();
  const headers = [
    { label: 'Plan de estudios', field: 'studyPlanName' },
    { label: 'Estado', field: 'stateHtml' },
    { label: 'Modificado', field: 'modifiedMoment' }
  ];

  const [toggleConfirmModal, setToggleConfirmModal] = React.useState(false);
  const [studyPlanClicked, setStudyPlanClicked] = React.useState();
  const [toggleSignModal, setToggleSignModal] = React.useState(false);

  const handleClickVisibilityIcon = (e) => {
    if (e) {
      navigate(StudyPlanRouteConstants.DETALLE(e.study_plan_id));
    }
  };

  const handleSendObservationIcon = (e) => {
    if (e) {
      navigate(StudyPlanRouteConstants.DETALLE(e.study_plan_id));
    }
  };

  const handleClickEditIcon = (e) => {
    if (e) {
      navigate(StudyPlanRouteConstants.PASO2(e.study_plan_id));
    }
  };

  const handleClickDelete = (e) => {
    if (e) {
      setStudyPlanClicked(e);
      setToggleConfirmModal(!toggleConfirmModal);
    }
  };

  const onClickConfirmDeleteNote = (e) => {
    if (e) {
      deleteCurriculumData(studyPlanClicked);
      setToggleConfirmModal(!toggleConfirmModal);
    }
  };

  const curriculumsHtml = useMemo(() => {
    return entities?.map((e) => {
      let time = e.study_plan.updated_at ? moment(e.study_plan.updated_at) : e.study_plan.created_at ? moment(e.study_plan.created_at) : '';
      e.modifiedMoment = time.format('DD/MM/YYYY');
      let fullName = e.study_plan.amending_resolution
        ? `${e.study_plan.study_plan_name} (${e.study_plan.amending_resolution})`
        : e.study_plan.study_plan_name;

      e.studyPlanName = (
        <Box sx={{ display: 'flex' }}>
          <Typography>{fullName}</Typography>
        </Box>
      );

      e.stateHtml = <Box>{stateHtmlCurriculums(e.study_plan.study_plan_status.id)}</Box>;

      e.actions = [];

      addAndAuthTableAction(
        <TableAction
          key={e.id + 'view'}
          tooltipTitle="Ver Detalle"
          onClick={() => handleClickVisibilityIcon(e)}
          iconElement={<Visibility color="primary" />}
        />,
        [Actions.READ_STUDYPLAN],
        e.actions
      );
      if (e.pending_observations && curriculumStateValidator.validToObserve(e.study_plan.study_plan_status.id)) {
        addAndAuthTableAction(
          <TableAction
            key={e.id + 'obs'}
            tooltipTitle="Revisar Observacion Pendiente"
            onClick={() => handleSendObservationIcon(e)}
            iconElement={<Send color="primary" />}
          />,
          [Actions.OBSERVE_STUDYPLAN],
          e.actions
        );
      }
      if (curriculumStateValidator.validToEdit(e.study_plan.study_plan_status.id))
        addAndAuthTableAction(
          <TableAction
            key={e.id + '' + 'edit'}
            tooltipTitle="Editar"
            onClick={() => handleClickEditIcon(e)}
            iconElement={<Edit color="primary" />}
          />,
          [Actions.UPDATE_STUDYPLAN],
          e.actions
        );

      if (curriculumStateValidator.validToDisasociate(e.study_plan.study_plan_status.id)) {
        addAndAuthTableAction(
          <TableAction
            key={e.id + '' + 'disasociate'}
            tooltipTitle="Desvincular"
            onClick={() => handleClickUnlink(e)}
            iconElement={<ContentCutIcon color="primary" />}
          />,
          [Actions.DISASSOCIATE_STUDYPLAN],
          e.actions
        );
      }
      // DELETE
      if (curriculumStateValidator.validToDelete(e.study_plan.study_plan_status.id)) {
        addAndAuthTableAction(
          <TableAction
            key={e.id + '' + 'delete'}
            tooltipTitle="Eliminar"
            onClick={() => handleClickDelete(e)}
            iconElement={<DeleteIcon color="primary" />}
          />,
          [Actions.DELETE_STUDYPLAN],
          e.actions
        );
      }

      return e;
    });
  }, [entities]);

  const confirmModalBody = <Typography>¿Estas seguro de eliminar el plan de estudios?</Typography>;
  const confirmModalActions = (
    <Grid container style={{ justifyContent: 'flex-end' }}>
      <GreyButton text="Cancelar" action={(e) => handleClickDelete(e)} />
      <CustomYellowButton title="Aceptar" action={(e) => onClickConfirmDeleteNote(e)} />
    </Grid>
  );

  const handleClickUnlink = (e) => {
    if (e) {
      setStudyPlanClicked(e);
      setToggleSignModal(!toggleSignModal);
    }
  };

  const onClickConfirmUnlink = (e) => {
    if (e) {
      disassociateCurriculumData(studyPlanClicked);
      setToggleSignModal(!toggleSignModal);
    }
  };

  const confirmModalActionsUnlink = (
    <Grid container style={{ justifyContent: 'flex-end' }}>
      <GreyButton text="Cancelar" action={(e) => handleClickUnlink(e)} />
      <CustomYellowButton title="Aceptar" action={(e) => onClickConfirmUnlink(e)} />
    </Grid>
  );

  return (
    <>
      {toggleConfirmModal && <Modal title="Eliminar plan de estudios" body={confirmModalBody} actions={confirmModalActions} />}
      {toggleSignModal && (
        <Modal title="Desvincular" body="¿Estas seguro de desvincular el plan de estudios?" actions={confirmModalActionsUnlink} />
      )}
      <TableGenericComponent
        bar={false}
        displayPagination={true}
        columns={headers}
        rows={curriculumsHtml}
        count={count}
        page={page}
        rowsPerPage={limit}
        handleChange={handlePageChange}
        hasActions={true}
        message="No hay planes de estudios asociados al establecimiento."
      />
    </>
  );
};
