import { Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Actions, Roles } from '../../../constants/Actions';
import { EstablishmentState } from '../../../context/establishment/EstablishmentState';
import { AppContext } from '../../../context/global/AppContext';
import { EstablishmentHead } from './EstablishmentHead';
import { EstablishmentTable } from './EstablishmentTable';

export const EstablishmentPage = () => {
  const { user, logout } = useContext(AppContext);

  useEffect(() => {
    if (user) {
      if (!user.actions.includes(Actions.SELECT_ESTABLISHMENT) && !user.roles.includes(Roles.SUPERADMIN)) {
        logout();
      }
    }
  }, [user]);

  return (
    <EstablishmentState>
      <Grid sx={{ mt: 4, mb: 4 }}>
        {user && (
          <>
            <EstablishmentHead />
            <br></br>
            <EstablishmentTable />
          </>
        )}
      </Grid>
    </EstablishmentState>
  );
};
