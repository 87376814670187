import { useEffect } from 'react';
import { useTable } from '../../../hooks/useTable';
import { getRoles } from '../../../services/admin/rolesService';
import { RolesContext } from './RolesContext';

export const RolesState = ({ children }) => {
  useEffect(() => {
    onSearchRoles();
  }, []);

  const tableInfo = useTable(false);

  const onSearchRoles = async () => {
    let data = await getRoles();
    tableInfo.onChange(
      data.map((x) => {
        x.is_active = x.is_active ? 'Activo' : 'Inactivo';
        return x;
      })
    );
  };

  return (
    <RolesContext.Provider
      value={{
        roles: tableInfo.data
      }}>
      {children}
    </RolesContext.Provider>
  );
};
