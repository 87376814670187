import { getData, patchData, postData } from '../Services';

export const getRoles = async () => {
  let data = await getData('/role');
  return data;
};

export const getPermissions = async () => {
  let data = await getData('/default-action');
  return data;
};

export const getRolById = async (id) => {
  let data = await getData('/role/' + id);
  return data;
};

export const postRol = async (rol) => {
  let response = await postData('/role', rol);
  return response;
};

export const patchRol = async (rol) => {
  let response = await patchData('/role/' + rol.id, rol);
  return response;
};
