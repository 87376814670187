import { Grid, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { StudyPlanRouteConstants } from '../../../routes/RoutesConstants';
import { Actions } from '../../../constants/Actions';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { useState } from 'react';
import { VALIDADOR } from '../../../constants/Roles';

export const CurriculumHead = ({ establishmentVersion }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { authorizeElement, isAllowed } = useAuthHelper();
  const isUserAdminOrLegalization = isAllowed([Actions.READ_GLOBAL_STUDYPLAN]);
  const [nameEstablisment, setNameEstablisment] = useState(params?.name);

  const handleCreateCurriculum = () => {
    localStorage.removeItem('baseStudyPlan');
    navigate(StudyPlanRouteConstants.PASO1_NUEVO);
  };
  const userData = JSON.parse(localStorage.getItem('user'));
  const isValidador = (userData && userData.roles && userData.roles.length > 0) ? (userData.roles[0] == VALIDADOR) : false;
  const numberEstablishment = JSON.parse(localStorage.getItem('user'))?.establishments?.length >= 2;

  if(localStorage.getItem('establishmentName')){
    setTimeout(() => {
      setNameEstablisment(localStorage.getItem('establishmentName'));
    }, 700);
  }
 
  if(isValidador && !numberEstablishment){
    setTimeout(() => {
      setNameEstablisment(JSON.parse(localStorage.getItem('user'))?.establishments[0]?.name);
    }, 700);
    
  }

  return (
    <>
      {!establishmentVersion && (isUserAdminOrLegalization || (isValidador && numberEstablishment)) && (
        <CustomBreadcrumbs base={'Establecimientos'} now={'Plan de estudios'} baseLink={StudyPlanRouteConstants.ESTABLECIMIENTOS} />
      )}
      <Grid container justifyContent="space-between" mt={4} mb={4} alignItems="center">
        <Grid item xs={6}>
          {!establishmentVersion && (isUserAdminOrLegalization || isValidador) ? (
            <Typography variant="h4" color="initial">
              {nameEstablisment}
            </Typography>
          ) : (
            <Typography variant="h3" color="initial">
              Plan de estudios
            </Typography>
          )}
        </Grid>
        {!establishmentVersion ? (
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', height: '49px' }}>
              {authorizeElement(<CustomYellowButton title={'+ Asociar Plan de estudios'} action={handleCreateCurriculum} />, [
                Actions.CREATE_STUDYPLAN,
                Actions.UPDATE_STUDYPLAN
              ])}
            </Box>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};
