import { CustomBreadcrumbs } from './CustomBreadcrumbs';
import { Typography } from '@mui/material';

export const FormPageHeader = ({ titleEdition, titleCreation, isEdition, baseBreadCrumb, actualBreadCrumb, baseLink }) => {
  return (
    <>
      <CustomBreadcrumbs base={baseBreadCrumb} now={actualBreadCrumb} baseLink={baseLink ? baseLink : ''} />
      {isEdition ? (
        <Typography variant="h3" color="initial">
          {titleEdition}
        </Typography>
      ) : (
        <Typography variant="h3" color="initial">
          {titleCreation}
        </Typography>
      )}
    </>
  );
};
