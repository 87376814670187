import { Checkbox, FormControlLabel, Grid } from '@mui/material';

export const EditRolPermissions = ({ permissionsControls, onChangeChecked }) => {
  return (
    <Grid container>
      {permissionsControls.length > 0
        ? permissionsControls.map((x) => {
            return (
              <Grid key={x.id} item xs={4}>
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox
                      checked={x.checked}
                      onChange={() => {
                        onChangeChecked(x.id);
                      }}
                    />
                  }
                  label={x.description}
                  labelPlacement="end"
                />
              </Grid>
            );
          })
        : null}
    </Grid>
  );
};
