import { FormControl, Grid, InputLabel, MenuItem, Select, IconButton, OutlinedInput, InputAdornment } from '@mui/material';
import { useContext } from 'react';
import { CurriculumContext, CurriculumEstablishmentContext } from '../../../context/Curriculum/CurriculumContext';
import SearchIcon from '@mui/icons-material/Search';

export const CurriculumFilters = ({ establishmentVersion }) => {
  const { status, handleChangeSelect, handleSearch, setSearchText } = useContext(
    establishmentVersion ? CurriculumEstablishmentContext : CurriculumContext
  );

  //const [curriculumState, setCurriculumState] = useState('');
  //const [searchText, setSearchText] = useState('');

  /*
  const handleChangeSelect = async (event) => {
    await setCurriculumState(event.target.value);
    if (searchText) {
      getAllCurriculumsData(0, 10, event.target.value, searchText);
    } else {
      getAllCurriculumsData(0, 10, event.target.value);
    }
  };
  */
  /*
  const handleSearch = (event) => {
    event.preventDefault();
    if (searchText && curriculumState) {
      getAllCurriculumsData(0, 10, curriculumState, searchText);
    } else {
      getAllCurriculumsData(0, 10, undefined, searchText);
    }
  };
  */

  return (
    <>
      <Grid container justifyContent="space-between" sx={{ mt: '30px', mb: '32px' }}>
        <Grid item xs={6}>
          <FormControl sx={{ minWidth: '248px', backgroundColor: '#F3F6F9', border: 'none' }}>
            <InputLabel id="demo-simple-select-label">Todos los estados</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{ border: 'none' }}
              label="Todos los estados"
              onChange={handleChangeSelect}>
              {status?.map((eachState) => {
                return (
                  <MenuItem key={eachState.id} value={eachState.id}>
                    {eachState.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={handleSearch}>
            <FormControl fullWidth sx={{ backgroundColor: '#F3F6F9', border: 'none' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment">
                {establishmentVersion ? 'Buscar CUE o establecimiento' : 'Buscar plan de estudios'}
              </InputLabel>
              <OutlinedInput
                id="outlined"
                autoComplete="off"
                onChange={(e) => setSearchText(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleSearch} edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label={establishmentVersion ? 'Buscar CUE o establecimiento' : 'Buscar plan de estudios'}
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </>
  );
};
