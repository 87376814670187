import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AccordionButton = ({ title, body, styleTitle }) => {
    return (
        <Accordion sx={{ mb: 2, borderRadius: '8px !important', boxShadow: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ styleTitle }}>
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {body}
            </AccordionDetails>
        </Accordion>
    );
};