import { Box, Typography } from '@mui/material';
import { ShowDataDetail } from '../../../components/ShowDataDetail';

export const CurriculumData = ({ curriculum }) => {
  return (
    <Box>
      {curriculum && (
        <>
          <Typography sx={{ mt: '42px', mb: '40px', fontSize: '28px', fontWeight: '600', lineHeight: '34px' }}>
            Información general
          </Typography>
          <ShowDataDetail title={'Plan de estudios'} info={curriculum.study_plan_name} inline />
          <ShowDataDetail title={'Resolución modificatoria'} info={curriculum.amending_resolution} inline />
          <ShowDataDetail title={'Título'} info={curriculum.title} inline />
          <ShowDataDetail title={'Equivalencia Ley N° 26.206'} info={curriculum.equivalence_Law_26206} inline />
          <ShowDataDetail title={'Norma jurisdiccional de aprobación'} info={curriculum.standard_approval} inline />
          {/* <ShowDataDetail title={'Validez Nacional otorgada por'} info={curriculum.national_validity_granted_by} /> */}
          <ShowDataDetail title={'Norma ratificación Dictamen'} info={curriculum.standard_ratification_judgment} inline />
          <ShowDataDetail title={'N° de inscripción otorgada por el RFIFD'} info={curriculum.number_rfifd} inline />
          <ShowDataDetail
            title={'Modalidad de espacio curricular'}
            info={curriculum.study_plan_modality_id === 1 ? 'Años' : 'Ciclos'}
            inline
          />
        </>
      )}
    </Box>
  );
};
