import moment from 'moment';
import { NACION_ANALYTICAL_STATUS } from '../constants/AnalyticalStates';
import { deleteData, getData, patchData, postData } from './Services';

export const getAllAnalyticStatus = async () => {
  return await getData('/analytic/analyticsStatus');
};

export const getAnalyticByStudent = async (documentNumber, documentTypeId = 5) => {
  const res = await getData(`/analytic/detail?documentTypeId=${documentTypeId}&documentNumber=${documentNumber}`);
  return res;
};

export const getAllDocumentType = async () => {
  const res = await getData(`/students/documentTypes`);
  return res;
};

export const getStudentByAnalyticalId = async (analyticalId) => {
  const res = await getData(`/analytic/student?analyticId=${analyticalId}`);
  return res;
};

export const getValidateQualificationRange = async (establishmentId) => {
  const res = await getData(`/establishment/validateQualificationRange/${establishmentId}`);
  return res;
};

export const getAnalyticByStatus = async (page = 1, limit = 10, status, establishmentId) => {
  const res = await getData(`/analytic/byAnalyticStatus?page=${page}&term=${status}&limit=${limit}&establishmentId=${establishmentId}`);
  return res;
};

export const getAnalyticByFilter = async (page = 1, limit = 10, filter, establishmentId, status, year, schoolYear) => {
  const res = await getData(
    `/analytic/filter?page=${page}${filter ? `&term=${filter}` : ''}&limit=${limit}${
      establishmentId ? `&establishmentId=${establishmentId}` : ''
    }${status ? `&analyticStatusId=${status}` : ''}${year ? `&year=${year}` : ''}${schoolYear ? `&schoolYear=${schoolYear}` : ''}`
  );
  return res;
};

export const getStudentData = async (id) => {
  const res = await getData(`/students/information/${id}`);
  return res;
};

export const postCreateStudent = async (body) => {
  const res = await postData(`/students`, body);
  return res;
};

export const patchStudentStepOne = async (body) => {
  const res = await patchData(`/analytic/student/analytic`, body);
  return res;
};

export const postCreateNewCarreer = async (body) => {
  const res = await postData(`/students/newCareer`, body);
  return res;
};

// Step two
export const patchStudentStepTwo = async (body) => {
  const res = await patchData(`/analytic/step2/analytic`, body);
  return res;
};

export const getAllStudyPlans = async () => {
  const res = await getData('/studyPlans/GetAllStudyPlans');
  return res;
};

export const getCopies = async () => {
  const res = await getData('/analytic/copyTypes');
  return res;
};

export const getStepTwoAnalyticInfo = async (id) => {
  const res = await getData(`/analytic/step2/analytic?analyticId=${id}`);
  return res;
};

export const getStudyPlansByEstablishment = async (id) => {
  let qs = id ? 'establishmentId=' + id : '';
  const res = await getData(`/studyPlans/establishment?${qs}`);
  return res;
};

export const getAnalyticCopiesReasons = async () => {
  const res = await getData(`/analytic/copyReasons`);
  return res;
};

export const getAnalyticCopyOriginalReasons = async (analyticId) => {
  const res = await getData(`/analytic/copyOriginalReasons${analyticId ? `?analytic_id=${analyticId}` : ''}`);
  return res;
};

export const getEstablishmentNames = async () => {
  const res = await getData(`/establishment/names`);
  return res;
};
//Step three
export const getStepThreeCurriculumBox = async (id, level) => {
  const res = await getData(`/analytic/level?analyticId=${id}&level=${level}`);

  return res;
};
export const getStepThreeCurriculumBoxAll = async (id) => {
  const res = await getData(`/analytic/step3/state?analyticId=${id}`);
  return res;
};
export const getStepThreeStudentQualifications = async () => {
  const res = await getData(`/analytic/qualifications`);
  return res;
};
export const getStepThreeStudentConditions = async () => {
  const res = await getData(`/analytic/conditions`);
  return res;
};
export const patchStepThreeCurriculumBox = async (body) => {
  const res = await patchData(`/analytic/step3/level`, body);
  return res;
};

// Analytical Establishment

export const getAnalyticalEstablishment = async (page = 1, limit = 10, establishment, status) => {
  if (status !== undefined && status !== null && status !== '' && establishment) {
    const res = await getData(
      `/establishment/GroupByEstablishmentAnalytic?page=${page}&limit=${limit}&term=${establishment}&analyticStatusId=${status}`
    );
    return res;
  }
  if (typeof status == 'number') {
    const res = await getData(`/establishment/GroupByEstablishmentAnalytic?page=${page}&limit=${limit}&analyticStatusId=${status}`);
    return res;
  }
  if (establishment) {
    const res = await getData(`/establishment/GroupByEstablishmentAnalytic?page=${page}&limit=${limit}&term=${establishment}`);
    return res;
  }
  const res = await getData(`/establishment/GroupByEstablishmentAnalytic?page=${page}&limit=${limit}`);
  return res;
};

// Analytical Detail

export const patchSignAnalytic = async (id) => {
  const res = await patchData(`/analytic/firm/analytic?analyticId=${id}`);
  return res;
};

export const patchValidateAnalytic = async (id) => {
  const res = await patchData(`/analytic/validate/analytic?analyticId=${id}`);
  return res;
};

export const getStepsInfoAnalitic = async (id, nextStatus, calculatedEstablishmendId) => {
  const searchTerm = localStorage.getItem('searchTerm');
  const searchStatusId = localStorage.getItem('searchStatusId');
  const establishmentSearchId = localStorage.getItem('analyticalEstablishmentId') || calculatedEstablishmendId;
  const searchYear = localStorage.getItem('searchYear');
  const searchSchoolYear = localStorage.getItem('searchYearSchool');
  let qs = '';
  qs += searchTerm ? `&search_term=${searchTerm}` : '';
  qs += searchStatusId ? `&search_status_id=${searchStatusId}` : '';
  qs += establishmentSearchId ? `&establishment_id=${establishmentSearchId}` : '';
  qs += nextStatus ? '&next_status_id=' + nextStatus : '';
  qs += searchYear ? '&years=' + searchYear : '';
  qs += searchSchoolYear ? '&schoolYear=' + searchSchoolYear : '';
  const res = await getData(`/analytic/steps?analyticId=${id}${qs}`);
  return res;
};

export const postAnalyticObserve = async (body) => {
  const res = await postData(`/analytic/observe/analytic`, body);
  return res;
};

export const patchAnalyticObserve = async (body) => {
  const res = await patchData(`/analytic/observe/analytic`, body);
  return res;
};

export const deleteAnalyticObserve = async (body) => {
  const res = await deleteData(`/analytic/observe/analytic?id_observation=` + parseInt(body.id_observation));
  return res;
};

export const deleteAnalytic = async (body) => {
  const res = await deleteData(`/analytic?analyticId=` + parseInt(body));
  return res;
};

export const getStudentStatus = async (document_type, document, gender_id, establishmentId, id) => {
  let qs = id ? `&id=${id}` : '';
  qs += gender_id ? `&gender_id=${gender_id}` : '';
  const res = await getData(
    `/students/status?document_type_id=${document_type}&document_number=${document}&establishment_id=${establishmentId}${qs}`
  );
  return res;
};

export const getStudyPlanStatus = async (studentId, studyPlanId) => {
  const res = await getData(`/analytic/verifyStudentCareers?student_id=${studentId}&study_plan_id=${studyPlanId}`);
  return res;
};

export const getStatusHistory = async (analyticId, page = 1, limit = 10) => {
  const res = await getData(`/analytic/nationHistorical?page=${page}&limit=${limit}&analyticId=${analyticId}`);
  // const res = [{ created_at: '2023-11-29T14:52:52.000Z', state_nation: 'PENDIENTE_FIRMA' }]; // MOCKUP
  const mappedResponse =
    res.data.length > 0
      ? res.data.map((status) => ({
          dateAndTime: moment(status.created_at).format('DD/MM/YYYY hh:mm'),
          status: NACION_ANALYTICAL_STATUS[status.state_nation]
        }))
      : [];
  return { ...res, data: mappedResponse };
};
