import { AnalyticalEstablishmentState } from '../../../context/Analytical/AnalyticalEstablishment/AnalyticalEstablishmentState';
import { AnalyticalEstablishmentFilters } from './AnalyticalEstablishmentFilters';
import { AnalyticalEstablishmentHead } from './AnalyticalEstablishmentHead';
import { AnalyticalEstablishmentTable } from './AnalyticalEstablishmentTable';
import { Grid } from '@mui/material';

export const AnalyticalEstablishmentPage = () => {
  return (
    <AnalyticalEstablishmentState>
      <Grid sx={{ mt: 4, mb: 4, ml: 3 }}>
        <AnalyticalEstablishmentHead />
        <AnalyticalEstablishmentFilters />
        <AnalyticalEstablishmentTable />
      </Grid>
    </AnalyticalEstablishmentState>
  );
};
