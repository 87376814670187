import { Grid } from '@mui/material';
import { GenericFormInput } from './GenericFormInput';

//Actions pasar componente botones
export const GenericForm = ({ formControls, options, handleValue, form }) => {
  return (
    <Grid container>
      {formControls &&
        formControls.map((x) => {
          return (
            <Grid item key={x.label} xs={x.size}>
              {!x.hidden && (
                <GenericFormInput
                  key={x.label}
                  label={x.label}
                  type={x.type}
                  valueData={x.value || form[x.field] || ''}
                  required={x.required}
                  size={x.size}
                  list={x.optionsKey ? options[x.optionsKey] : null}
                  //EN UN FUTURO FIX, ESTA DECISION DEBERIA ESTAR EN EL USE GENERIC FORM, NO ACA! (SIEMPRE DEBERIA EJECUTAR HANDLE VALUE)
                  handle={(value) => (x.action ? x.action(value, x.field, formControls) : handleValue(value, x.field))}
                  disabled={x.disabled}
                  readOnly={x.readOnly}
                  multiple={x.multiple}
                  validationFunction={
                    x.validation
                      ? (value) => {
                          return x.field === 'broadcast_date' ? x.validation(value, form['egress_date']) : x.validation(value, x, form);
                        }
                      : null
                  }
                  defaultValue={x.defaultValue ? x.defaultValue : null}
                  max={x.max ? x.max : null}
                />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};
