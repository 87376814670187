import { useContext } from 'react';
import { CurriculumContext } from '../../../context/Curriculum/CurriculumContext';
import { Grid } from '@mui/material';
import { CurriculumData } from './CurriculumData';
// import { CurriculumOptions } from './CurriculumOptions';
import { CurriculumDetailHeader } from './CurriculumDetailHeader';

export const BodyDetail = () => {
  const { curriculum } = useContext(CurriculumContext);

  return (
    <Grid item xs={10}>
      <CurriculumDetailHeader curriculum={curriculum} />
      {/* <CurriculumOptions /> */}
      <CurriculumData curriculum={curriculum} />
    </Grid>
  );
};
