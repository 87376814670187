import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { TableGenericComponent } from '../../../../components/TableGenericComponents';
import { Visibility } from '@mui/icons-material';
import { TableAction } from '../../../../components/TableAction';
import moment from 'moment';
import { Actions } from '../../../../constants/Actions';
import { CurriculumEstablishmentContext } from '../../../../context/Curriculum/CurriculumContext';
import { stateHtmlCurriculums } from '../../../../helpers/StateHtmlColors';

export const CurriculumEstablishmentsTable = () => {
  const navigate = useNavigate();
  const { page, limit, count, handleEstablishmentPageChange, establishments } = useContext(CurriculumEstablishmentContext);
  const { addAndAuthTableAction } = useAuthHelper();

  const headers = [
    { label: 'CUE', field: 'cue' },
    { label: 'Anexo', field: 'annexed' },
    { label: 'Establecimiento', field: 'establishment_name' },
    { label: 'Nivel', field: 'level' },
    { label: 'Estado', field: 'statusHtml' }
  ];

  const establishmentRows = establishments.map((establishment) => {
    let updated = establishment.updated_at ? moment(establishment.updated_at).format('DD/MM/YYYY') : '';
    establishment.statusNumber = establishment.status_study_plan || 0;
    establishment.statusHtml = stateHtmlCurriculums(establishment.statusNumber);
    establishment.actions = [];
    addAndAuthTableAction(
      <TableAction
        key={establishment.id + 'view'}
        tooltipTitle="Ver Detalle"
        onClick={() => handleViewDetailEstablishment(establishment)}
        iconElement={<Visibility color="primary" />}
      />,
      [Actions.READ_PRINT],
      establishment.actions
    );

    establishment.updated = updated;
    return establishment;
  });

  const handleViewDetailEstablishment = (e) => {
    navigate(`/planEstudio/buscar/${e.id}/${e.establishment_name}`, {
      state: { establishmentId: e.id, establishmentName: e.establishment_name }
    });
  };

  return (
    <>
      {establishmentRows ? (
        <TableGenericComponent
          displayPagination={true}
          bar={false}
          columns={headers}
          rows={establishmentRows}
          count={count}
          page={page}
          rowsPerPage={limit}
          handleChange={handleEstablishmentPageChange}
          hasActions={true}
        />
      ) : (
        <div>Hola</div>
      )}
    </>
  );
};
