import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import { forwardRef } from 'react';
import { Modal as muiModal } from '@mui/base';

// eslint-disable-next-line react/display-name
const BackdropUnstyled = forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />;
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool
};

const Modal = styled(muiModal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  minWidth: '400px',
  bgcolor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  border: '1px none currentColor',
  borderRadius: '4px',
  padding: '16px 32px 24px 32px'
});

export const CustomModal = ({ children, open, handleClose, width }) => {
  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        components={{ Backdrop }}>
        <Box style={width ? { width: width } : null} sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
};
