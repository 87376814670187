import { Grid, Typography } from '@mui/material';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { CurriculumStepOneState } from '../../../context/Curriculum/CurriculumStepOneState';
import { StudyPlanRouteConstants } from '../../../routes/RoutesConstants';
import { CurriculumForm } from './CurriculumForm';
import { Actions } from '../../../constants/Actions';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

export const CurriculumAddNewPage = () => {
  const auth = useAuthHelper();
  const isUserAdminOrLegalization = auth.isAllowed([Actions.READ_GLOBAL_STUDYPLAN]);
  const redirectLink = isUserAdminOrLegalization
    ? StudyPlanRouteConstants.BUSCAR_POR_ESTABLECIMIENTO(localStorage.getItem('establishmentId'), localStorage.getItem('establishmentName'))
    : StudyPlanRouteConstants.BUSCAR;
  return (
    <CurriculumStepOneState>
      <br />
      <Grid sx={{ background: 'linear-gradient(180deg, rgba(230,235,240,1) 30%, rgba(243,246,249,1) 30%)', p: '40px' }}>
        <CustomBreadcrumbs base={'Plan de estudios'} now={'Asociar plan de estudios'} baseLink={redirectLink} />
        <Typography variant="h3" color="initial" sx={{ fontSize: '28px', lineHeidht: '34px' }}>
          Asociar plan de estudios
        </Typography>

        <CurriculumForm />
      </Grid>
    </CurriculumStepOneState>
  );
};
