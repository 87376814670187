import BlockIcon from '@mui/icons-material/Block';
import ClearIcon from '@mui/icons-material/Clear';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, TableCell, Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../../../components/CustomModal';
import { TableAction } from '../../../components/TableAction';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import historicalConfig from '../../../configs/historicalConfig.json';
import { Actions } from '../../../constants/Actions';
import { HistoricalSearchContext } from '../../../context/historical/search/HistoricalSearchContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
//import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import { HistoricalRouteConstants } from '../../../routes/RoutesConstants';
import { CancelLegalizedAnalyticModal } from './CancelLegalizedAnalyticModal';
import { UnsubscribeAnalyticModal } from './UnsubscribeAnalyticModal';

export const HistoricalSearchTable = ({ setShowAlert }) => {
  const navigate = useNavigate();
  const { addAndAuthTableAction } = useAuthHelper();
  const { students, paginationProps, onChangePage, unsubscribe, reloadStudents, handleCancelAnalytic } =
    useContext(HistoricalSearchContext);
  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState(false);
  const [cancelLegalizedAnalyticIsOpen, setCancelLegalizedAnalyticIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState('');

  const handleViewDetail = (id) => {
    navigate(HistoricalRouteConstants.DETALLE(id));
  };

  const handleUnsubscribe = (id) => {
    setSelectedItem(id);
    setUnsubscribeModalIsOpen(true);
  };

  const handleCancel = (id) => {
    setSelectedItem(id);
    setCancelLegalizedAnalyticIsOpen(true);
  };

  const formatDate = (inputDate) => {
    if (!inputDate || inputDate.trim() === '') {
      return ''; // o cualquier otro valor predeterminado que desees
    } 
    const [year, month, day] = inputDate.split('-');
    return `${day}/${month}/${year}`;
  };
  const createNameComponent = (name, extraSpace) => {
    return (
      <>
        <Box key={name} sx={{ display: 'flex' }}>
          {extraSpace && (
            <TableCell
              colSpan={5}
              sx={{
                mr: 3,
                borderBottom: 'none'
              }}></TableCell>
          )}
          <Box>
            <Typography sx={{ fontSize: '14px', maxWidth: '280px', display: 'flex', alignItems: 'center' }}>{name}</Typography>
          </Box>
        </Box>
      </>
    );
  };

  const studentRows = useMemo(
    () =>
      students?.map((x) => {
        let e = { ...x };
        e.collapsible = e.analytic.length > 1;
        e.nameAndData = createNameComponent(e.completeName);
        e.analytic = e.analytic.map((analytic) => {
          const isParentCollapsible = e.collapsible;
          analytic.completeName = isParentCollapsible
            ? createNameComponent(analytic.study_plan.study_plan_name, true)
            : createNameComponent(e.completeName, true);
          analytic.egress_date = formatDate(analytic.egress_date);
          analytic.document_type = e.document_type.name;
          analytic.document_number = e.document_number;
          analytic.actions = [];
          addAndAuthTableAction(
            <div key={analytic.id + 'detail'}>
              <Box sx={{ float: 'center' }}>
                <TableAction
                  tooltipTitle={'Ver Detalle'}
                  onClick={() => handleViewDetail(analytic.id)}
                  iconElement={<VisibilityIcon color="primary" />}
                />
              </Box>
            </div>,
            //cambiar
            [Actions.READ_HISTORIC],
            analytic.actions
          );
          addAndAuthTableAction(
            <div key={analytic.id + 'unsubscribe'}>
              <Box sx={{ float: 'center' }}>
                <TableAction
                  tooltipTitle={'Dar de baja'}
                  onClick={() => handleUnsubscribe(analytic.id)}
                  iconElement={<BlockIcon color="primary" />}
                />
              </Box>
            </div>,
            //cambiar
            [Actions.UNSUSCRIBE_ANALYTIC],
            analytic.actions
          );
          addAndAuthTableAction(
            <div key={analytic.id + 'anular'}>
              <Box sx={{ float: 'center' }}>
                <TableAction
                  tooltipTitle={'Anular'}
                  onClick={() => handleCancel(analytic.id)}
                  iconElement={<ClearIcon color="primary" />}
                />
              </Box>
            </div>,
            //cambiar
            [Actions.CANCEL_LEGALIZED_ANALYTIC],
            analytic.actions
          );
          return analytic;
        });
        return e;
      }),
    [students]
  );

  const onUnsubscribe = async () => {
    await unsubscribe({ id: selectedItem });
    setUnsubscribeModalIsOpen(false);
    setShowAlert(true);
    reloadStudents();
  };

  const onCancel = async (request) => {
    await handleCancelAnalytic(request);
    setCancelLegalizedAnalyticIsOpen(false);
    reloadStudents();
  };

  return (
    <>
      <TableGenericComponent
        displayPagination={true}
        page={paginationProps.page || 0}
        rowsPerPage={paginationProps.limit || 0}
        count={paginationProps.count || 0}
        handleChange={(limit, page) => onChangePage(limit, page)}
        bar={false}
        columns={historicalConfig.tableConfig}
        rows={studentRows}
        hasActions={true}
        message="No hay estudiantes para mostrar."
        allowCollapsibleRows={true}
      />
      <CustomModal open={unsubscribeModalIsOpen} handleClose={() => setUnsubscribeModalIsOpen(false)}>
        <UnsubscribeAnalyticModal handleUnsubscribed={onUnsubscribe} handleClose={() => setUnsubscribeModalIsOpen(false)} />
      </CustomModal>
      <CustomModal open={cancelLegalizedAnalyticIsOpen} handleClose={() => setCancelLegalizedAnalyticIsOpen(false)}>
        <CancelLegalizedAnalyticModal
          id={selectedItem}
          handleCancel={onCancel}
          handleClose={() => setCancelLegalizedAnalyticIsOpen(false)}
        />
      </CustomModal>
    </>
  );
};
