import { useContext, useState } from 'react';
import { CurriculumContext } from '../../../context/Curriculum/CurriculumContext';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GradeYearButton } from '../../../components/GradeYearButton';
import { TableGenericComponent } from '../../../components/TableGenericComponents';

const headers = [{ label: 'Espacio curricular', field: 'course' }];

export const CurriculumBox = () => {
  const { curriculumBox, curriculum } = useContext(CurriculumContext);
  const [level, setLevel] = useState(1);

  const label = curriculum.study_plan_modality_id ? (curriculum.study_plan_modality_id === 1 ? 'Año' : 'Ciclo') : undefined;

  const curriculumBoxByLevel = curriculumBox.filter((e) => e.level === level);
  return (
    <>
      <Typography sx={{ mt: '40px', mb: '30px', fontSize: '28px', lineHeight: '34px', fontWeight: '600' }}>Caja curricular</Typography>
      <Box sx={{ display: 'flex', mb: '30px' }}>
        {/* TODO: CAMBIAR A LA MANERA CORRECTA */}
        {curriculum.years &&
          Array.from({ length: curriculum.years }, (_, i) => i + 1).map((year) => (
            <GradeYearButton year={year} key={year} pendingState={false} pageYearNow={level} label={label} handle={setLevel} />
          ))}
      </Box>
      <Box sx={{ mb: '30px' }}>
        <TableGenericComponent displayPagination={false} bar={false} columns={headers} rows={curriculumBoxByLevel} />
      </Box>
    </>
  );
};
