import React from 'react';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { useContext } from 'react';
import { AnalyticalContext } from '../../../context/Analytical/AnalyticalContext';

let headers = [
    { label: 'Fecha y hora', field: 'dateAndTime' },
    { label: 'Estado Nación', field: 'status' }
  ];

const AnalyticalStatusHistoryTable = () => {
  const { statusCount, statusPage, statusLimit, handleStatusHistoryPageChange, statusHistory } = useContext(AnalyticalContext);
  return (
    <TableGenericComponent
      displayPagination={true}
      bar={false}
      columns={headers}
      rows={statusHistory}
      count={statusCount}
      page={statusPage}
      rowsPerPage={statusLimit}
      handleChange={handleStatusHistoryPageChange}
      message="No hay historial para mostrar."
    />
  );
};

export default AnalyticalStatusHistoryTable;
