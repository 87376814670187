import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableGenericComponent } from '../../../../components/TableGenericComponents';
import { Actions } from '../../../../constants/Actions';
import { UsersContext } from '../../../../context/admin/users/UsersContext';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { AdminRouteConstants } from '../../../../routes/RoutesConstants';
import { TableAction } from '../../../../components/TableAction';
import Edit from '@mui/icons-material/Edit';

export const UsersTable = () => {
  const { items, handlePageChange, paginationProps } = useContext(UsersContext);
  const { page, limit, count } = paginationProps;
  const navigate = useNavigate();
  const { addAndAuthTableAction } = useAuthHelper();
  const headers = [
    { label: 'Nombre', field: 'first_name' },
    { label: 'Apellido', field: 'last_name' },
    { label: 'Instituciones', field: 'establishment' },
    { label: 'Roles', field: 'rol' }
  ];
  const dataItems = items?.map((e) => {
    e.actions = [];
    addAndAuthTableAction(
      <TableAction
        key={e.id}
        tooltipTitle={'Editar'}
        onClick={() => {
          navigate(AdminRouteConstants.USUARIO_EDITAR(e.id));
        }}
        iconElement={<Edit color="primary" />}
      />,
      [Actions.UPDATE_USER],
      e.actions
    );
    return e;
  });

  return (
    <>
      <TableGenericComponent
        displayPagination={true}
        bar={false}
        columns={headers}
        rows={dataItems}
        count={count}
        page={page}
        rowsPerPage={limit}
        hasActions={true}
        handleChange={handlePageChange}
        message="No hay usuarios para mostrar."
      />
    </>
  );
};
