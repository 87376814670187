import { Divider, Grid, Typography } from '@mui/material';
import { CustomYellowButton } from '../../../components/CustomYellowButton';

export const UnsubscribeAnalyticModal = ({ handleClose, handleUnsubscribed }) => {
  const onUnsubscribed = () => {
    handleUnsubscribed();
  };

  return (
    <>
      <Typography variant="h6">Dar de baja el analítico</Typography>
      <br></br>
      <Divider />
      <br></br>
      <Grid container>
        <Typography>
          ¿Está seguro que desea dar de baja el analítico?<br></br> {'Al confirmar esta acción, el analítico del alumno/a quedará en estado "En Proceso"'}
        </Typography>
      </Grid>
      <br></br>
      <Grid container></Grid>
      <br></br>
      <Grid container item justifyContent={'flex-end'}>
        <CustomYellowButton action={handleClose} title={'Cancelar'} style={{ marginRight: 10 }} />
        <CustomYellowButton action={onUnsubscribed} title={'Dar de baja'} />
      </Grid>
    </>
  );
};
