/* eslint-disable no-unused-vars */

import {
  ANULADO,
  COMPLETO,
  EN_PROCESO,
  OBSERVADO,
  PENDIENTE_DE_ENVIO,
  PENDIENTE_DE_FIRMA,
  PENDIENTE_DE_IMPRESION,
  PENDIENTE_DE_VALIDACION,
  VALIDADO,
  VALIDAR_IMPRESION,
  IMPRESO,
  LEGALIZADO
} from '../constants/AnalyticalStates';
import { AnalyticRouteConstants } from '../routes/RoutesConstants';

export const analyticStateValidator = {
  isValidToSign: (state) => [PENDIENTE_DE_FIRMA, COMPLETO].includes(state),
  isValidToEdit: (state) => [OBSERVADO, COMPLETO, PENDIENTE_DE_FIRMA, EN_PROCESO, ANULADO].includes(state),
  isValidToValidate: (state) => [PENDIENTE_DE_VALIDACION].includes(state),
  isValidToObservate: (state) => [PENDIENTE_DE_VALIDACION].includes(state),
  isValidToPrint: (state) => [PENDIENTE_DE_IMPRESION].includes(state),
  isValidToValidatePrint: (state) => [VALIDAR_IMPRESION].includes(state),
  isValidToLegalize: (state) => [IMPRESO].includes(state),
  isValidToAnnul: (state) => [IMPRESO].includes(state),
  isValidToSend: (state) => [PENDIENTE_DE_ENVIO].includes(state),
  isValidToAnnulAnalitic: (state) => [LEGALIZADO].includes(state),
  isValidToDownAnalitic: (state) => [LEGALIZADO].includes(state),
  getRouteToRedirectStep: (step, id) => {
    switch (step) {
      case 1:
        return AnalyticRouteConstants.PASO1(id);
      case 2:
        return AnalyticRouteConstants.PASO2(id);
      case 3:
        return AnalyticRouteConstants.PASO3(id);
      case 4:
        return AnalyticRouteConstants.PASO3(id);
      case 0:
        return AnalyticRouteConstants.PASO1_NUEVO;
      default:
        return AnalyticRouteConstants.PASO1(id);
    }
  }
};
