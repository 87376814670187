import * as React from 'react';
import { MobileStepper, Typography, Box } from '@mui/material';

export const CustomStepper = ({ steps, activeStep }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <MobileStepper
        variant="dots"
        steps={steps}
        position="static"
        activeStep={activeStep - 1}
        sx={{ pl: 0, backgroundColor: 'rgba(230,235,240,1)' }}
      />
      <Typography variant="body2" sx={{ fontSize: '13px', fontWeight: '400', lineHeight: '20px' }}>
        Pasos {activeStep} de {steps}
      </Typography>
    </Box>
  );
};
