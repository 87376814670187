import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableAction } from '../../../../components/TableAction';
import { TableGenericComponent } from '../../../../components/TableGenericComponents';
import RolesConfig from '../../../../configs/Admin/rolesConfig.json';
import { Actions } from '../../../../constants/Actions';
import { RolesContext } from '../../../../context/admin/roles/RolesContext';
import { useAuthHelper } from '../../../../hooks/useAuthHelper';
import { AdminRouteConstants } from '../../../../routes/RoutesConstants';

export const RolesTable = () => {
  const { roles } = useContext(RolesContext);
  const { addAndAuthTableAction } = useAuthHelper();
  const navigate = useNavigate();

  const dataItems = roles?.map((e) => {
    e.actions = [];
    addAndAuthTableAction(
      <TableAction
        key={e.id}
        tooltipTitle={'Editar'}
        onClick={() => {
          navigate(AdminRouteConstants.ROL_EDITAR(e.id));
        }}
        iconElement={<EditIcon color="primary" />}
      />,
      [Actions.UPDATE_ROL],
      e.actions
    );

    return e;
  });

  return (
    <TableGenericComponent
      columns={RolesConfig.tableConfig}
      rows={dataItems}
      displayPagination={false}
      hasActions={true}
      message="No hay roles para mostrar."
    />
  );
};
