import { getDataWithNoTracking, getData, patchData, postData } from './Services'

export const ExistsPendingNotifications = async (establishmentId) => {
    const res = await getDataWithNoTracking('/notifications/exists?establishment_id=' + parseInt(establishmentId))
    return res
}

export const getByEstablishment = async (establishmentId) => {
    const res = await getData('/notifications?establishment_id=' + parseInt(establishmentId))
    return res
}

export const setNotificationViewed = async (id) => {
    await patchData('/notifications/', { id })
}

export const postNotification = async (notification) => {
    await postData('/notifications/', notification)
}