import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { GradeYearButton } from '../../../components/GradeYearButton';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { AnalyticalDetailContext } from '../../../context/Analytical/AnalyticalContext';

const headers = [
  { label: 'Espacio curricular', field: 'course' },
  { label: 'Calificación', field: 'qualification' },
  { label: 'Condición', field: 'condition' },
  { label: 'Mes', field: 'month' },
  { label: 'Año', field: 'year' },
  { label: 'Establecimiento', field: 'establishment_name' }
];

export const CurriculumBox = () => {
  const { setLevel, level, analyticsHtmlCurriculumBox, levelsList, studentSelectedTable } = useContext(AnalyticalDetailContext);

  return (
    <>
      <br />
      <Typography variant="h4">Caja curricular</Typography>
      <br />
      <Box sx={{ display: 'flex' }}>
        {levelsList.map((x) => (
          <GradeYearButton
            year={x.level}
            key={x.level}
            pendingState={!x.complete}
            pageYearNow={level}
            handle={setLevel}
            label={
              studentSelectedTable && studentSelectedTable.study_plan
                ? studentSelectedTable.study_plan.study_plan_modality_id === 1
                  ? 'Año'
                  : 'Ciclo'
                : null
            }
          />
        ))}
      </Box>
      <br />
      <TableGenericComponent displayPagination={false} bar={false} columns={headers} rows={analyticsHtmlCurriculumBox} />
      <br />
    </>
  );
};
