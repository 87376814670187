import { Alert, AlertTitle, Avatar, Collapse, Container, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LButton from '../components/LButton';
import { AppContext } from '../context/global/AppContext';
import Navbar from '../components/Navbar';
import '../styles/styles.css';
import CustomFooter from '../components/CustomFooter';
import StudentsImage from '../assets/images/Imagen_circular_grande.png';
import CloseIcon from '@mui/icons-material/Close';

export const LoginPage = () => {
  const navigate = useNavigate();

  const { token } = useContext(AppContext);
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [isUserDisabled, setUserDisabled] = React.useState(false);

  useEffect(() => {
    if (location?.search === '?unahutorized=true') {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (location?.search === '?disabled=true') {
      setUserDisabled(true);
      setOpen(true);
    }
  }, []);

  const openWebAuth = () => {
    if (token) {
      navigate('/', {
        replace: true
      });
    } else {
      // eslint-disable-next-line no-undef
      window.open(process.env.REACT_APP_URL_BACKEND + '/auth/google', '_self');
    }
  };

  return (  
    <div className="back">
      <Navbar />
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 2, ml: 2 }}>
          <AlertTitle sx={{ fontWeight: 700 }}>Error</AlertTitle>
          {isUserDisabled
          ? "El usuario ingresado se encuentra deshabilitado."
          : "Por favor ingrese un mail valido para ingresar."}
        </Alert>
      </Collapse>
      <Container sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%', mt: '133px' }}>
          <Stack>
            <Typography variant="h1" sx={{ fontSize: '36px', fontWeight: 700 }}>
              Bienvenidos al nuevo
            </Typography>
            <Typography variant="h2" sx={{ fontSize: '29px', fontWeight: 500, marginTop: '0 !important' }}>
              Sistema de Títulos Educativos Digitales
            </Typography>
          </Stack>
          <Stack sx={{ mt: '18px', width: '418px', mb: '48px' }}>
            <Typography>
              <b>SiTED es el nuevo sistema de generación de analíticos</b> de nivel secundario y nivel
superior del Ministerio de Educación de la Ciudad de Buenos Aires.
            </Typography> 
          </Stack>
          <LButton action={openWebAuth} />
        </Box>
        <Avatar
          sx={{ width: '438px', height: '438px', mt: '49px', mb: '86px' }}
          alt={'Imagen de alumnos estudiando'}
          src={StudentsImage}
          variant="square"
        />
      </Container>
      <CustomFooter />
    </div>
  );
};
