import React, { useEffect, useContext } from 'react';
import { useTable } from '../../hooks/useTable';
import { getByEstablishment, setNotificationViewed } from '../../services/notificationService';
import { AppContext } from '../global/AppContext';
import { NotificationContext } from './notificationContext';

export const NotificationState = ({ children }) => {
  const tableInfo = useTable(false);
  const { user } = useContext(AppContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    let data = await getByEstablishment(user.establishmentId);
    tableInfo.onChange(data);
  };

  const setViewed = async (id) => {
    await setNotificationViewed(id);
    let newData = [...tableInfo.data];
    tableInfo.onChange(newData.filter((x) => x.id !== id));
  };

  return <NotificationContext.Provider value={{ notifications: tableInfo.data, setViewed }}>{children}</NotificationContext.Provider>;
};
