import Container from '@mui/material/Container';
import { useContext, useEffect, useState } from 'react';
import { deleteCurriculum, disassociateCurriculum, getAllCurriculums, getStatusCurriculum } from '../../services/CurriculumServices';
import { AppContext } from '../global/AppContext';
import { CurriculumContext } from './CurriculumContext';
import { useTable } from '../../hooks/useTable';
import { useLocation } from 'react-router-dom';

export const CurriculumState = ({ children }) => {
  const { user } = useContext(AppContext);
  const establishmentIdLocalStorage = localStorage.getItem('establishmentId')
  const tableInfo = useTable(true);
  const [status, setStatus] = useState([]);
  const { paginationProps } = tableInfo;
  const location = useLocation();

  const [curriculumState, setCurriculumState] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getAllCurriculumsData();
    getStatusData();
  }, []);

  const handleChangeSelect = async (event) => {
    event.preventDefault();
    await setCurriculumState(event.target.value);
    if (searchText != undefined) {
      getAllCurriculumsData(0, 10, event.target.value, searchText);
    } else {
      getAllCurriculumsData(0, 10, event.target.value);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchText && curriculumState) {
      getAllCurriculumsData(0, 10, curriculumState, searchText);
    } else if (searchText) {
      getAllCurriculumsData(0, 10, '', searchText);
    }
  };

  const getAllCurriculumsData = async (
    page = 0,
    limit,
    studyPlanStatusId = '',
    studyPlan = '',
  ) => {
    const establishmentId =  calculateEstablishmentId();
    let response = await getAllCurriculums(page, limit, establishmentId, studyPlanStatusId, studyPlan);
    tableInfo.onChange(response.data, limit, page, response.count);
  };

  const getStatusData = async (studyPlanId) => {
    let data = await getStatusCurriculum(studyPlanId, user.establishmentId);
    data.unshift({ id: '', name: 'Todos los estados' });
    setStatus(data);
  };

  const deleteCurriculumData = async (e) => {
    const res = await deleteCurriculum(e.study_plan_id);
    if (res) {
      getAllCurriculumsData(0, 10, curriculumState, searchText);
    }
    return res;
  };
  const disassociateCurriculumData = async (e) => {
    const res = await disassociateCurriculum(e.study_plan_id, (establishmentIdLocalStorage ?? user.establishmentId));
    if (res) {
      getAllCurriculumsData(0, 10, curriculumState, searchText);
    }
    return res;
  };

  const handlePageChange = (limit, page) => {
    getAllCurriculumsData(page, limit, curriculumState, searchText);
    tableInfo.onChange(tableInfo.data, limit, page, tableInfo.paginationProps.count);
  };

  const calculateEstablishmentId = () => {
    const storagedEstablishment = localStorage.getItem('establishmentId');
    if (location.state) {
      const { establishmentId, establishmentName } = location.state;
      if (establishmentId && establishmentName) {
        localStorage.setItem('establishmentId', establishmentId);
        localStorage.setItem('establishmentName', establishmentName);
        return localStorage.getItem('establishmentId');
      }
    } else if (storagedEstablishment) {
      return localStorage.getItem('establishmentId');
    } else {
      const id = user.establishmentId;
      return id;
    }
  }

  return (
    <>
      <CurriculumContext.Provider
        value={{
          page: paginationProps.page,
          limit: paginationProps.limit,
          entities: tableInfo.data,
          status,
          count: paginationProps.count,
          handlePageChange,
          getAllCurriculumsData,
          deleteCurriculumData,
          disassociateCurriculumData,
          handleSearch,
          handleChangeSelect,
          setSearchText
        }}>
        <Container sx={{ mt: 4 }}>{children}</Container>
      </CurriculumContext.Provider>
    </>
  );
};
