import { Paper, Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomStepper } from '../../../components/CustomStepper';
import { CustomSteps } from '../../../components/CustomSteps';
import { CustomYellowButton } from '../../../components/CustomYellowButton';
import { GradeYearButton } from '../../../components/GradeYearButton';
import { GreyButton } from '../../../components/GreyButton';
import { PreviousDataComponent } from '../../../components/PreviousDataComponent';
import { TableGenericComponent } from '../../../components/TableGenericComponents';
import { CurriculumStepTwoContext } from '../../../context/Curriculum/CurriculumContext';
import { curriculumStateValidator } from '../../../helpers/curriculumStateValidator';
import { StudyPlanRouteConstants } from '../../../routes/RoutesConstants';
import { EditableCurricularBoxTable } from './EditableCurricularBoxTable';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import { CurriculumStates } from '../../../constants/CurriculumStates';
import { ObservationDetailAlert } from '../../../components/ObservationDetailAlert';
const headers = [{ label: 'Espacio curricular', field: 'course' }];
const stepsArray = ['Plan de estudios', 'Caja curricular'];
import Toast from '../../../components/Toast';
import { Modal } from '../../../components/Modal';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { Actions } from '../../../constants/Actions';

export const CurriculumCurricularBox = () => {
  const { curriculumBox, curriculum, postAssociate, SendToPendingRequest } = useContext(CurriculumStepTwoContext);
  const navigate = useNavigate();
  const [currentCurricularBox, setCurrentCurricularBox] = useState([]);
  const [editableCurriculumBox, setEditableCurriculumBox] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [level, setLevel] = useState(1);
  const [activateConfirm, setActivateConfirm] = useState(false);
  const [handleDisabledButtonStatus, setHandleDisabledButtonStatus] = useState(false);
  const auth = useAuthHelper();
  const isUserAdminOrLegalization = auth.isAllowed([Actions.READ_GLOBAL_STUDYPLAN]);
  const redirectLink = isUserAdminOrLegalization
    ? StudyPlanRouteConstants.BUSCAR_POR_ESTABLECIMIENTO(localStorage.getItem('establishmentId'), localStorage.getItem('establishmentName'))
    : StudyPlanRouteConstants.BUSCAR;

  useEffect(() => {
    setEditableCurriculumBox([...curriculumBox]);
  }, [curriculumBox]);
  useEffect(() => {
    handleDisabledButton();
  }, [editableCurriculumBox]);

  useEffect(() => {
    if (editableCurriculumBox) {
      setCurrentCurricularBox(editableCurriculumBox.filter((e) => e.level === level));
    }
  }, [level, editableCurriculumBox]);

  const label = curriculum.study_plan_modality_id ? (curriculum.study_plan_modality_id === 1 ? 'Año' : 'Ciclo') : undefined;
  const enableToEdit = curriculumStateValidator.validToEdit(curriculum.study_plan_status_id);
  const associateHandleClick = async () => {
    await postAssociate(curriculum.id);
    localStorage.removeItem('baseStudyPlan');
    navigate(redirectLink);
  };

  const handleDisabledButton = () => {
    const { amending_resolution: amendingResolution, is_preloaded: isPreloaded } = curriculum;
    const levels = [...new Set(editableCurriculumBox.map((item) => item.level))];
    const newCurricularBox = [...editableCurriculumBox].filter((e) => e.course != '' && !e.aproved);
    let hasNonEmptyCourseForAllLevels;
    if (isPreloaded === false) {
      hasNonEmptyCourseForAllLevels = levels.every((level) => {
        return newCurricularBox.some((item) => item.level === level && item.course.trim() !== '');
      });
    } else {
      if (amendingResolution) {
        hasNonEmptyCourseForAllLevels = newCurricularBox.some((item) => item.course.trim() !== '');
      } else {
        hasNonEmptyCourseForAllLevels = levels.every((level) => {
          return newCurricularBox.some((item) => item.level === level && item.course.trim() !== '');
        });
      }
    }
    setHandleDisabledButtonStatus(hasNonEmptyCourseForAllLevels);
  };

  const handleCheckButton = (year) => {
    const newCurricularBox = [...editableCurriculumBox.filter((x) => x.level !== level), ...currentCurricularBox].filter(
      (e) => e.course != ''
    );
    if (newCurricularBox.some((e) => e.level === year)) {
      return false;
    } else {
      return true;
    }
  };

  const SendToPendingRequestClick = async () => {
    const newCurricularBox = [...editableCurriculumBox.filter((x) => x.level !== level), ...currentCurricularBox].filter(
      (e) => e.course != ''
    );
    if (validateAllLevels(newCurricularBox)) {
      let res = await SendToPendingRequest(newCurricularBox);
      if (res.msg == 'Study Plan updated successfully') {
        Toast.success('El plan de estudios ha sido enviado para su aprobación');
        localStorage.removeItem('baseStudyPlan');
        navigate(redirectLink);
      }
    } else {
      enqueueSnackbar('Debe completar espacios curriculares en todos los años', { variant: 'warning' });
    }
  };

  const validateAllLevels = (curricularBox) => {
    return curricularBox.map((x) => x.level).filter(onlyUnique).length === curriculum.years;
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  //esto no esta bien, deberia llevarte a la pantalla con el nuevo id
  const handlePrevious = () => {
    navigate(StudyPlanRouteConstants.PASO1(curriculum.id));
  };

  const handleCancel = () => {
    localStorage.removeItem('baseStudyPlan');
    navigate(redirectLink);
  };

  const changeLevel = (value) => {
    const newCurricularBox = [...editableCurriculumBox.filter((x) => x.level !== level), ...currentCurricularBox];
    setEditableCurriculumBox(newCurricularBox);
    setLevel(value);
  };

  const updateSubject = (id, value) => {
    const newObject = editableCurriculumBox.map((data) => {
      if (data.id === id) {
        data.course = value;
      }
      return data;
    });

    setEditableCurriculumBox(newObject);
  };

  const addSubject = () => {
    let newSubject = { level: level, studyPlan_id: curriculum.id, id: uuid(), isNew: true, course: '', aproved: false };
    setEditableCurriculumBox([...editableCurriculumBox, newSubject]);
  };

  const deleteSubject = (id) => {
    if (currentCurricularBox.length > 1) {
      setEditableCurriculumBox(editableCurriculumBox.filter((x) => x.id !== id));
    } else {
      Toast.error('No puede borrar todos los espacios curriculares.');
    }
  };

  // Modal confirmar
  const confirmModalActions = (
    <>
      <GreyButton text="Cancelar" action={() => setActivateConfirm(false)} />
      <CustomYellowButton title="Solicitar" action={() => SendToPendingRequestClick()} />
    </>
  );

  return (
    <>
      {activateConfirm && (
        <Modal
          title="Enviar"
          body={
            <Typography>
              ¿Estás seguro de <b>solicitar la aprobación</b> del plan de estudios?
            </Typography>
          }
          actions={confirmModalActions}
        />
      )}
      <CustomSteps activeStep={2} stepsArray={stepsArray} />
      <CustomStepper steps={2} activeStep={2} />
      <Grid container>
        {curriculum &&
        curriculum.study_plan_status_id === CurriculumStates.OBSERVADO &&
        curriculum.observations &&
        curriculum.observations.length > 0 ? (
          <ObservationDetailAlert observationDescription={curriculum.observations[curriculum.observations.length - 1].observation} />
        ) : null}
      </Grid>
      <br></br> <br></br>
      <PreviousDataComponent name={curriculum.study_plan_name} studyPlan={curriculum.title} />
      <br />
      <br />
      <Grid sx={{ background: '#FFF', p: '35px', borderRadius: '10px', mb: 3 }}>
        <Grid item xs>
          <Typography sx={{ fontSize: '28px' }}>Caja curricular</Typography>
        </Grid>
        {enableToEdit ? (
          <Grid container item xs justifyContent={'flex-end'}>
            <CustomYellowButton title={'+ Agregar espacio curricular'} action={addSubject} />
          </Grid>
        ) : null}
        <br />
        <br />
        <Box sx={{ display: 'flex' }}>
          {curriculum.years &&
            Array.from({ length: curriculum.years }, (_, i) => i + 1).map((year) => (
              <GradeYearButton
                year={year}
                key={year}
                pendingState={handleCheckButton(year)}
                pageYearNow={level}
                handle={changeLevel}
                label={label}
              />
            ))}
        </Box>
        <br />
        <Paper>
          {!enableToEdit ? (
            <TableGenericComponent displayPagination={false} bar={false} columns={headers} rows={currentCurricularBox} />
          ) : (
            <EditableCurricularBoxTable data={currentCurricularBox} onUpdateRow={updateSubject} onDeleteRow={deleteSubject} />
          )}
        </Paper>
        <br />
      </Grid>
      <Grid sx={{ ml: '-16px', display: 'inline' }}>
        <GreyButton text="Anterior" action={handlePrevious} />
      </Grid>
      <Box sx={{ float: 'right', display: 'flex', mb: 5 }}>
        <GreyButton text="Cancelar" action={handleCancel} />
        {!enableToEdit ? (
          <CustomYellowButton title="Asociar" action={associateHandleClick} />
        ) : (
          <CustomYellowButton title="Solicitar Aprobación" disabled={!handleDisabledButtonStatus} action={() => setActivateConfirm(true)} />
        )}
      </Box>
    </>
  );
};
